import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSelectedServiceOffer } from 'prefs-and-service-offers';
import GLOBAL_CONFIG from 'global-variables';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

const updateData = async ({ fleet, note }: any): Promise<{ saved: boolean; message: string } | null> => {
  if (fleet === null) {
    return null;
  }

  try {
    const apiType: MaestroAPI = {
      method: 'PUT',
      endpoint: `cp-be/billing/${fleet}/requirements`,
      baseURL,
    };
    return maestroApiCall(apiType, note).then((res) => res);
  } catch (e) {
    return null;
  }
};

function useUpdateData({ fleet }: { fleet: string | null }) {
  const queryClient = useQueryClient();
  return useMutation(updateData, {
    onSuccess: () => {
      queryClient.invalidateQueries([`billings-${fleet}-${getSelectedServiceOffer().id}`, fleet]);
    },
  });
}

export { useUpdateData };
