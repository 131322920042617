import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Panel, Select, Switch, TextField, useDialogs } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { ContactOVM, CustomerOVM } from '@michelin/fcp-view-models';
import { getOptions } from '@michelin/select-options-provider';
import { useQueryClient } from '@tanstack/react-query';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { useGetBillingRequirementsChecks } from 'hooks/useBillingProfile';
import { preApprovalContactsFiltering, transformAsigneeToBillingLocation } from 'modules/Billing/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TooltipWarning } from '../Components/TooltipWarning';
import { ViewCreateParams } from '../DetailPage';
import { AuthorizationService, BillingProfileContextData, BillingProfileTypes, ContactsTypesAuth } from '../types';

export interface IGeneralInformationComponent {
  action: string;
}
export function GeneralInformation(props: IGeneralInformationComponent) {
  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';
  const { getValues, control, trigger, setValue } = useFormContext<BillingProfileContextData>();
  const { general_information, owner } = getValues().profile;
  const { t } = useTranslation();
  const isShipTo = owner?.customer_type?.toLowerCase() === 'st';
  const [auxLocations, setAuxLocations] = useState<{
    list: string[];
    listRelationships: CustomerOVM[];
    toAll: boolean;
    auxPreApprovalsContacts: ContactOVM[];
  } | null>(null);
  const [initialToAll] = useState<boolean>(general_information?.set_all_locations || false);
  const showWarning = editFlag && !general_information?.set_all_locations && initialToAll;
  const [allowUpdateChecks, setAllowUpdateChecks] = useState<boolean>(false);
  const { location } = usePermissions();
  const { errorDialog } = useDialogs();

  const params = useParams<ViewCreateParams>();
  const queryClient = useQueryClient();
  const checksBillingsReq = useGetBillingRequirementsChecks({
    fleetId: params.selectedAccount || '',
    ownerId: owner?.customer_number || params.selectedAccount || '',
    toAll: general_information?.set_all_locations || false,
    locations: (getValues().assignedLocationsRelationships || []).map((l) => ({
      customer_number: l.customer_number || '',
      customer_type: l.customer_type || '',
      relationship: l.relationship || '',
    })),
    key: 'getBillingRequirementsChecks',
    allowChecks: allowUpdateChecks && action !== 'view',
  });

  const checkAndUpdateProfile = ({
    wasChanged,
    newToAllValue,
    locations,
  }: {
    wasChanged: 'all' | 'select';
    newToAllValue?: boolean | undefined;
    locations: string[];
  }) => {
    if (!(wasChanged === 'all' && !newToAllValue && locations.length === 0)) {
      queryClient.removeQueries(['billings', 'getBillingRequirementsChecks']);
      setValue('isBillingRequirementsChecksLoading', true);
    }
  };
  const updateProfile = (checks: { po_required_for_service: boolean; participates_in_approve_orders: boolean }) => {
    if (checks.participates_in_approve_orders) {
      setValue('profile.general_information.participates_in_approve_orders', true);
    } else {
      setValue('profile.general_information.participates_in_approve_orders', false);
    }
    if (checks.po_required_for_service) {
      setValue('profile.general_information.po_required_for_service', true);
      updateErsOnsite();
    } else {
      setValue('profile.general_information.po_required_for_service', false);
    }
    setAuxLocations(null);
    setValue('isBillingRequirementsChecksLoading', false);
  };

  const updateErsOnsite = () => {
    const ers = getValues().profile.ers_purchasing_procedures;
    const onsite = getValues().profile.onsite_purchasing_procedures;
    const ersContactTypes = ers?.pri_ers_authorized_contact_type || [];
    const onsiteContactTypes = onsite?.pri_ers_authorized_contact_type || [];
    if (
      general_information?.profile_type === BillingProfileTypes.ers_onsite ||
      general_information?.profile_type === BillingProfileTypes.ers
    ) {
      if (ers?.required_authorization_to_begin_service !== 'po') {
        setValue('profile.ers_purchasing_procedures.required_authorization_to_begin_service', 'po');
        if (ers?.primary_method_to_request_service !== AuthorizationService.fleet_contact) {
          setValue(
            'profile.ers_purchasing_procedures.primary_method_to_request_service',
            AuthorizationService.fleet_contact,
          );
          setValue('profile.ers_purchasing_procedures.pri_ers_authorized_contact_type', [ContactsTypesAuth.PO_ISSUER]);
        } else if (!ersContactTypes.some((v) => v === ContactsTypesAuth.PO_ISSUER)) {
          setValue('profile.ers_purchasing_procedures.pri_ers_authorized_contact_type', [
            ...ersContactTypes,
            ContactsTypesAuth.PO_ISSUER,
          ]);
        }
        trigger('profile.ers_purchasing_procedures.required_authorization_to_begin_service');
        trigger('profile.ers_purchasing_procedures.primary_method_to_request_service');
        trigger('profile.ers_purchasing_procedures.pri_ers_authorized_contact_type');
      }
    }
    if (
      general_information?.profile_type === BillingProfileTypes.ers_onsite ||
      general_information?.profile_type === BillingProfileTypes.onsite
    ) {
      if (onsite?.required_authorization_to_begin_service !== 'po') {
        setValue('profile.onsite_purchasing_procedures.required_authorization_to_begin_service', 'po');
        if (onsite?.primary_method_to_request_service !== AuthorizationService.fleet_contact) {
          setValue(
            'profile.onsite_purchasing_procedures.primary_method_to_request_service',
            AuthorizationService.fleet_contact,
          );
          setValue('profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type', [
            ContactsTypesAuth.PO_ISSUER,
          ]);
        } else if (!onsiteContactTypes.some((v) => v === ContactsTypesAuth.PO_ISSUER)) {
          setValue(
            'profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type',
            onsiteContactTypes.length < 2
              ? [...onsiteContactTypes, ContactsTypesAuth.PO_ISSUER]
              : [ContactsTypesAuth.PO_ISSUER],
          );
        }
        trigger('profile.onsite_purchasing_procedures.required_authorization_to_begin_service');
        trigger('profile.onsite_purchasing_procedures.primary_method_to_request_service');
        trigger('profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type');
      } else if (onsiteContactTypes.length === 0) {
        setValue('profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type', [ContactsTypesAuth.PO_ISSUER]);
        trigger('profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type');
      }
    }
  };
  useEffect(() => {
    if (checksBillingsReq.isError && !checksBillingsReq.isLoading && !checksBillingsReq.isFetching) {
      setValue('profile.general_information.set_all_locations', auxLocations?.toAll || false);
      setValue('profile.assigned_locations', auxLocations?.list || []);
      setValue('assignedLocationsRelationships', auxLocations?.listRelationships || []);
      setValue('preApprovalsContacts', auxLocations?.auxPreApprovalsContacts || []);
      setValue('isBillingRequirementsChecksLoading', false);
      errorDialog(
        t('Error updating profile with new location selection. Location change was not applied.'),
        t('Error Updating Profile.'),
      );
    } else if (checksBillingsReq.data && !checksBillingsReq.isLoading && !checksBillingsReq.isFetching) {
      updateProfile(checksBillingsReq.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checksBillingsReq.data, checksBillingsReq.isLoading, checksBillingsReq.isFetching, checksBillingsReq.isError]);
  useEffect(() => {
    setAllowUpdateChecks(false);
  }, [action]);
  return (
    <Panel title={t('General Info')} style={{ marginBottom: 15 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Select
            value={general_information?.service_type || ''}
            label={t('Service Type')}
            readOnly
            style={{ width: '100%' }}
            options={getOptions('billing_service_types')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            value={general_information?.profile_type || ''}
            label={t('Profile Type')}
            readOnly
            style={{ width: '100%' }}
            options={getOptions('profile_types')}
          />
        </Grid>
        <Grid item xs={12} sm={!isShipTo ? 6 : 12}>
          <Controller
            name="profile.general_information.profile_name"
            control={control}
            rules={{
              required: { value: true, message: t('Billing Profile Name') },
            }}
            render={(item) => (
              <TextField
                id={item.field.name}
                name="billingProfileName"
                label={t('Billing Profile Name')}
                type="string"
                variant="standard"
                value={item.field.value || ''}
                error={!!item.formState.errors.profile?.general_information?.profile_name}
                onChange={(e) => item.field.onChange(e.target.value)}
                readOnly={props.action === 'view'}
                required
                helperText={
                  !!item.formState.errors.profile?.general_information?.profile_name
                    ? t('Profile name is required').toString()
                    : ''
                }
              />
            )}
          />
        </Grid>
        {!isShipTo ? (
          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" style={{ paddingTop: 16 }}>
              <Grid item xs={showWarning ? 8 : 9} style={{ textAlign: 'left' }}>
                <Controller
                  name="profile.general_information.set_all_locations"
                  defaultValue={general_information?.set_all_locations || false}
                  control={control}
                  rules={{
                    required: false,
                    validate: {
                      value: (value) =>
                        (getValues().assignedLocationsRelationships?.length > 0 && value === false) ||
                        value === true ||
                        t('You must assign to All or selected ship to accounts').toString(),
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      id="allLocationsSwitch"
                      readOnly={!editFlag}
                      onChange={(e) => {
                        setAllowUpdateChecks(true);
                        const auxConntacts = getValues().preApprovalsContacts;
                        setAuxLocations({
                          list: getValues().profile.assigned_locations || [],
                          listRelationships: getValues().assignedLocationsRelationships,
                          toAll: !e.target.checked,
                          auxPreApprovalsContacts: auxConntacts,
                        });
                        checkAndUpdateProfile({
                          wasChanged: 'all',
                          newToAllValue: e.target.checked,
                          locations: getValues().profile.assigned_locations || [],
                        });
                        onChange(e.target.checked);
                        let auxAssignedLocations: CustomerOVM[] = [];
                        if (!e.target.checked) {
                          auxAssignedLocations = getValues().assignedLocationsRelationships;
                        }
                        const auxPreApprovalsContacts = preApprovalContactsFiltering({
                          ownerContacts: getValues().ownerContacts,
                          profile: getValues().profile,
                          assignedLocations: auxAssignedLocations,
                          location: location,
                          loggedLocations: getValues().loggedLocationAndChildren,
                        });
                        setValue('preApprovalsContacts', auxPreApprovalsContacts);
                      }}
                      color="primary"
                      disabled={!editFlag || checksBillingsReq.isLoading || checksBillingsReq.isFetching}
                      label={t('Assign to all locations')}
                      labelPosition="left"
                    />
                  )}
                />
              </Grid>
              {showWarning && (
                <Grid
                  item
                  xs={1}
                  style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}
                >
                  <TooltipWarning
                    tooltipPlacement="top"
                    title={t('Some profiles may be left without a billing profile.')}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <Controller
                  name="assignedLocationsRelationships"
                  control={control}
                  render={({ field: { onChange, value }, formState }) => {
                    return (
                      <NewLocationAssignments
                        owner={owner?.customer_number}
                        mode={
                          !editFlag ||
                          getValues().profile.owner?.customer_type?.toLowerCase() === 'st' ||
                          getValues().profile.general_information?.set_all_locations ||
                          location?.customer_type.toLowerCase() === 'st'
                            ? 'view'
                            : 'edit'
                        }
                        disabled={checksBillingsReq.isLoading || checksBillingsReq.isFetching}
                        locations={(value || []).map((x) => ({ hash_key: x.hash_key || '' }))}
                        all={general_information?.set_all_locations || false}
                        onUpdate={(newAssignments) => {
                          if (newAssignments.length === 0) return;
                          const locations = transformAsigneeToBillingLocation(newAssignments);
                          const locationsList = locations.map((x) => x.hash_key?.split('~')[1] || '');
                          const auxList = getValues().profile.assigned_locations || [];
                          const auxListRelationships = getValues().assignedLocationsRelationships || [];
                          const auxConntacts = getValues().preApprovalsContacts;
                          setAllowUpdateChecks(true);
                          setAuxLocations({
                            list: auxList,
                            listRelationships: auxListRelationships,
                            toAll: getValues().profile.general_information?.set_all_locations || false,
                            auxPreApprovalsContacts: auxConntacts,
                          });
                          checkAndUpdateProfile({ wasChanged: 'select', locations: locationsList });
                          onChange(locations);
                          trigger('profile.general_information.set_all_locations');
                          setValue('profile.assigned_locations', locationsList);
                          const auxPreApprovalsContacts = preApprovalContactsFiltering({
                            ownerContacts: getValues().ownerContacts,
                            profile: getValues().profile,
                            assignedLocations: locations,
                            location: location,
                            loggedLocations: getValues().loggedLocationAndChildren,
                          });
                          setValue('preApprovalsContacts', auxPreApprovalsContacts);
                        }}
                        error={!!formState.errors.profile?.general_information?.set_all_locations}
                        style={{ margin: '0 0 0 auto' }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Switch
            label={t('Participates in Approve Orders Program?')}
            readOnly
            checked={general_information?.participates_in_approve_orders || false}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="profile.general_information.driver_signature_required"
            defaultValue={general_information?.driver_signature_required || false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={value}
                id="driverSignatureRequiredSwitch"
                readOnly={!editFlag}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                color="primary"
                disabled={!editFlag}
                label={t('Driver Signature Required?')}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="profile.general_information.driver_name_required"
            defaultValue={general_information?.driver_name_required || false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={value}
                id="driverNameRequiredSwitch"
                readOnly={!editFlag}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                color="primary"
                disabled={!editFlag}
                label={t('Driver Name Required?')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Switch
            label={t('PO Required for Invoicing?')}
            readOnly
            checked={general_information?.po_required_for_service || false}
            color="primary"
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
