import { Location, MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import {
  BillingContactsGet,
  BillingLocationsGet,
  BillingProfileGetOVM,
  BillingProfileIVM,
  BillingProfileOVM,
  ContactOVM,
  ResponseOVM,
} from '@michelin/fcp-view-models';
import { useMutation, useQuery } from '@tanstack/react-query';
import GLOBAL_CONFIG from 'global-variables';
import { ProfileParams, ServiceParams } from 'modules/Billing/Profiles/Components/SpeedDialActions';
import { checksPO } from 'modules/Billing/Profiles/types';
import { newBillingProfile } from 'modules/Billing/utils';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

interface BillingProfileDeleteResult {
  id: string;
}

const deleteBillingProfile = async ({
  fleetId,
  profileId,
}: {
  fleetId: string;
  profileId: string;
}): Promise<BillingProfileDeleteResult | null> => {
  if (!fleetId || !profileId) return null;
  const apiType: MaestroAPI = {
    method: 'DELETE',
    endpoint: `cp-be/billing/${fleetId}/profile/${profileId}`,
    baseURL,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return maestroApiCall(apiType);
};
function useDeleteBillingProfile() {
  return useMutation(deleteBillingProfile);
}

const getBillingProfile = async (
  fleetId: string,
  profileId: string,
  action: string,
  service: ServiceParams | '',
  profile: ProfileParams | '',
  location: Location | undefined,
): Promise<BillingProfileGetOVM | null> => {
  if (!fleetId) return null;
  if (action === 'create') {
    let checks = {
      po_required_for_service: false,
      participates_in_approve_orders: false,
    };
    const isShipTo = location?.customer_type.toLowerCase() === 'st';
    let ownerContacts: ContactOVM[] = [];

    const promises: Promise<any>[] = [];

    if (isShipTo) {
      const apiType: MaestroAPI = {
        method: MethodType.POST,
        endpoint: `/cp-be/billing/${fleetId}/profile/infoFromRequirements`,
        baseURL: baseURL || '',
      };
      promises.push(
        maestroApiCall<checksPO>(apiType, {
          owner: fleetId,
        }),
      );
    } else {
      promises.push(Promise.resolve(null));
    }

    const billingApiType: MaestroAPI = {
      method: MethodType.GET,
      endpoint: `/cp-be/contacts/${fleetId}?$limit=1000`,
      baseURL: baseURL || '',
    };
    promises.push(maestroApiCall<any>(billingApiType));

    const [shipToResponse, billingResponse] = await Promise.all(promises);

    if (shipToResponse) {
      checks.po_required_for_service = shipToResponse.po_required_for_service;
      checks.participates_in_approve_orders = shipToResponse.participates_in_approve_orders;
    }
    if (billingResponse) {
      ownerContacts = billingResponse.data;
    }
    const newProfile = newBillingProfile({ location, checks, isShipTo, service, profile });
    return {
      profile: newProfile,
      assignedLocationsRelationships: [],
      ownerContacts: ownerContacts,
    };
  } else {
    if (!profileId) return null;
    const apiType: MaestroAPI = {
      method: MethodType.GET,
      endpoint: `/cp-be/billing/${fleetId}/profile/${profileId}`,
      baseURL: baseURL || '',
    };
    const response = await maestroApiCall<BillingProfileGetOVM>(apiType);
    return {
      ...response,
    };
  }
};
function useGetBillingProfile({
  fleetId,
  profileId,
  key,
  service,
  profile,
  action,
  location,
}: {
  fleetId: string;
  profileId: string;
  key: string;
  service: ServiceParams | '';
  profile: ProfileParams | '';
  action: string;
  location: Location | undefined;
}) {
  return useQuery(['billing', key, fleetId, profileId], () => {
    return getBillingProfile(fleetId, profileId, action, service, profile, location);
  });
}

async function updateBillingProfile({
  fleetId,
  profileId,
  profileData,
  action,
}: {
  fleetId: string;
  profileId?: string;
  profileData: BillingProfileIVM;
  action: 'edit' | 'create';
}) {
  if (!fleetId) return null;
  const apiType: MaestroAPI = {
    method: action === 'edit' ? MethodType.PUT : MethodType.POST,
    endpoint:
      action === 'edit' ? `/cp-be/billing/${fleetId}/profile/${profileId}` : `/cp-be/billing/${fleetId}/profile`,
    baseURL: baseURL || '',
  };
  const res = await maestroApiCall<ResponseOVM<BillingProfileOVM | { hash_key: string }>>(apiType, profileData);
  return res;
}
function useUpdateBillingProfile({ fleetId, profileId }: { fleetId: string; profileId: string }) {
  return useMutation(updateBillingProfile, {});
}

const getBillingLocations = async ({
  fleetId,
  ownerId,
  profileId,
  byLevel,
  fleetLoggedData,
}: {
  fleetId: string;
  ownerId?: string;
  profileId?: string;
  byLevel: boolean;
  fleetLoggedData: boolean;
}): Promise<BillingLocationsGet | null> => {
  if (!fleetId) return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `/cp-be/billing/${fleetId}/locations?ownerId=${ownerId}&profileId=${profileId}&byLevel=${byLevel}&fleetLoggedData=${fleetLoggedData}`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<BillingLocationsGet>(apiType);
  return response;
};
function useGetBillingLocations({
  fleetId,
  ownerId,
  profileId,
  key,
  byLevel,
  fleetLoggedData,
}: {
  fleetId: string;
  ownerId?: string;
  profileId?: string;
  key: string;
  byLevel: boolean;
  fleetLoggedData: boolean;
}) {
  return useQuery(['billing', key, fleetId, profileId], () => {
    return getBillingLocations({ fleetId, ownerId, profileId, byLevel, fleetLoggedData });
  });
}
const getBillingContacts = async ({
  fleetId,
  ownerId,
  profileId,
  byLevel,
  fleetLoggedData,
}: {
  fleetId: string;
  ownerId?: string;
  profileId?: string;
  byLevel: boolean;
  fleetLoggedData: boolean;
}): Promise<BillingContactsGet | null> => {
  if (!fleetId) return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `/cp-be/billing/${fleetId}/contacts?ownerId=${ownerId}&profileId=${profileId}&byLevel=${byLevel}&fleetLoggedData=${fleetLoggedData}`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<BillingContactsGet>(apiType);
  return response;
};
function useGetBillingContacts({
  fleetId,
  ownerId,
  profileId,
  key,
  byLevel,
  fleetLoggedData,
}: {
  fleetId: string;
  ownerId?: string;
  profileId?: string;
  key: string;
  byLevel: boolean;
  fleetLoggedData: boolean;
}) {
  return useQuery(['billing', key, fleetId, profileId], () => {
    return getBillingContacts({ fleetId, ownerId, profileId, byLevel, fleetLoggedData });
  });
}

const getBillingRequirementsChecks = async ({
  fleetId,
  ownerId,
  toAll,
  locations,
  allowChecks,
}: {
  fleetId: string;
  ownerId?: string;
  toAll: boolean;
  locations: { customer_number: string; customer_type: string; relationship: string }[];
  allowChecks: boolean;
}): Promise<{ po_required_for_service: boolean; participates_in_approve_orders: boolean } | null> => {
  if (!fleetId || !allowChecks) return null;
  const apiType: MaestroAPI = {
    method: MethodType.POST,
    endpoint: `/cp-be/billing/${fleetId}/profile/infoFromRequirements`,
    baseURL: baseURL || '',
  };
  const data: {
    owner?: string;
    locations?: { customer_number: string; customer_type: string; relationship: string }[];
  } = {};
  if (toAll) {
    data['owner'] = ownerId;
  } else if (locations.length > 0) {
    data['locations'] = locations;
  }
  const response = await maestroApiCall<{ po_required_for_service: boolean; participates_in_approve_orders: boolean }>(
    apiType,
    data,
  );
  return response;
};
function useGetBillingRequirementsChecks({
  fleetId,
  ownerId,
  toAll,
  locations,
  key,
  allowChecks,
}: {
  fleetId: string;
  ownerId?: string;
  toAll: boolean;
  locations: { customer_number: string; customer_type: string; relationship: string }[];
  key: string;
  allowChecks: boolean;
}) {
  return useQuery(['billings', key], () => {
    return getBillingRequirementsChecks({ fleetId, ownerId, toAll, locations, allowChecks });
  });
}

export {
  useUpdateBillingProfile,
  useGetBillingProfile,
  useDeleteBillingProfile,
  useGetBillingLocations,
  useGetBillingContacts,
  useGetBillingRequirementsChecks,
};
