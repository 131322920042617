import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, WithDialogsProps, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { LocationOVM } from '@michelin/fcp-view-models';
import { useQueryClient } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ViewCreateParams } from '../../DetailPage';
import { BillingProfileContextData, LocationContactRole } from '../../types';
import { LocationsContactsDialog } from './LocationsContacsDialog';
import { BillingReassignContactType, MissingCriticalRolesDialog } from './MissingCriticalRolesDialog';
import {
  missingCriticalRolesText,
  missingCriticalRolesTitle,
  saveReassignations,
  updateAssignedContactTypes,
} from './utils';

interface Props {
  displayWarningDialog: boolean;
  onClose: () => void;
  contactLevel: 'primary' | 'secondary';
  contactTypes: Array<string>;
  locationsWithContacts: string[];
  locationsWithContactsToAssignData: LocationOVM[];
  ppName: 'ers' | 'onsite';
  shortContactLevel: 'pri' | 'sec';
  disableEditContactTypes: boolean;
  displayLocationsContactsDialog: boolean;
  watchContactTypeList: string[];
  onCloseLocationsContactsDialog: () => void;
  locationsContactsRolesData: LocationContactRole[];
}

export function ReassignContactTypes(props: Props): JSX.Element {
  const {
    onClose,
    displayWarningDialog,
    contactLevel,
    contactTypes,
    locationsWithContacts,
    locationsWithContactsToAssignData,
    ppName,
    shortContactLevel,
    disableEditContactTypes,
    displayLocationsContactsDialog,
    watchContactTypeList,
    onCloseLocationsContactsDialog,
    locationsContactsRolesData,
  } = props;
  const { getValues, setValue } = useFormContext<BillingProfileContextData>();
  const { profile, authorizeServiceLocations } = getValues();
  const params = useParams<ViewCreateParams>();
  const queryClient = useQueryClient();
  const dialogs: WithDialogsProps = useDialogs();

  const { t } = useTranslation();

  const [openMissingCriticalRolesDialog, setOpenMissingCriticalRolesDialog] = useState(false);
  const locLevel =
    (profile?.[`${ppName}_purchasing_procedures`]?.[
      `${shortContactLevel}_ers_authorized_contact_level`
    ] as keyof typeof authorizeServiceLocations) || '';

  const criticalText = missingCriticalRolesText(
    locationsWithContactsToAssignData.length,
    locationsWithContactsToAssignData.length + locationsWithContacts.length,
    locLevel,
    profile?.[`${ppName}_purchasing_procedures`]?.[`${shortContactLevel}_ers_authorized_contact_type`] || [],
    !disableEditContactTypes,
    t,
  );

  if (
    locationsWithContacts.length > 0 &&
    displayLocationsContactsDialog &&
    watchContactTypeList.length > 0
  ) {
    return (
      <LocationsContactsDialog
        open={displayLocationsContactsDialog && !displayWarningDialog}
        contactTypeLevel={contactLevel}
        contactTypes={watchContactTypeList}
        onClose={onCloseLocationsContactsDialog}
        locationsContactsRolesData={locationsContactsRolesData}
      />
    );
  }
  const resetLists = () => {
    setValue('isAuthorizeServiceContactsLoading', true);
    setValue('isAuthorizeServiceLocationsLoading', true);
    queryClient.invalidateQueries([
      'billing',
      'AuthorizeServiceLocations',
      params.selectedAccount || '',
      params.profileId,
    ]);
    queryClient.invalidateQueries([
      'billing',
      'AuthorizeServiceContacts',
      params.selectedAccount || '',
      params.profileId,
    ]);
  };
  return (
    <>
      <Dialog
        open={
          displayWarningDialog &&
          !displayLocationsContactsDialog &&
          locationsWithContactsToAssignData.length > 0 &&
          !openMissingCriticalRolesDialog
        }
      >
        <DialogTitle>{t(missingCriticalRolesTitle)}</DialogTitle>
        <DialogContent>{criticalText}</DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              setOpenMissingCriticalRolesDialog(true);
              onClose();
            }}
            disabled={disableEditContactTypes}
          >
            {t('Update')}
          </Button>
          <Button color="danger" onClick={() => onClose()}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      {locationsWithContactsToAssignData.length > 0 && openMissingCriticalRolesDialog && (
        <MissingCriticalRolesDialog
          open={openMissingCriticalRolesDialog}
          locations={locationsWithContactsToAssignData}
          onCancel={(reset) => {
            setOpenMissingCriticalRolesDialog(false);
            if (reset) {
              resetLists();
            }
          }}
          onSave={(assignedContactTypes, needToBeSaved) => {
            setOpenMissingCriticalRolesDialog(false);
            if (needToBeSaved) {
              dialogs.confirmDialog(
                t('Do you want to update the contacts?. This action cannot be undone.'),
                t('Update contacts'),
                t('Yes'),
                t('No'),
                async () => {
                  setValue('isAuthorizeServiceContactsLoading', true);
                  setValue('isAuthorizeServiceLocationsLoading', true);
                  const updatedAssignedContactTypes = updateAssignedContactTypes(assignedContactTypes);
                  await saveReassignations(new Map(updatedAssignedContactTypes));
                  queryClient.invalidateQueries([
                    'billing',
                    'AuthorizeServiceLocations',
                    params.selectedAccount || '',
                    params.profileId,
                  ]);
                  queryClient.invalidateQueries([
                    'billing',
                    'AuthorizeServiceContacts',
                    params.selectedAccount || '',
                    params.profileId,
                  ]);
                },
                () => {},
              );
            } else {
              resetLists();
            }
          }}
          alreadyAssignedContactTypes={new Map<string, BillingReassignContactType>()}
          contactTypes={contactTypes}
          contactTypeLevel={contactLevel}
        />
      )}
    </>
  );
}
