import React from 'react';
import { ArrowBack, Cancel, Edit, Save, Timelapse } from '@material-ui/icons';
import { Fab, GlobalActions } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { isHierarchyHigher } from 'utils/reusableMethods';
import { BillingProfileContextData } from '../types';

interface Props {
  savingFlag: boolean;
  editFlag: boolean;
  modifiedFlag: boolean;
  profileId: string;
  action: string;
  handleSaveClick: () => void;
  loadingData: boolean;
}

function BillingFabs(props: Props): JSX.Element {
  const { savingFlag, editFlag, modifiedFlag, profileId, handleSaveClick, action, loadingData } = props;
  const { getValues, watch } = useFormContext<BillingProfileContextData>();
  const updatePermission = getValues().allowedActions?.update;
  const watchUpdatingRequirementsCheks = watch('isBillingRequirementsChecksLoading')
  const profile = getValues().profile;
  const { location } = usePermissions();
  const hierarchyHigher = isHierarchyHigher(location?.customer_type || '', profile?.owner?.customer_type || '');
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <GlobalActions>
      <Fab color="inherit" aria-label={t('Saving')} disabled={!savingFlag}>
        <Timelapse />
      </Fab>
      <Fab
        color={modifiedFlag ? 'primary' : 'inherit'}
        aria-label={t('Save')}
        disabled={savingFlag || !editFlag || updatePermission === false || watchUpdatingRequirementsCheks}
        displayDisabled={watchUpdatingRequirementsCheks}
        onClick={() => {
          if (modifiedFlag) handleSaveClick();
        }}
      >
        <Save />
      </Fab>
      <Fab
        color="primary"
        aria-label={t('Edit')}
        disabled={savingFlag !== false || editFlag || !updatePermission || !hierarchyHigher || loadingData}
        onClick={() => {
          history.push(`${getBaseUrl()}/billing/${profileId}/edit`);
        }}
      >
        <Edit />
      </Fab>
      <Fab
        color="primary"
        label={t('Back')}
        disabled={action.toLowerCase() !== 'view' || savingFlag}
        onClick={() => {
          const path = editFlag && profileId ? `/billing/${profileId}/view` : '/billings';
          history.push(`${getBaseUrl()}${path}`);
        }}
      >
        <ArrowBack />
      </Fab>

      <Fab
        color={'primary'}
        disabled={action.toLowerCase() === 'view' || savingFlag}
        label={t('Cancel')}
        onClick={() => {
          const path = editFlag && profileId ? `/billing/${profileId}/view` : '/billings';
          history.push(`${getBaseUrl()}${path}`);
        }}
      >
        <Cancel />
      </Fab>
    </GlobalActions>
  );
}

export { BillingFabs };
