/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, Select, SelectOption, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import Autocomplete from 'components/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { BillingProfileContextData, BillingRequestedPhotosTypes, BillingServiceTypes } from '../types';

interface PhotoTypesProps {
  editFlag: boolean;
}

function PhotoTypes(props: PhotoTypesProps) {
  const { t } = useTranslation();
  const { editFlag } = props;
  const [photoTypes, setPhotoTypes] = useState<SelectOption[]>([]);
  const { getValues, control, setValue, watch } = useFormContext<BillingProfileContextData>();
  const { profile } = getValues();
  useEffect(() => {
    if (profile?.general_information?.service_type === undefined) return;
    const opts: SelectOption[] = getOptions('photo_types').filter((x) => {
      if (
        profile?.general_information?.service_type === BillingServiceTypes.MECHANICAL &&
        (x.value === 'asset_before' || x.value === 'asset_after')
      ) {
        return true;
      }
      if (
        profile?.general_information?.service_type === BillingServiceTypes.TIRE_WHEEL &&
        x.value !== 'asset_before' &&
        x.value !== 'asset_after'
      ) {
        return true;
      }
      return false;
    });
    setPhotoTypes(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.general_information?.service_type]);
  const requestedPhotosTypes = watch('profile.requested_photos.types');

  const displayTireIdentifierType =
    requestedPhotosTypes &&
    requestedPhotosTypes.length > 0 &&
    requestedPhotosTypes.includes(BillingRequestedPhotosTypes.TIRE_IDENTIFIER);

  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: 5 }}>
        <Grid item xs={12} md={displayTireIdentifierType ? 5 : 7}>
          <Controller
            name="profile.requested_photos.types"
            control={control}
            render={(item) => (
              <Autocomplete
                id={item.field.name}
                data-testid="requested-photos-test"
                multiple
                variant="standard"
                label={t('Requested Types of Photos')}
                disableClearable
                disableCloseOnSelect
                placeholder=""
                onChange={(value) => {
                  item.field.onChange(value);
                  if (!value.includes(BillingRequestedPhotosTypes.TIRE_IDENTIFIER)) {
                    setValue('profile.requested_photos.identifier_type', '');
                  }
                }}
                suggestions={photoTypes}
                values={item.field.value}
                readOnly={!editFlag}
              />
            )}
          />
        </Grid>
        {displayTireIdentifierType ? (
          <Grid item xs={12} md={3}>
            <Controller
              name="profile.requested_photos.identifier_type"
              control={control}
              render={(item) => (
                <Select
                  id={item.field.name}
                  name="identifier_type"
                  data-testid="identifier-type-test"
                  label={t('Tire Identifier Type')}
                  value={item.field.value}
                  style={{ width: '100%' }}
                  readOnly={!editFlag}
                  options={getOptions('photo_identifier_types')}
                  onChange={(e) => item.field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={displayTireIdentifierType ? 3 : 4}>
          <Controller
            name="profile.requested_photos.other_photos"
            control={control}
            render={(item) => (
              <TextField
                id={item.field.name}
                name="other_photos"
                value={item.field.value}
                label={t('Other Photos')}
                inputProps={{
                  'aria-label': 'other-photos-textfield',
                }}
                readOnly={!editFlag}
                onChange={(e) => item.field.onChange(e.target.value)}
                maxLength={250}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

interface RequestedPhotosDetailProps {
  action: string;
}

export function RequestedPhotosDetail(props: RequestedPhotosDetailProps) {
  const { t } = useTranslation();
  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';
  const title = t('Requested Photos');

  return (
    <Panel
      title={title}
      subtitle={
        <span style={{ fontSize: '0.8em' }}>
          <Alert severity="warning">
            {t(
              'Michelin does not guarantee the applicable dealer from its independent dealer network will provide these Requested Details.',
            )}
          </Alert>
        </span>
      }
      style={{ marginBottom: 15 }}
    >
      <PhotoTypes editFlag={editFlag} />
    </Panel>
  );
}
