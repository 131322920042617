/* eslint-disable max-len */

/* eslint-disable react/no-array-index-key */
import React, { CSSProperties, Children, ReactNode } from 'react';
import { Paper } from '@material-ui/core';
import { TireProfilePriorities } from '@michelin/fcp-view-models';
import { AvailableSelectOptions, getOptionLabel } from '@michelin/select-options-provider';
import { renderNotificationType } from 'components/Contact/ContactDetails/NotificationSubscriptions';
import { ContactType } from 'components/Contact/utils';
import { getDates } from 'components/HoursOfOperation/Types';
import { SOPBrandPreferences, SOPInflation, SOPPullPoint, SOPWheelBalance } from 'components/SOPs/Types';
import { getPartialTimeString } from 'components/Util';
import { WheelDetailsBrand } from 'modules/Wheels/types';
import { formatDate } from 'utils/reusableMethods';
import { getFieldLabel, ignoreFields } from './Fields';
import { BillingTireOffInstalled } from 'modules/Billing/Profiles/types';

const moment = require('moment');

export type TranslateFunction = (s: string, r?: any) => string;

interface NotificationSubscription {
  id: string;
  cc_emails: string[];
  event: string;
  method: string[];
  type: string;
}

const VALIDATION_REM_COLOR = 'rgb(241, 111, 111)';
const VALIDATION_ADD_COLOR = 'rgb(102, 175, 78)';

const renderBoolean = (value: boolean, t: TranslateFunction) => {
  if (value === false) return <span style={{ color: VALIDATION_REM_COLOR }}>{t('No')}</span>;
  return <span style={{ color: VALIDATION_ADD_COLOR }}>{t('Yes')}</span>;
};

const renderString = (value: any) => {
  if (!value || Object.keys(value).length === 0) return <span />;
  return <span>{value}</span>;
};

const renderDate = (value: any, t: any) => {
  if (!value || Object.keys(value).length === 0) return <span />;

  return <span>{moment(value).format('lll')}</span>;
};

type TireDetails = {
  billing_tire_casing_age: string;
  id: string;
  off_installed: string;
  other: string;
  requested_details: string[];
};
const renderTireDetails = (value: TireDetails[], t: any) => {
  return (
    <>
      {value.map((detail: TireDetails, index: number) => {
        if (Object.keys(detail).length === 0) return <span />;
        return (
          <>
            <div style={{ paddingLeft: 10 }} key={`render-tire-detail-${index}`}>
              <b>{t(`Option {{i}}`, { i: index + 1 })}</b>: &nbsp;
              <div style={{ paddingLeft: 10 }}>
                {detail.billing_tire_casing_age && (
                  <div>
                    <b>{t('Casing Details')}</b>:{' '}
                    {getOptionLabel('tire_casing_age', detail.billing_tire_casing_age as string)}
                  </div>
                )}
                {detail.off_installed && (
                  <div>
                    <b>{t('Installed')}</b>:{' '}
                    {BillingTireOffInstalled.INSTALLED_TIRES === detail.off_installed ? t('Installed') : t('Off')}
                  </div>
                )}
                {detail.other && (
                  <div>
                    <b>{t('Other')}</b>: {detail.other}
                  </div>
                )}
                {detail.requested_details && (
                  <div>
                    <b>{t('Requested Details')}</b>:{' '}
                    <div style={{ paddingLeft: 10 }}>
                      {detail.requested_details
                        .map((item) => {
                          return getOptionLabel('tire_requested_details', item.split(' ')[1])
                            ? getOptionLabel('tire_requested_details', item.split(' ')[1])
                            : item.split(' ')[1];
                        })
                        .filter((item) => item)
                        .join(', ')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

const renderArrayValues = (value: string[]) => {
  if (!value || value.length === 0) return <span />;
  return <div style={{ paddingLeft: 10 }}>{value.join(', ')}</div>;
};

type PhotoRequested = {
  types: string[];
  identifier_type: string;
  other_photos: string;
};
const renderRequestPhotos = (value: PhotoRequested, t: any) => {
  if (Object.keys(value).length === 0) return <span />;
  return (
    <>
      <div style={{ paddingLeft: 10 }}>
        {value.types && (
          <div>
            <b>{t('Types')}</b>:{' '}
            <div style={{ paddingLeft: 10 }}>
              {value.types
                .map((item) => {
                  return getOptionLabel('photo_types', item.split(' ')[1])
                    ? getOptionLabel('photo_types', item.split(' ')[1])
                    : item.split(' ')[1];
                })
                .filter((item) => item)
                .join(', ')}
            </div>
          </div>
        )}
        {value.identifier_type && (
          <div>
            <b>{t('Tire Identifier Type')}</b>: {getOptionLabel('photo_identifier_types', value.identifier_type)}
          </div>
        )}
        {value.other_photos && (
          <div>
            <b>{t('Other Photos')}</b>: {value.other_photos}
          </div>
        )}
      </div>
    </>
  );
};

const renderArrayOptionsValues = (value: string[], options: AvailableSelectOptions) => {
  if (!value || value.length === 0) return <span />;
  const array: string = value.map((item) => getOptionLabel(options, item.split(' ')[1])).join(', ');
  return <div style={{ paddingLeft: 10 }}>{array}</div>;
};

const renderVehicleTypeVMRSCode = (value: string, data: any) => {
  let type = '';
  if (data.after.main?.vehicle_type_vmrs_code === value) {
    type = data.after.main?.profile_type;
  } else {
    type = data.before.main?.profile_type;
  }
  if (type === 'onsite') {
    return getOptionLabel('vehicle_types_onsite', value);
  }
  return getOptionLabel('vehicle_types_ers', value);
};

type WheelDetails = {
  id: string;
  off_installed: string;
  other: string;
  requested_details: string[];
};
const renderWheelDetails = (value: WheelDetails[], t: any) => {
  return (
    <>
      {value.map((detail: WheelDetails, index: number) => {
        if (Object.keys(detail).length === 0) return <span />;
        return (
          <>
            <div style={{ paddingLeft: 10 }} key={`render-wheel-detail-${index}`}>
              <b>{t(`Option {{i}}`, { i: index + 1 })}</b>: &nbsp;
              <div style={{ paddingLeft: 10 }}>
                {detail.off_installed ? (
                  <div>
                    <b>{t('Installed')}</b>:{' '}
                    {BillingTireOffInstalled.INSTALLED_TIRES === detail.off_installed ? t('Installed') : t('Off')}
                  </div>
                ) : (
                  <></>
                )}
                {detail.other ? (
                  <div>
                    <b>{t('Other')}</b>: {detail.other}
                  </div>
                ) : (
                  <></>
                )}
                {detail.requested_details ? (
                  <div>
                    <b>{t('Requested Details')}</b>:{' '}
                    {detail.requested_details
                      .map((item) => {
                        return getOptionLabel('wheel_requested_details', item.split(' ')[1])
                          ? getOptionLabel('wheel_requested_details', item.split(' ')[1])
                          : item.split(' ')[1];
                      })
                      .filter((item) => item)
                      .join(', ')}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
const renderAssignedLocations = (value: string[], t: any) => {
  if (!value || value.length === 0) return <span />;
  let auxLocations: string[] = [...value]
    .map((item) => (item ? item.split(' ') : ['', '']))
    .filter((item) => item[1])
    .map((item) => item[1]);
  if (auxLocations.length === 0) return <></>;
  if (auxLocations.length > 8) {
    auxLocations = auxLocations.slice(0, 8);
    auxLocations.push(t('and others'));
  }
  return <div style={{ paddingLeft: 10 }}>{auxLocations.join(', ')}</div>;
};
function Row({ children, rowTemplate }: { children: ReactNode; rowTemplate?: string }) {
  const count = Children.count(children);
  return (
    <div style={{ paddingTop: '5px' }}>
      <span
        style={{
          display: 'grid',
          gridTemplateColumns: rowTemplate || '1fr '.repeat(count),
          minHeight: '32px',
          justifyItems: 'left',
        }}
      >
        {children}
      </span>
    </div>
  );
}

const renderTireReplacementPriority = (
  value: TireProfilePriorities[],
  data: any,
  field: any,
  countryCode: 'us' | 'ca',
  t: any,
  axle: string,
) => {
  if (!value) return <></>;
  return (
    <>
      {value.map((detail: TireProfilePriorities, index: number) => {
        if (typeof detail === 'string') return <span />;
        if (Object.keys(detail).length === 0) return <span />;
        if (field === 'diff' && !data?.after?.axles[axle]) return <span />;
        if (field === 'diff' && data?.after?.axles[axle]?.tire_replacement_priority.length <= index) return <span />;
        if (field === 'origin' && !data?.before?.axles[axle]) return <span />;
        if (field === 'origin' && data?.before?.axles[axle]?.tire_replacement_priority.length <= index) return <span />;
        const size: string =
          field === 'origin'
            ? data?.before?.axles[axle]?.tire_replacement_priority[index]?.tire_size
            : data?.after?.axles[axle]?.tire_replacement_priority[index]?.tire_size;
        const priority: string =
          field === 'origin'
            ? data?.before?.axles[axle]?.tire_replacement_priority[index]?.priority
            : data?.after?.axles[axle]?.tire_replacement_priority[index]?.priority;
        return (
          <div style={{ paddingLeft: 10 }} key={`tire-remplacement-${field}-${size}-${priority}`}>
            <b>
              {t('Tire size {{size}} - Priority {{priority}}', {
                size: getOptionLabel(`${countryCode === 'us' ? 'USA' : 'CAN'}_tire_sizes`, size),
                priority,
              })}
            </b>
            :
            <div style={{ paddingLeft: 10 }}>
              {detail.tire_type && (
                <div>
                  <b>{t('Tire Type')}</b>: {detail.tire_type}
                </div>
              )}
              {detail.brand && (
                <div>
                  <b>{t('Brand')}</b>: {detail.brand}
                </div>
              )}
              {detail.tread_design && (
                <div>
                  <b> {t('Tread Design')}</b>: {detail.tread_design}
                </div>
              )}
              {detail.load_range && (
                <div>
                  <b> {t('Load Range')}</b>: {detail.load_range}
                </div>
              )}
              {detail.mspn && (
                <div>
                  <b> {t('MSPN')}</b>: {detail.mspn}
                </div>
              )}
              {detail.width && (
                <div>
                  <b> {t('Width')}</b>: {detail.width}
                </div>
              )}
              {detail.original_tire_depth && (
                <div>
                  <b> {t('Original Tire Depth')}</b>: {detail.original_tire_depth}
                </div>
              )}
              {detail.note && (
                <div>
                  <b> {t('Notes')}</b>: {detail.note}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

type PassengerLtPriority = {
  brand: string;
  mspn: string;
  priority: number;
  speed_rating: string;
  tire_size: string;
  tread_design: string;
  note: string;
};
const renderPassengerLtPriority = (
  value: PassengerLtPriority[],
  data: any,
  field: any,
  countryCode: 'us' | 'ca',
  t: any,
) => {
  if (!value) return <></>;

  return (
    <>
      {value.map((detail: PassengerLtPriority, index: number) => {
        if (typeof detail === 'string') return <span />;
        if (Object.keys(detail).length === 0) return <span />;
        if (field === 'diff' && !data?.after?.axles.all_axles) return <span />;
        if (field === 'diff' && data?.after?.axles.all_axles?.passenger_lt_priority.length <= index) return <span />;
        if (field === 'origin' && !data?.before?.axles.all_axles) return <span />;
        if (field === 'origin' && data?.before?.axles.all_axles?.passenger_lt_priority.length <= index) return <span />;

        const priority: string =
          field === 'origin'
            ? data?.before?.axles.all_axles?.passenger_lt_priority[index]?.priority
            : data?.after?.axles.all_axles?.passenger_lt_priority[index]?.priority;
        return (
          <div style={{ paddingLeft: 10 }} key={`tire-passenger-lt-${field}-${priority}`}>
            <b>
              {t('Priority {{priority}}', {
                priority,
              })}
            </b>
            :
            <div style={{ paddingLeft: 10 }}>
              {detail.brand && (
                <div>
                  <b>{t('Brand')}</b>: {detail.brand}
                </div>
              )}
              {detail.tire_size && (
                <div>
                  <b>{t('Tire size')}</b>:{' '}
                  {getOptionLabel(`${countryCode === 'us' ? 'USA' : 'CAN'}_tire_sizes`, detail.tire_size)}
                </div>
              )}
              {detail.tread_design && (
                <div>
                  <b> {t('Tread Design')}</b>: {detail.tread_design}
                </div>
              )}
              {detail.speed_rating && (
                <div>
                  <b> {t('Speed Rating')}</b>: {detail.speed_rating}
                </div>
              )}
              {detail.mspn && (
                <div>
                  <b> {t('MSPN')}</b>: {detail.mspn}
                </div>
              )}
              {detail.note && (
                <div>
                  <b> {t('Notes')}</b>: {detail.note}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
function Field({ label, value }: { label?: string; value?: string | number | ReactNode }) {
  return (
    <div>
      {label && <div style={{ fontSize: '11px', color: 'rgba(0, 0, 0, 0.50)' }}>{`${label}:`}</div>}
      {value && <div>{value}</div>}
    </div>
  );
}

const renderInflation = (
  value: SOPInflation[],
  data: any,
  field: string,
  t: TranslateFunction,
): React.ReactElement | null => {
  if (!value) return null;

  return (
    <div>
      {value.map((inflation: SOPInflation, index) => {
        const uom = inflation.uom ? getOptionLabel('pressure_types', inflation.uom) : '';

        return (
          <Paper key={index} style={{ margin: '10px 0px', padding: '10px' }}>
            <Row>
              <Field label={t('Record Id')} value={(inflation.id || 0).toString().padStart(5, 'IP000')} />
              <Field label={t('Tire Size')} value={inflation.size || ''} />
              <Field label={t('Unit of Measurement')} value={uom} />
            </Row>
            <Row>
              <Field label={t('Axle Type')} value={getOptionLabel('axle_types', inflation.axle_type || '')} />
              <Field
                label={t('Application Type')}
                value={getOptionLabel('application_types', inflation.application_type || '')}
              />
              <Field
                label={t('Vehicle Type')}
                value={getOptionLabel('vehicle_types_onsite', inflation.vehicle_type || '')}
              />
            </Row>
            <Row>
              <Field
                label={t('Min Pressure')}
                value={inflation.min_pressure ? `${inflation.min_pressure} ${uom}` : ''}
              />
              <Field
                label={t('Target Pressure')}
                value={inflation.target_pressure ? `${inflation.target_pressure} ${uom}` : ''}
              />
              <Field
                label={t('Max Pressure')}
                value={inflation.max_pressure ? `${inflation.max_pressure} ${uom}` : ''}
              />
            </Row>
            <Row>
              <Field
                label={t('Removal for Under Inflation')}
                value={inflation.under_inflation_removal ? `${inflation.under_inflation_removal} ${uom}` : ''}
              />
              <Field
                label={t('Removal for Over Inflation')}
                value={inflation.over_inflation_removal ? `${inflation.over_inflation_removal} ${uom}` : ''}
              />
            </Row>
            <Row>
              <Field label={t('Comments')} value={inflation.comments || ''} />
            </Row>
          </Paper>
        );
      })}
    </div>
  );
};

const renderWheelBalance = (
  value: SOPWheelBalance[],
  data: any,
  field: string,
  t: TranslateFunction,
): React.ReactElement | null => {
  if (!value) return null;

  return (
    <div>
      {value.map((wheel: SOPWheelBalance, index) => {
        const uom = wheel.uom ? getOptionLabel('pressure_types', wheel.uom) : '';

        return (
          <Paper key={index} style={{ margin: '10px 0px', padding: '10px' }}>
            <Row>
              <Field label={t('Record Id')} value={(wheel.id || 0).toString().padStart(5, 'WB000')} />
              <Field label={t('Axle Type')} value={getOptionLabel('axle_types', wheel.axle_type || '')} />
              <Field
                label={t('Application Type')}
                value={getOptionLabel('application_types', wheel.application_type || '')}
              />
              <Field
                label={t('Vehicle Type')}
                value={getOptionLabel('vehicle_types_onsite', wheel.vehicle_type || '')}
              />
            </Row>
            <Row>
              <Field label={t('Unit of Measurement')} value={uom} />
              <Field label={t('Max Weight')} value={wheel.max_weight ? `${wheel.max_weight} ${uom}` : ''} />
            </Row>
            <Row>
              <Field
                label={t('Preferred Rotation Pattern')}
                value={getOptionLabel('preferred_tire_rotation_patterns', wheel?.preferred_rotation_pattern)}
              />
              <Field
                label={t('Balancing Preference')}
                value={getOptionLabel('balance_options', wheel?.balancing_preference)}
              />
            </Row>
            <Row>
              <Field
                label={t('Require Radial Runout Match Mounting')}
                value={renderBoolean(wheel.require_radial_runout_match_mounting, t)}
              />
            </Row>
            <Row>
              <Field label={t('Comments')} value={wheel.comments || ''} />
            </Row>
          </Paper>
        );
      })}
    </div>
  );
};

const renderBrandPreferences = (
  value: SOPBrandPreferences[],
  data: any,
  field: string,
  t: TranslateFunction,
): React.ReactElement | null => {
  if (!value) return null;

  return (
    <div>
      {value.map((brand: SOPBrandPreferences, index) => (
        <Paper key={index} style={{ margin: '10px 0px', padding: '10px' }}>
          <Row rowTemplate="1fr 1fr 1fr 40px">
            <Field label={t('Record Id')} value={(brand.id || 1).toString().padStart(5, 'BP000')} />
            <Field label={t('Brand Type')} value={getOptionLabel('brand_types', brand.type || '')} />
            <Field label={t('Name')} value={brand.brand} />
            <Field label={t('Priority')} value={brand.priority || ''} />
          </Row>
        </Paper>
      ))}
    </div>
  );
};

const renderWheelBrands = (brands: WheelDetailsBrand[], t: TranslateFunction): React.ReactElement | null => {
  if (!brands) return null;
  return (
    <div>
      <Paper style={{ margin: '10px 0px', padding: '10px' }}>
        <Row>
          <Field label={t('Priority')} />
          <Field label={t('Brand')} />
        </Row>
        {brands.map((brand: WheelDetailsBrand, index) => (
          <Row>
            <Field value={brand.priority || ''} />
            <Field value={getOptionLabel('brand_types', brand.brand || '')} />
          </Row>
        ))}
      </Paper>
    </div>
  );
};

const renderPullPoints = (
  value: SOPPullPoint[],
  data: any,
  field: string,
  t: TranslateFunction,
): React.ReactElement => {
  if (!value) return <></>;

  return (
    <div>
      {value.map((pullPoint: SOPPullPoint, index) => {
        const uom = pullPoint.uom ? getOptionLabel('units_of_measurement', pullPoint.uom) : '';

        return (
          <Paper key={index} style={{ margin: '10px 0px', padding: '10px' }}>
            <Row>
              <Field label={t('Record Id')} value={(pullPoint.id || 0).toString().padStart(5, 'TP000')} />
              <Field label={t('Tire Size')} value={pullPoint.size} />
              <Field label={t('Unit of Measurement')} value={uom} />
            </Row>
            <Row>
              <Field label={t('Axle Type')} value={getOptionLabel('axle_types', pullPoint.axle_type || '')} />
              <Field
                label={t('Application Type')}
                value={getOptionLabel('application_types', pullPoint.application_type || '')}
              />
              <Field
                label={t('Vehicle Type')}
                value={getOptionLabel('vehicle_types_onsite', pullPoint.vehicle_type || '')}
              />
            </Row>
            <Row>
              <Field
                label={t('Section Max Repairs')}
                value={pullPoint.section_max_repairs ? `${pullPoint.section_max_repairs} ${uom}` : ''}
              />
              <Field
                label={t('Flat Max Repairs')}
                value={pullPoint.flat_max_repairs ? `${pullPoint.flat_max_repairs} ${uom}` : ''}
              />
            </Row>
            <Row>
              <Field label={t('ERS Removal')} value={pullPoint.ers_removal ? `${pullPoint.ers_removal} ${uom}` : ''} />
              <Field label={t('PM Removal')} value={pullPoint.pm_removal ? `${pullPoint.pm_removal} ${uom}` : ''} />
            </Row>
            <Row>
              <Field
                label={t('Fleet Inspection Removal')}
                value={pullPoint.inspection_removal ? `${pullPoint.inspection_removal} ${uom}` : ''}
              />
              <Field
                label={t('Matching Within')}
                value={pullPoint.td_matching_within ? `${pullPoint.td_matching_within} ${uom}` : ''}
              />
            </Row>
            <Row>
              <Field
                label={t('Dual Tread Depth Matching')}
                value={getOptionLabel('dual_tread_depth_matching', pullPoint.dual_tread_depth_matching || '')}
              />
            </Row>
            <Row>
              <Field label={t('Comments')} value={pullPoint.comments || ''} />
            </Row>
          </Paper>
        );
      })}
    </div>
  );
};

const renderHoursOfOperations = (hoo: string, countryCode: string, t: TranslateFunction) => {
  const workHours: { key: string; label: string; value: string | JSX.Element }[] = [];
  const hooDays = getDates(countryCode.toLowerCase());
  if (!hoo) return <div />;
  Object.entries(hoo).forEach(([key, val]) => {
    let time: string | JSX.Element = '';

    if (val && Object.keys(val).length !== 0 && (val as any).begin !== undefined && (val as any).end !== undefined) {
      const begin = ((val as any).begin || '').trim();
      const end = ((val as any).end || '').trim();

      if (!end) time = `Opens at ${getPartialTimeString(begin)}`;
      if (!begin) time = `Closes at ${getPartialTimeString(end)}`;

      if (time === '') time = `${getPartialTimeString(begin)} to ${getPartialTimeString(end)}`;
    } else {
      time = <span style={{ color: '#888', fontStyle: 'italic' }}>{t('Closed')}</span>;
    }

    workHours.push({
      key,
      label: t(hooDays.find((day) => day.key === key)?.label || ''),
      value: time,
    });
  });

  // order the workHours array by the Hour of Operation Days
  const orderedWorkHours = workHours.sort((a, b) => {
    const aIndex = hooDays.findIndex((day) => day.key === a.key);
    const bIndex = hooDays.findIndex((day) => day.key === b.key);

    return aIndex - bIndex;
  });

  return (
    <>
      {orderedWorkHours.map((item) => (
        <div key={item.label} style={{ paddingLeft: 10, display: 'grid', gridTemplateColumns: '1fr 10px 2fr' }}>
          <div>{item.label}</div>
          <div>:</div>
          <div>{item.value}</div>
        </div>
      ))}
    </>
  );
};

const renderContactTypes = (values: ContactType[], t: TranslateFunction, data?: any, field?: string) => {
  if (!values || values.length === 0) return <div />;
  let contactTypes: ContactType[] = [...values];
  const service = (item: ContactType, index: number) => {
    let service = '';
    if (item.service) {
      service = item.service;
    } else {
      if (field === 'origin') {
        service = data?.before?.contact_types?.[index]?.service || '';
      } else if (field === 'diff') {
        service = data?.after?.contact_types?.[index]?.service || '';
      }
    }
    return service;
  };
  return (
    <div style={{ paddingLeft: 10 }}>
      {contactTypes.map((item, index) => {
        if (!item || !item.role_areas || item.role_areas.length === 0) return <></>;

        return (
          <>
            <div style={{ marginRight: '10px' }}>{t(getFieldLabel(service(item, index)) || 'Contact Types')}</div>
            <ul style={{ margin: '0px' }}>
              {item?.role_areas?.map((roleArea) => {
                const label = getOptionLabel('contact_types', roleArea.role_area);

                if (!roleArea.levels || roleArea.levels.length === 0) return <li>{label}</li>;

                const level = roleArea?.levels
                  .map((lvl) => `(${lvl?.level}${lvl?.location ? ` ${lvl?.location?.split('~').pop()}` : ''})`)
                  .join(' | ');

                return (
                  <li>
                    {label}&nbsp;
                    <span style={{ color: '#888', fontStyle: 'italic' }}>{level}</span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      })}
    </div>
  );
};

const renderNotificationSubscription = (values: NotificationSubscription[], t: TranslateFunction) => {
  if (!values || values.length === 0) return <div />;

  const listStyle: CSSProperties = {
    marginLeft: 16,
    listStyleType: 'none',
  };
  return (
    <div style={{ paddingLeft: 10 }}>
      {values.map((item) => {
        return (
          <Paper
            elevation={3}
            style={{
              margin: '5px',
              padding: '5px',
              display: 'grid',
              gridTemplateColumns: '60px 5px 1fr',
            }}
          >
            <div>{t('Event')}</div>
            <div>:</div>
            <div>{getOptionLabel('notification_subscription_events', item.event)}</div>
            <div>{t('Type')}</div>
            <div>:</div>
            <div>{renderNotificationType(item.event, item.type)}</div>
            <div>Methods</div>
            <div>:</div>
            <div>
              {item.method?.map((method) => {
                listStyle.color = method.indexOf('+') >= 0 ? VALIDATION_ADD_COLOR : VALIDATION_REM_COLOR;
                return <li style={listStyle}>{method}</li>;
              })}
            </div>
            {item.cc_emails && (
              <>
                <div>{t('Mails')}</div>
                <div>:</div>
                <div>
                  {item.cc_emails?.map((email) => {
                    listStyle.color = email.indexOf('+') >= 0 ? VALIDATION_ADD_COLOR : VALIDATION_REM_COLOR;
                    return <li style={listStyle}>{email}</li>;
                  })}
                </div>
              </>
            )}
          </Paper>
        );
      })}
    </div>
  );
};

// eslint-disable-next-line react/no-danger
const renderHTML = (value: string): React.ReactElement => <div dangerouslySetInnerHTML={{ __html: value }} />;

export const renderRowData = (data: any, module: any, t: TranslateFunction): React.ReactElement => {
  const updated_date = formatDate((data.datetime as string) || '');
  const rowData = { label: '', value: '' };
  switch (module) {
    case 'sop':
      rowData.label = t('Profile Name');
      rowData.value = data.after.profile_name;
      break;
    case 'account':
      rowData.label = t('Account Number');
      rowData.value = data.identifier.split('-')[1];
      break;
    case 'wheel_profile':
      rowData.label = t('Profile Name');
      rowData.value = data.after.detail?.name || data.after.detail?.id || '';
      break;
    case 'contact':
      rowData.label = t('Contact Name');
      rowData.value = data.identifier;
      break;
    case 'sop_notes':
    case 'location':
      rowData.label = t('Location');
      rowData.value = data.identifier;
      break;
    case 'billing':
      rowData.label = t('Profile name');
      rowData.value = data.identifier;
      break;
    case 'tire_profile':
      rowData.label = t('Profile name');
      rowData.value = data.after.main?.profile_name || '';
      break;
    default:
      rowData.label = t('Location');
      rowData.value = data.identifier.split('~')[0];
      break;
  }

  return (
    <div style={{ verticalAlign: 'top', height: '100%' }}>
      <Row>
        <Field label={rowData.label} value={rowData.value} />
      </Row>
      <Row>
        <Field label={t('Updated By')} value={data.author.split('~')[0]} />
      </Row>
      <Row>
        <Field label={t('Modify Date')} value={updated_date} />
      </Row>
    </div>
  );
};

export const renderValue = (
  key: string,
  value: string | boolean,
  data: any,
  field: string,
  countryCode: string,
  t: TranslateFunction,
): React.ReactElement | null => {
  if (value === null || value === undefined) return <></>;

  switch (key) {
    // Strings --------------------------------------------------- //
    case 'profile_name':
    case 'company':
    case 'ld_na_number':
    case 'wheel_rim_width':
    case 'wheel_color_finish':
    case 'wheel_wheel_diameter':
    case 'wheel_operating_company':
    case 'ext':
    case 'work_phone':
    case 'last_name':
    case 'first_name':
    case 'cell_phone':
    case 'email_address':
    case 'contact_level':
    case 'preferred_method':
    case 'job_title':
    case 'preferred_language':
    case 'fax':
    case 'num_of_wheels':
    case 'num_of_tires':
    case 'action_code':
    case 'address':
    case 'customer_name':
    case 'customer_type':
    case 'extrnl_cust_id':
    case 'customer_addr1':
    case 'customer_addr2':
    case 'customer_country':
    case 'customer_city':
    case 'customer_state':
    case 'customer_zip':
    case 'parent_company_number':
    case 'home_office_number':
    case 'bill_to_customer':
    case 'ship_to_customer':
    case 'phone_number':
    case 'numberOfFleetTrucks':
    case 'numberOfFleetTrailers':
    case 'primaryTransportActivity':
    case 'primaryVocation':
    case 'secondaryTransportActivity':
    case 'secondaryVocation':
    case 'method_of_delivery':
    case 'delivery_address':
    case 'delivery_city':
    case 'delivery_state':
    case 'delivery_zip':
    case 'cust_timezone':
    case 'bill_to_customer_name':
    case 'ship_to_customer_name':
    case 'home_office_name':
    case 'parent_company_name':
    case 'customer_dba_name':
    case 'customer_number':
    case 'urban_admin_preapproved_txs_limit_amount':
    case 'billing_ers_ship_to_hash_key':
    case 'billing_pri_phone_number':
    case 'billing_sec_phone_number':
    case 'billing_signed_order_receiver_other':
    case 'billing_Required Authorization for Billing Purposes':
    case 'billing_signed_order_receiver_contact':
    case 'billing_signed_order_receiver_location':
    case 'billing_maxium_num_of_wheels':
    case 'billing_maximum_num_of_tires':
    case 'billing_maximum_dollar_amount':
    case 'owner_level':
    case 'billing_profile_name':
    case 'billing_when_should_po_be_provided':
    case 'tire_profile_name':
    case 'tire_vehicle_type':
      return renderString(value.toString());

    // Booleans -------------------------------------------------- //
    case 'default':
    case 'set_all_locations':
    case 'is_deleted':
    case 'wheel_is_x_one':
    case 'visible_externally':
    case 'driver_signature_required':
    case 'recipient_signature_required':
    case 'is_urban':
    case 'is_commercial':
    case 'mechanical_services_enabled':
    case 'delivery_address_same_as_physical':
    case 'urban_admin_allow_create_vehicle':
    case 'urban_admin_preapproved_txs_no_limits':
    case 'urban_admin_allow_preapproved_txs':
    case 'allow_michelin_tires':
    case 'allow_uniroyal_tires':
    case 'allow_megamile_tires':
    case 'allow_bfgoodrich_tires':
    case 'allow_oliver_tires':
    case 'oncall_sds_dashboard':
    case 'billing_required_for_ers_event':
    case 'billing_driver_signature_required':
    case 'billing_default':
    case 'billing_participates_in_approve_orders':
    case 'billing_po_required_for_service':
    case 'billing_driver_name_required':
    case 'billing_set_all_locations':
    case 'assign_to_all_locations':
    case 'tire_single_call_indicator':
    case 'tire_assign_to_all_locations':
    case 'tire_default':
    case 'tire_driver_can_override':
    case 'tire_special_tools_required':
    case 'tire_pressure_regulation_device_installed':
    case 'tire_should_device_be_reinstalled':
    case 'tire_driver_has_special_tools':
    case 'tire_pressure_sensor_installed':
    case 'wheel_assign_to_all_locations':
      return renderBoolean(value as boolean, t);

    // HTMLs ----------------------------------------------------- //
    case 'inspection_requirements':
    case 'service_installation_notes':
    case 'pickup_and_delivery':
    case 'proof_of_delivery':
    case 'scrap_only':
    case 'scrap_pending':
    case 'adjustment_notes':
    case 'wheel_instructions':
    case 'additional_instructions':
    case 'wheel_comments':
    case 'pickup_delivery_instructions':
    case 'tire_special_instructions':
      return renderHTML(value as string);

    // Customs --------------------------------------------------- //
    case 'inflation':
      return renderInflation((value as any).inflations as SOPInflation[], data, field, t);
    case 'wheel_balance':
      return renderWheelBalance((value as any).wheel_balances as SOPWheelBalance[], data, field, t);
    case 'pull_point':
      return renderPullPoints((value as any).pull_points as SOPPullPoint[], data, field, t);
    case 'brands':
      return renderBrandPreferences((value as any).items as SOPBrandPreferences[], data, field, t);
    case 'wheel_brands':
      return renderWheelBrands(value as any as WheelDetailsBrand[], t);
    case 'work_hours':
    case 'hoursOfOperations':
    case 'operationHours':
    case 'orderingHours':
      return renderHoursOfOperations(value as string, countryCode, t);
    case 'contact_types':
      return renderContactTypes(value as unknown as ContactType[], t, data, field);
    case 'notification_subscription':
      return renderNotificationSubscription(value as unknown as NotificationSubscription[], t);
    case 'tire_dollar_amount':
      return renderString(`$ ${value.toString()}`);
    case 'detail':
      return renderValues(value as string, data, field, countryCode, t, 'wheel');
    case 'billing_child_last_update_date':
      return renderDate(value as string, t);
    case 'tire_details':
      return renderTireDetails(value as unknown as TireDetails[], t);
    case 'ers_purchasing_procedures':
    case 'onsite_purchasing_procedures':
    case 'approvals':
    case 'billing_onsite_pre_approvals_limits':
    case 'billing_ers_pre_approvals_limits':
    case 'general_information':
      return renderValues(value as string, data, field, countryCode, t, 'billing');
    case 'requested_photos':
      return renderRequestPhotos(value as unknown as PhotoRequested, t);
    case 'wheel_details':
      return renderWheelDetails(value as unknown as TireDetails[], t);
    case 'main':
    case 'axles':
      return renderValues(value as string, data, field, countryCode, t, 'tire');
    case 'tire_vehicle_type_vmrs_code':
      return renderString(renderVehicleTypeVMRSCode(value as string, data));
    case 'tire_steer':
      return renderValues(value as string, data, field, countryCode, t, 'tire_steer');
    case 'tire_drive':
      return renderValues(value as string, data, field, countryCode, t, 'tire_drive');
    case 'tire_trailer':
      return renderValues(value as string, data, field, countryCode, t, 'tire_trailer');
    case 'tire_carry':
      return renderValues(value as string, data, field, countryCode, t, 'tire_carry');
    case 'tire_lift':
      return renderValues(value as string, data, field, countryCode, t, 'tire_lift');
    case 'tire_pusher':
      return renderValues(value as string, data, field, countryCode, t, 'tire_pusher');
    case 'tire_tag':
      return renderValues(value as string, data, field, countryCode, t, 'tire_tag');
    case 'tire_all_axles':
      return renderValues(value as string, data, field, countryCode, t, 'tire_all_axles');
    case 'tire_steer_tire_pressure_regulator':
    case 'tire_drive_tire_pressure_regulator':
    case 'tire_trailer_tire_pressure_regulator':
    case 'tire_carry_tire_pressure_regulator':
    case 'tire_lift_tire_pressure_regulator':
    case 'tire_pusher_tire_pressure_regulator':
    case 'tire_tag_tire_pressure_regulator':
    case 'tire_all_axles_tire_pressure_regulator':
    case 'tire_steer_tire_service_specifications':
    case 'tire_drive_tire_service_specifications':
    case 'tire_trailer_tire_service_specifications':
    case 'tire_carry_tire_service_specifications':
    case 'tire_lift_tire_service_specifications':
    case 'tire_pusher_tire_service_specifications':
    case 'tire_tag_tire_service_specifications':
    case 'tire_all_axles_tire_service_specifications':
      return renderValues(value as string, data, field, countryCode, t, 'tire');
    case 'tire_steer_pressure_sensor':
    case 'tire_drive_pressure_sensor':
    case 'tire_trailer_pressure_sensor':
    case 'tire_carry_pressure_sensor':
    case 'tire_lift_pressure_sensor':
    case 'tire_pusher_pressure_sensor':
    case 'tire_tag_pressure_sensor':
    case 'tire_all_axles_pressure_sensor':
      return renderValues(value as string, data, field, countryCode, t, 'tire_pressure_sensor');
    case 'tire_steer_tire_replacement_priority':
    case 'tire_drive_tire_replacement_priority':
    case 'tire_trailer_tire_replacement_priority':
    case 'tire_carry_tire_replacement_priority':
    case 'tire_lift_tire_replacement_priority':
    case 'tire_pusher_tire_replacement_priority':
    case 'tire_tag_tire_replacement_priority':
      return renderTireReplacementPriority(
        value as unknown as TireProfilePriorities[],
        data,
        field,
        countryCode as unknown as 'us' | 'ca',
        t,
        key.split('_')[1],
      );
    case 'tire_all_axles_tire_replacement_priority':
      return renderTireReplacementPriority(
        value as unknown as TireProfilePriorities[],
        data,
        field,
        countryCode as unknown as 'us' | 'ca',
        t,
        'all_axles',
      );
    case 'tire_all_axles_passenger_lt_priority':
      return renderPassengerLtPriority(
        value as unknown as PassengerLtPriority[],
        data,
        field,
        countryCode as unknown as 'us' | 'ca',
        t,
      );

    // Render Arrays of strings --------------------------------------------------- //
    case 'billing_pri_tire_po_issuers':
    case 'billing_sec_tire_po_issuers':
      return renderArrayValues(value as unknown as string[]);
    case 'billing_pri_ers_authorized_contact_type':
    case 'billing_sec_ers_authorized_contact_type':
      return renderArrayOptionsValues(value as unknown as string[], 'contact_types');
    case 'assigned_locations':
      return renderAssignedLocations(value as unknown as string[], t);
    case 'tire_pressure_sensor_types':
      return renderArrayOptionsValues(value as unknown as string[], 'tire_sensor_type');
    case 'tire_steer_activity_if_pref_unavailable':
    case 'tire_drive_activity_if_pref_unavailable':
    case 'tire_trailer_activity_if_pref_unavailable':
    case 'tire_carry_activity_if_pref_unavailable':
    case 'tire_lift_activity_if_pref_unavailable':
    case 'tire_pusher_activity_if_pref_unavailable':
    case 'tire_tag_activity_if_pref_unavailable':
    case 'tire_all_axles_activity_if_pref_unavailable':
      return renderArrayOptionsValues(value as unknown as string[], 'activity_if_not_set_preferences_types');

    // Options --------------------------------------------------- //
    case 'service_type':
      return renderString(getOptionLabel('core_profile_sop_service_types', value as string));
    case 'account_type':
      return renderString(getOptionLabel('ld_na_account_types', value as string));
    case 'wheel_profile_type':
      return renderString(getOptionLabel('profile_types', value as string));
    case 'wheel_wheel_type':
      return renderString(getOptionLabel('wheel_types', value as string));
    case 'wheel_axle_position':
      return renderString(getOptionLabel('axle_types', value as string));
    case 'wheel_wheel_condition':
      return renderString(getOptionLabel('wheel_conditions', value as string));
    case 'wheel_wheel_material':
      return renderString(getOptionLabel('wheel_materials', value as string));
    case 'wheel_wheel_position':
      return renderString(getOptionLabel('wheel_positions', value as string));
    case 'billing_pri_ers_authorized_contact_level':
    case 'billing_sec_ers_authorized_contact_level':
      return renderString(getOptionLabel('account_types', value as string));
    case 'billing_primary_method_to_request_service':
    case 'billing_secondary_method_to_request_service':
      return renderString(getOptionLabel('method_to_req_service', value as string));
    case 'billing_method_to_receive_signed_order':
      return renderString(getOptionLabel('method_to_receive_signed_order', value as string));
    case 'billing_service_type':
      return renderString(getOptionLabel('billing_service_types', value as string));
    case 'billing_profile_type':
    case 'tire_profile_type':
      return renderString(getOptionLabel('profile_types', value as string));
    case 'tire_application_type':
      return renderString(getOptionLabel('application_types', value as string));
    case 'tire_off_tire_disposition':
      return renderString(getOptionLabel('off_tire_disposition', value as string));
    case 'tire_vehicle_attrs_tractor':
      return renderString(getOptionLabel('vehicle_attrs_tractor', value as string));
    case 'tire_vehicle_attrs_trailer':
      return renderString(getOptionLabel('vehicle_attrs_trailer', value as string));
    case 'tire_off_tire_disposition_reason':
      return renderString(getOptionLabel('off_tire_disposition_reasons_kwd', value as string));
    case 'tire_pressure_sensor_disposition':
      return renderString(getOptionLabel('tire_sensor_disposition', value as string));
    case 'tire_pressure_sensor_location':
      return renderString(getOptionLabel('tire_sensor_location', value as string));
    case 'tire_pressure_sensor_mounting':
      return renderString(getOptionLabel('tire_sensor_mounting_type', value as string));
    case 'tire_first_choice':
    case 'tire_second_choice':
    case 'tire_third_choice':
      return renderString(getOptionLabel('damage_tire_course_of_action', value as string));

    // Default --------------------------------------------------- //
    default:
      return <span>{JSON.stringify(value)}</span>;
  }
};

export const renderValues = (
  value: string | boolean,
  data: any,
  field: string,
  countryCode: string,
  t: TranslateFunction,
  entity?: string,
) => {
  const items: { label: string; value: string | JSX.Element | null }[] = [];

  if (!value) return <span>&nbsp;</span>;

  Object.entries(value).forEach(([key, val]) => {
    if (!val && typeof value === 'boolean') return;
    if (ignoreFields.includes(key)) return;

    const entityKey = entity ? `${entity}_${key}` : key;

    items.push({
      label: t(getFieldLabel(key)),
      value: renderValue(entityKey, val as string, data, field, countryCode, t),
    });
  });

  /*
    if (isDeleted === true) {
          items.push({
            label: (mainFieldNames as any).is_deleted || 'is_deleted',
            value: renderValue('is_deleted', (value as any).is_deleted, data, field, t),
          });
    } else {
    }
  */
  return (
    <>
      {items.map((item, i) => {
        if (!item.value) return null;
        return (
          <div style={{ paddingLeft: 15, paddingBottom: 2 }} key={`render-item-${i}`}>
            <b>{t(item.label)}</b>: &nbsp;
            {item.value}
          </div>
        );
      })}
    </>
  );
};
