import React from 'react';
import { Grid } from '@material-ui/core';
import { LoadingBounce, Panel, Select } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { Controller, useFormContext } from 'react-hook-form';
import { ContactToRequireAuth } from '../Components/ContactToRequireAuth';
import { DrWorkOrder } from '../Components/DrWorkOrder';
import { AuthorizationService, BillingProfileContextData } from '../types';

export interface OnSitePurchasingProceduresProps {
  action: string;
}

export function OnSitePurchasingProcedures(props: OnSitePurchasingProceduresProps) {
  const { t } = useTranslation();
  const options_required_authorization_to_begin_service = getOptions('required_authorization_to_begin_service_onsite');
  const authorized_tire_po_issuer = { label: t('Tire PO Issuer'), value: 'po_issuer' };
  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';

  const methods = useFormContext<BillingProfileContextData>();
  const { getValues, setValue, watch } = methods;
  const { profile } = getValues();

  const watchIsBillingRequirementsChecksLoading = watch('isBillingRequirementsChecksLoading');

  const title = t('On-Site Purchasing Procedures');
  const poRequired: boolean = profile?.general_information?.po_required_for_service ?? false;

  const updateReqAuth = (newValue: string) => {
    if (newValue === 'po') {
      setValue(
        'profile.onsite_purchasing_procedures.primary_method_to_request_service',
        AuthorizationService.fleet_contact,
        { shouldDirty: true },
      );
      if (profile.onsite_purchasing_procedures?.pri_ers_authorized_contact_type) {
        if (
          !profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type.includes(
            authorized_tire_po_issuer.value,
          )
        ) {
          const aux: string[] = [...profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type];
          aux.push(authorized_tire_po_issuer.value);
          setValue('profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type', aux, { shouldDirty: true });
        }
      } else {
        setValue(
          'profile.onsite_purchasing_procedures.pri_ers_authorized_contact_type',
          [authorized_tire_po_issuer.value],
          { shouldDirty: true },
        );
      }
    }
  };

  return (
    <Panel title={title} style={{ marginBottom: 15 }}>
      {watchIsBillingRequirementsChecksLoading ? (
        <LoadingBounce style={{ height: 300 }} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Controller
              name="profile.onsite_purchasing_procedures.required_authorization_to_begin_service"
              control={methods.control}
              rules={{
                required: { value: true, message: t('Required Authorization for Billing Purposes') },
                validate: {
                  value: (value) => value !== 'Select' || t('Required Authorization for Billing Purposes').toString(),
                },
              }}
              render={(item) => (
                <Select
                  id={item.field.name}
                  name="required_authorization_to_begin_service"
                  data-testid="requiredAuthToBeginService"
                  style={{ minWidth: '100px', width: '100%' }}
                  label={t('Required Authorization for Billing Purposes')}
                  value={item.field.value || ''}
                  required
                  readOnly={!editFlag || poRequired}
                  options={options_required_authorization_to_begin_service}
                  onChange={(e) => {
                    item.field.onChange(e.target.value);
                    updateReqAuth(e.target.value as string);
                  }}
                  inputProps={{
                    'aria-label': 'required_authorization_to_begin_service-select',
                  }}
                  error={
                    !!methods.formState.errors.profile?.onsite_purchasing_procedures
                      ?.required_authorization_to_begin_service
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ContactToRequireAuth
              editFlag={editFlag}
              ers={false}
              primaryContactRequired
              poRequired={poRequired}
              key="onsite_primary_method"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ContactToRequireAuth
              editFlag={editFlag}
              ers={false}
              primaryContactRequired={false}
              poRequired={poRequired}
              key="onsite_secondary_method"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <DrWorkOrder editFlag={editFlag} ers={false} />
          </Grid>
        </Grid>
      )}
    </Panel>
  );
}
