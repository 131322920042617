import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { ErrorOutline } from '@material-ui/icons';

interface WarningTooltipProps {
  title: JSX.Element;
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top';
}

const useTooltipStyles = makeStyles((theme: Theme) => ({
  tooltipCommon: {
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  tooltipLight: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  },
}));

export const TooltipWarning = ({ title, tooltipPlacement = 'right' }: WarningTooltipProps) => {
  const classes = useTooltipStyles();

  return (
    <Tooltip
      placement={tooltipPlacement}
      classes={{
        tooltip: `${classes.tooltipCommon} ${classes.tooltipLight}`,
      }}
      title={title}
    >
      <ErrorOutline fontSize="small" />
    </Tooltip>
  );
};
