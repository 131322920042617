import React from 'react';
import { Grid } from '@material-ui/core';
import { AccountAvatar, LoadingBounce, Panel, Select, Switch } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { Controller, useFormContext } from 'react-hook-form';
import { ContactToRequireAuth } from '../Components/ContactToRequireAuth';
import { DrWorkOrder } from '../Components/DrWorkOrder';
import { AuthorizationService, BillingProfileContextData } from '../types';

export interface ErsPurchasingProceduresProps {
  action: string;
}

export function ErsPurchasingProcedures(props: ErsPurchasingProceduresProps) {
  const { t } = useTranslation();
  const options_required_authorization_to_begin_service = getOptions('required_authorization_to_begin_service_ers');
  const authorized_tire_po_issuer = { label: t('Tire PO Issuer'), value: 'po_issuer' };

  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';

  const methods = useFormContext<BillingProfileContextData>();
  const { getValues, setValue } = methods;
  const { profile } = getValues();
  const watchIsBillingRequirementsChecksLoading = methods.watch('isBillingRequirementsChecksLoading');

  const title = t('ERS Purchasing Procedures');
  const poRequired: boolean = profile?.general_information?.po_required_for_service ?? false;

  const ersPurchasingProcedures = methods.watch('profile.ers_purchasing_procedures');

  const updateReqAuth = (newValue: string) => {
    if (newValue === 'po') {
      setValue(
        'profile.ers_purchasing_procedures.primary_method_to_request_service',
        AuthorizationService.fleet_contact,
        { shouldDirty: true },
      );
      if (profile.ers_purchasing_procedures?.pri_ers_authorized_contact_type) {
        if (
          !profile.ers_purchasing_procedures.pri_ers_authorized_contact_type.includes(authorized_tire_po_issuer.value)
        ) {
          const aux: string[] = [...profile.ers_purchasing_procedures.pri_ers_authorized_contact_type];
          aux.push(authorized_tire_po_issuer.value);
          setValue('profile.ers_purchasing_procedures.pri_ers_authorized_contact_type', aux, { shouldDirty: true });
        }
      } else {
        setValue(
          'profile.ers_purchasing_procedures.pri_ers_authorized_contact_type',
          [authorized_tire_po_issuer.value],
          { shouldDirty: true },
        );
      }
    }
  };

  return (
    <Panel title={title} style={{ marginBottom: 15 }}>
      {watchIsBillingRequirementsChecksLoading ? (
        <LoadingBounce style={{ height: 300 }} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <label style={{ width: '100%', fontSize: '10px' }}>
                  {t('ERS Ship To Number (for Billing Purposes)')}
                </label>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Controller
                    name="profile.ers_purchasing_procedures.ers_ship_to_hash_key"
                    control={methods.control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        {!ersPurchasingProcedures?.ers_st_location ? (
                          <AccountAvatar
                            accountType="ST"
                            name=""
                            accountDisplay="short"
                            addressDisplay="cityState"
                            address={{
                              city: '',
                              state: '',
                            }}
                            accountNumbers={{
                              parentCompany: '',
                              homeOffice: '',
                              billTo: '',
                              shipTo: '',
                            }}
                            terminalId=""
                          />
                        ) : (
                          <AccountAvatar
                            accountType="ST"
                            name={ersPurchasingProcedures.ers_st_location.customer_name || ''}
                            accountDisplay="short"
                            addressDisplay="cityState"
                            style={{ color: 'primary' }}
                            address={{
                              city: ersPurchasingProcedures.ers_st_location.customer_city,
                              state: ersPurchasingProcedures.ers_st_location.customer_state,
                            }}
                            accountNumbers={{
                              parentCompany: ersPurchasingProcedures.ers_st_location.parent_company_number,
                              homeOffice: ersPurchasingProcedures.ers_st_location.home_office_number,
                              billTo: ersPurchasingProcedures.ers_st_location.bill_to_customer,
                              shipTo: ersPurchasingProcedures.ers_st_location.ship_to_customer,
                            }}
                            terminalId=""
                          />
                        )}
                        <NewLocationAssignments
                          owner={profile.owner?.customer_number}
                          mode={editFlag ? 'edit' : 'view'}
                          locations={value ? [{ hash_key: `${value}` }] : []}
                          all={false}
                          onUpdate={(newAssignments) => {
                            if (!newAssignments || !newAssignments.length) {
                              onChange('');
                              methods.setValue('profile.ers_purchasing_procedures.ers_st_location', undefined, {
                                shouldDirty: true,
                              });
                              return;
                            }
                            const na = newAssignments[0];
                            const erStLocation = {
                              customer_name: na.customer_name || '',
                              customer_city: na.customer_city || '',
                              customer_state: na.customer_state || '',
                              parent_company_number: na.parent_company_number || '',
                              home_office_number: na.home_office_number || '',
                              bill_to_customer: na.bill_to_customer || '',
                              ship_to_customer: na.ship_to_customer || '',
                            };
                            methods.setValue('profile.ers_purchasing_procedures.ers_st_location', erStLocation, {
                              shouldDirty: true,
                            });

                            onChange(na.hash_key);
                          }}
                          plainView
                          disabled={!editFlag}
                          hideCount
                          singleSelection
                          style={{ margin: '0 0 0 auto' }}
                          error={!!methods.formState.errors.profile?.ers_purchasing_procedures?.ers_ship_to_hash_key}
                        />
                      </>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} lg={4} style={{ marginTop: '1rem' }}>
                <Controller
                  name="profile.ers_purchasing_procedures.required_for_ers_event"
                  control={methods.control}
                  render={({ field: { value, onChange } }) => (
                    <Switch
                      name="terminal/location"
                      checked={value || false}
                      disabled={!editFlag}
                      label={t('Terminal/Location ID Required for ERS Event?')}
                      labelPosition="left"
                      inputProps={{
                        'aria-label': 'ers-event-switch',
                      }}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="profile.ers_purchasing_procedures.required_authorization_to_begin_service"
              control={methods.control}
              rules={{
                required: { value: true, message: t('Required Authorization for Billing Purposes') },
                validate: {
                  value: (value) => value !== 'Select' || t('Required Authorization for Billing Purposes').toString(),
                },
              }}
              render={(item) => (
                <Select
                  id={item.field.name}
                  name="required_authorization_to_begin_service"
                  data-testid="requiredAuthToBeginService"
                  style={{ minWidth: '100px', width: '100%' }}
                  label={t('Required Authorization for Billing Purposes')}
                  value={item.field.value || ''}
                  required
                  readOnly={!editFlag || poRequired}
                  options={options_required_authorization_to_begin_service}
                  onChange={(e) => {
                    item.field.onChange(e.target.value);
                    updateReqAuth(e.target.value as string);
                  }}
                  inputProps={{
                    'aria-label': 'required_authorization_to_begin_service-select',
                  }}
                  error={
                    !!methods.formState.errors.profile?.ers_purchasing_procedures
                      ?.required_authorization_to_begin_service
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ContactToRequireAuth
              editFlag={editFlag}
              ers={true}
              primaryContactRequired
              poRequired={poRequired}
              key="ers_primary_method"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ContactToRequireAuth
              editFlag={editFlag}
              ers={true}
              primaryContactRequired={false}
              poRequired={poRequired}
              key="ers_secondary_method"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <DrWorkOrder editFlag={editFlag} ers={true} />
          </Grid>
        </Grid>
      )}
    </Panel>
  );
}
