/* eslint-disable @typescript-eslint/no-use-before-define */

/* eslint-disable no-console */
import React, { FunctionComponent, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ArrowBack, Cancel, Edit, Save, Timelapse } from '@material-ui/icons';
import {
  AppBar,
  Container,
  Fab,
  GlobalActions,
  LoadingBounce,
  Panel,
  PreventTransitionPrompt,
  Tab,
  Tabs,
  useDialogs,
} from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import LastModified from 'components/ChangeLog/LastModified';
import { deepOmit } from 'components/Contact/utils';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { HoursOfOperation } from 'components/Types';
import { CONTENT_AREA_HEIGHT, scrollToDisplayFirstError } from 'components/Util';
import { useGetLocationPrograms, useUpdateLocationPrograms } from 'hooks/useLocations';
import { DealerProvider, LocationDetailsDataProvider, ProgramsDataProvider } from 'modules/Locations/types';
import { useSnackbar } from 'notistack';
import { getBaseUrl, getSelectedServiceOffer } from 'prefs-and-service-offers';
import { compose, graphql } from 'react-apollo';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { addPrefixToArray } from 'utils';
import { ProgramsDetails } from '../../modules/Locations/DetailTabs/Programs';
import { ServiceProviders } from '../../modules/Locations/DetailTabs/ServiceProviders/ServiceProviders';
import { PlainMessage } from '../Messages';
import { GeneralInfo } from './DetailTabs/GeneralInfo';
import { TireOrderingMap } from './DetailTabs/TireOrderingProcedures/Inventory';
import { TireOrderingProc } from './DetailTabs/TireOrderingProcedures/TireOrderingProc';
import {
  Customer,
  CustomerChildAssets,
  Data,
  DealerData,
  DetailsProps,
  SaveError,
  UpdateDealerLocationsInput,
  UpdateInput,
} from './LocationTypes';
import {
  getChildren,
  getDealers,
  getTireOrderingInformation,
  update,
  updateDealerLocations,
  updateTireOrdering,
} from './queries';

const _ = require('lodash');

interface TabPanelProps {
  children: React.ReactNode | undefined;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

export type TireOrderingInformation = {
  howOrders: string;
  emailLink: string;
  orderingHours: HoursOfOperation;
  orderingInstructions: string;
  levelMap: TireOrderingMap;
  onlineWebsite: string;
};

type InnerProps = {
  customer: Customer;
  customerChilds: CustomerChildAssets[];
  letItBe: Function;
  currentDealers: DealerProvider[];
  updateDealers: Function;
  tireOrderingInformation: TireOrderingInformation;
};
const setInitialValues = (cust: Customer) => {
  const ret: Customer = cust;
  ret.numberOfFleetTrucks = cust.numberOfFleetTrucks ? cust.numberOfFleetTrucks : '0';
  ret.numberOfFleetTrailers = cust.numberOfFleetTrailers ? cust.numberOfFleetTrailers : '0';
  ret.primaryTransportActivity = cust.primaryTransportActivity ? cust.primaryTransportActivity : 'None';
  ret.primaryVocation = cust.primaryVocation ? cust.primaryVocation : 'None';
  ret.secondaryTransportActivity = cust.secondaryTransportActivity ? cust.secondaryTransportActivity : 'None';
  ret.secondaryVocation = cust.secondaryVocation ? cust.secondaryVocation : 'None';

  ret.hoursOfOperations = ret.hoursOfOperations || {};
  ret.operationHours = ret.operationHours || {};

  ret.delivery_address = cust.delivery_address ? cust.delivery_address : '';
  ret.delivery_city = cust.delivery_city ? cust.delivery_city : '';
  ret.delivery_state = cust.delivery_state ? cust.delivery_state : '';
  ret.delivery_zip = cust.delivery_zip ? cust.delivery_zip : '';

  ret.is_urban = cust.is_urban ?? '';
  ret.urban_admin_allow_create_vehicle = cust.urban_admin_allow_create_vehicle ?? '';
  ret.urban_admin_allow_preapproved_txs = cust.urban_admin_allow_preapproved_txs ?? '';
  ret.urban_admin_preapproved_txs_limit_amount = cust.urban_admin_preapproved_txs_limit_amount ?? '';
  ret.urban_admin_preapproved_txs_no_limits = cust.urban_admin_preapproved_txs_no_limits ?? false;

  return ret;
};
export interface LocationDetailParams {
  action: string;
  locationId: string;
  selectedAccount: string;
  tab: tab;
}
type tab = 'general-info' | 'programs' | 'service-providers' | 'tire-ordering';

const tabsToNumber = (tab: string) => {
  switch (tab) {
    case 'general-info':
      return 0;
    case 'tire-ordering':
      return 1;
    case 'programs':
      return 2;
    case 'service-providers':
      return 3;
    default:
      return 0;
  }
};
const tabsToString = (tab: number) => {
  switch (tab) {
    case 0:
      return 'general-info';
    case 1:
      return 'tire-ordering';
    case 2:
      return 'programs';
    case 3:
      return 'service-providers';
    default:
      return 'general-info';
  }
};

const NavTabs: FunctionComponent<InnerProps> = (props) => {
  const { updateDealers } = props;
  const params = useParams<LocationDetailParams>();
  const history = useHistory();
  const match = useRouteMatch<{ locationId: string }>();
  const [tabNumber, setTabNumber] = React.useState(tabsToNumber(params.tab));
  const isEditAction = params.action === 'edit';
  const [isEdit, setIsEdit] = useState(isEditAction);
  const [saveError, setSaveError] = useState(new SaveError());
  const [updateStateCounter, setUpdateStateCounter] = React.useState(0);
  const forceUpdate = () => setUpdateStateCounter(updateStateCounter + 1);
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();
  const [changeUrlEdit, setChangeUrlEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const methods = useForm<LocationDetailsDataProvider>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      serviceProviders: props.currentDealers,
      programsData: {} as unknown as ProgramsDataProvider,
    } as LocationDetailsDataProvider,
  });
  const { onCallDRAll, onCallDRData, urbanAll, urbanData, commercialData, commercialAll, telematicsData, fmsData, marketingData, fleetAppEnrolled, fleetAppData } =
    methods.getValues().programsData;

  const [tireOrderingInformation, setTireOrderingInformation] = useState<TireOrderingInformation>(
    props.tireOrderingInformation,
  );
  const updateLocationPrograms = useUpdateLocationPrograms({
    fleetId: permissions.location?.customer_number,
  });

  const { errorDialog, confirmDialog } = useDialogs();
  const { t } = useTranslation();

  const updatePermission = permissions.allowsAction('locations.update');
  const readPermission = permissions.allowsAction('locations.read');

  const [customer, setCustomer] = useState(setInitialValues(props.customer));
  const [isBTorST] = useState<boolean>(
    customer.customer_type.toLowerCase() === 'bt' || customer.customer_type.toLowerCase() === 'st',
  );
  const [showServiceProviders, setShowServiceProviders] = useState<boolean>(false);
  const [initialSecretPhrase, setInitialSecretPhrase] = useState<string | null | undefined>();

  const programsDataResponse = useGetLocationPrograms(customer.hash_key.replace(/1~/, ''));

  useEffect(() => {
    const data = programsDataResponse.data;
    setInitialSecretPhrase(data?.fleetAppData?.secret_phrase);
    methods.reset({
      ...methods.getValues(),
      programsData: {
        ...data,
        commercialAll:
          data?.stLocations &&
          data?.stLocations?.length > 0 &&
          data?.commercialData?.stCommercialLocations?.length === data?.stLocations?.length,
        urbanAll:
          (data?.stLocations &&
            data?.stLocations?.length > 0 &&
            data?.urbanData?.stUrbanLocations?.length === data?.stLocations?.length) ||
          !data?.urbanData?.is_urban,
        onCallDRAll:
          data?.stLocations &&
          data?.stLocations?.length > 0 &&
          data?.onCallDRData?.stOnCallLocations?.length === data?.stLocations?.length,
        fleetAppEnrolled: !!data?.fleetAppData?.secret_phrase,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programsDataResponse.data]);

  useEffect(() => {
    const { urbanData, commercialData } = methods.getValues().programsData;
    if (urbanData?.is_urban || commercialData?.is_commercial) {
      if (showServiceProviders === false) {
        setShowServiceProviders(true);
      }
    } else {
      if (showServiceProviders === true) {
        setShowServiceProviders(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.getValues(), updateStateCounter]);

  useEffect(() => {
    if (showServiceProviders === false && params.tab === 'service-providers') {
      handleChange(0);
    }
    if (isBTorST === false && params.tab === 'programs') {
      handleChange(0);
    }
    if (params.tab !== 'general-info' && tabsToNumber(params.tab) === 0) {
      handleChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showServiceProviders, isBTorST]);

  if (!readPermission && !isEdit) {
    enqueueSnackbar(t('Your user does not have enough permissions to read the information about this location.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  }

  function handleChange(newValue: number) {
    setTabNumber(newValue);
    if (params.action === 'edit') {
      setChangeUrlEdit(true);
    } else {
      history.push(`../${tabsToString(newValue)}/view`);
    }
  }

  useEffect(() => {
    if (changeUrlEdit && params.action === 'edit') {
      history.push(`../${tabsToString(tabNumber)}/edit`);
      setChangeUrlEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeUrlEdit, tabNumber]);

  const reloadData = () => {
    history.push(
      `../../../${match.path.includes('locationDetails') ? 'locationDetail' : 'locationDetails'}/${
        match.params.locationId
      }/${params.tab}/view`,
    );
  };

  const detailSave = async () => {
    const localSelectedServiceOffer = getSelectedServiceOffer();
    let isBulkOrder = false;
    if (
      localSelectedServiceOffer.shortTitle === 'Fleet Order' ||
      /all\s+p/i.test(localSelectedServiceOffer.shortTitle)
    ) {
      isBulkOrder = true;
    }

    const errors: string[] = [];
    let errorMessage = '';

    if (customer.primaryTransportActivity !== 'None' && customer.primaryVocation === 'None') {
      errors.push('primaryTransportActivity');
      errors.push('primaryVocation');
      errorMessage = t("Primary Vocation can't be empty");
    }
    if (customer.secondaryTransportActivity !== 'None') {
      if (customer.secondaryVocation === 'None') {
        errors.push('secondaryTransportActivity');
        errors.push('secondaryVocation');
        errorMessage = t("Secondary Vocation can't be empty");
      }
      if (customer.primaryTransportActivity === 'None') {
        errors.push('primaryTransportActivity');
        errors.push('primaryVocation');
        errorMessage = t('If you have a Secondary Vocation you need a Primary One too');
      }
    }
    if (
      urbanData?.urban_admin_allow_preapproved_txs &&
      !urbanData?.urban_admin_preapproved_txs_no_limits &&
      (!urbanData?.urban_admin_preapproved_txs_limit_amount ||
        Number(urbanData?.urban_admin_preapproved_txs_limit_amount) <= 0)
    ) {
      errors.push('preapprovedLimitAmount');
      errorMessage = t('If pre-approved transactions are allowed you have to set the Limit Amount');
    }

    if (tireOrderingInformation.howOrders) {
      if (/online/i.test(tireOrderingInformation.howOrders) && !tireOrderingInformation.onlineWebsite) {
        errors.push('onlineWebsite');
        errorMessage = t('If you have chosen Online ordering please select an "Online Website"');
      }
      if (/email/i.test(tireOrderingInformation.howOrders) && !tireOrderingInformation.emailLink) {
        errors.push('emailLink');
        errorMessage = t('If you have chosen Email ordering please fill out the "Link to Email Template"');
      }
      if (/email/i.test(tireOrderingInformation.howOrders) && tireOrderingInformation.emailLink) {
        try {
          // eslint-disable-next-line no-new
          new URL(tireOrderingInformation.emailLink);
        } catch (error) {
          errors.push('emailLink');
          errorMessage = t('Please enter a valid URL for "Link to Email Template"');
        }
      }
    }
    if (customer.customer_type.toLowerCase() === 'bt') {
      if (telematicsData?.telematics_is_used) {
        if (
          !telematicsData?.telematics_list_of_platforms ||
          telematicsData.telematics_list_of_platforms.length === 0 ||
          telematicsData.telematics_list_of_platforms.every((item) => item.trim() === '')
        ) {
          errors.push('telematicsPlatformsList');
          errorMessage = t('Please add at least one platform for telematics.');
        }
      }
      if (fmsData?.fms_is_used) {
        if (!fmsData?.fms_platform || fmsData.fms_platform.trim() === '') {
          errors.push('fmsPlatform');
          errorMessage = t('Please add the platform used for Fleet Management System.');
        }
      }
      if (errors.includes('telematicsPlatformsList') && errors.includes('fmsPlatform')) {
        errorMessage = t(
          'Please add at least one platform for telematics and the platform used for Fleet Management System.',
        );
      }
      if (fleetAppEnrolled && !fleetAppData?.secret_phrase) {
        errors.push('secretPhrase');
        errorMessage = t('The secret phrase is mandatory to enroll in Fleet App.');
      }
    }

    if (errors.length > 0) {
      const ret = { errors, errorMessage };
      setSaveError(ret);
      errorDialog(errorMessage, t('Save Location Details'), t('Ok'));
      scrollToDisplayFirstError();
      return { errors, errorMessage };
    }
    let superError = false;

    if (isBulkOrder && customer.method_of_delivery !== 'Pickup at Service Provider Location') {
      if (customer.delivery_address || customer.delivery_city || customer.delivery_state || customer.delivery_zip) {
        if (
          !(customer.delivery_address && customer.delivery_city && customer.delivery_state && customer.delivery_zip)
        ) {
          superError = true;
        }
      }
      let deliveryMessage = '';

      if (!customer.delivery_address) {
        errors.push('delivery_address');
        deliveryMessage += 'delivery address~';
      }

      if (!customer.delivery_city) {
        errors.push('delivery_city');
        deliveryMessage += 'delivery city~';
      }
      if (!customer.delivery_state) {
        errors.push('delivery_state');
        deliveryMessage += 'delivery state~';
      }
      if (!customer.delivery_zip) {
        errors.push('delivery_zip');
        deliveryMessage += 'delivery zip~';
      }
      if (
        !customer.hoursOfOperations ||
        Object.keys(customer.hoursOfOperations).length === 0 ||
        (!customer.hoursOfOperations.friday &&
          !customer.hoursOfOperations.saturday &&
          !customer.hoursOfOperations.sunday &&
          !customer.hoursOfOperations.monday &&
          !customer.hoursOfOperations.tuesday &&
          !customer.hoursOfOperations.wednesday &&
          !customer.hoursOfOperations.thursday)
      ) {
        errors.push('hoursOfOperations');
        deliveryMessage += 'hoursOfOperations~';
      }

      if (
        !(urbanData?.is_urban && !commercialData?.is_commercial) &&
        deliveryMessage &&
        /all\s+p/i.test(localSelectedServiceOffer.shortTitle) &&
        !superError
      ) {
        const ret = { errors, errorMessage };
        setSaveError(ret);
        confirmDialog(
          [
            t(
              'This location is missing delivery preferences which are needed when placing bulk orders through the Fleet Order service.',
            ),
            // eslint-disable-next-line @typescript-eslint/indent
            t('Do you wish to update the delivery preferences for this location?'),
          ],
          t('Missing Delivery Preferences'),
          t('Yes'),
          t('No'),
          () => {
            setTabNumber(1);
          },
          () => {
            SaveAfterCheck();
          },
        );
        return undefined;
      }
      if (!(urbanData?.is_urban && !commercialData?.is_commercial) && (deliveryMessage || superError)) {
        const ret = { errors, errorMessage };
        setSaveError(ret);
        errorDialog(
          t(
            'This location is missing delivery preferences which are needed when placing bulk orders through the Fleet Order service',
          ),
          t('Missing Required Fields'),
          t('Ok'),
        );
        scrollToDisplayFirstError();
        return undefined;
      }
    }
    SaveAfterCheck();
    return undefined;
  };

  const onSaveClick = () => {
    // If the secret phrase changes, the user should confirm before saving (except if fleetAppEnrolled && !secretPhrase, in that case the validation will fail so we don't show the confirmation popup)
    if (initialSecretPhrase !== fleetAppData?.secret_phrase && (!fleetAppEnrolled || (fleetAppEnrolled && fleetAppData?.secret_phrase))) {
      confirmDialog(
        t('The secret phrase has changed, are you sure you want to save the changes?'),
        t('Secret Phrase confirmation'),
        t('Save'),
        t('Cancel'),
        detailSave
      );
    } else {
      detailSave()
    }
	};

  const SaveAfterCheck = async () => {
    setSaving(true);
    setSaveError(new SaveError());
    const inputObject: any = {
      numberOfFleetTrucks: customer.numberOfFleetTrucks,
      numberOfFleetTrailers: customer.numberOfFleetTrailers,
      primaryTransportActivity: customer.primaryTransportActivity,
      primaryVocation: customer.primaryVocation,
      secondaryTransportActivity: customer.secondaryTransportActivity,
      secondaryVocation: customer.secondaryVocation,
      method_of_delivery: customer.method_of_delivery,
      delivery_address_same_as_physical: customer.delivery_address_same_as_physical,
      hoursOfOperations: deepOmit(customer.hoursOfOperations, ['__typename']),
      operationHours: deepOmit(customer.operationHours, ['__typename']),
      recipient_signature_required: customer.recipient_signature_required,
      driver_signature_required: customer.driver_signature_required,
      cust_timezone: customer.cust_timezone,
      pickup_delivery_instructions: customer.pickup_delivery_instructions,
      mechanical_services_enabled: customer.mechanical_services_enabled,
    };

    if (customer.delivery_address && customer.delivery_city && customer.delivery_state && customer.delivery_zip) {
      inputObject.delivery_address = customer.delivery_address;
      inputObject.delivery_city = customer.delivery_city;
      inputObject.delivery_state = customer.delivery_state;
      inputObject.delivery_zip = customer.delivery_zip;
    }

    const input = { hash_key: match.params.locationId, input: inputObject };
    const pArray: any = [];

    await pArray.push(props.letItBe({ variables: input }));
    const serviceProvidersData = methods.getValues().serviceProviders;
    const variables = {
      dealers: serviceProvidersData
        ? serviceProvidersData
            .filter((x) => !!x.flag && x.flag !== 'S')
            .map((x) => {
              const d = _.omit(x, ['__typename', 'tableData', 'tempIndex']);
              d.hash_key = customer.hash_key;
              return {
                hash_key: customer.hash_key,
                range_key: `d~1~${x.customer_number}`,
                dealer_name: x.customer_dba_name || x.customer_name,
                flag: /u/i.test(x.flag ? x.flag : 'U') ? 'A' : x.flag,
                is_urban: x.is_urban,
                is_commercial: x.is_commercial,
                is_scrap: x.is_scrap,
                is_fleet_ordering: x.is_fleet_ordering,
                is_mechanical_services: x.is_mechanical_services,
              };
            })
        : [],
      queryName: 'updateCustomerDealerRelationship',
    };
    pArray.push(updateDealers({ variables }));

    if (
      (customer.customer_type.toLowerCase() === 'bt' || customer.customer_type.toLowerCase() === 'st') &&
      Object.keys(methods.formState.dirtyFields.programsData ?? []).length > 0
    )
      await updateProgramsInfo();

    methods.reset({
      ...methods.getValues(),
      serviceProviders: serviceProvidersData
        ? _.cloneDeep(serviceProvidersData)
            .filter((d: DealerProvider) => d.flag !== 'D')
            .map((d: DealerProvider) => ({ ...d, flag: 'S' }))
        : [],
    });

    await Promise.all(pArray);
    if (auth !== null && auth.apolloClient !== null) {
      await auth.apolloClient.mutate({
        mutation: updateTireOrdering,
        variables: {
          queryName: 'genericModule',
          input: JSON.stringify({
            hash_key: match.params.locationId,
            module: 'tireOrdering',
            ...tireOrderingInformation,
            update_user: auth.getCustomerName(),
          }),
        },
      });
    }

    setIsEdit(false);
    enqueueSnackbar(t('Successfully saved location {{locationName}}', { locationName: customer.customer_name }), {
      variant: 'success',
    });
    setSaveError({
      errors: [],
      errorMessage: '',
    });
    setSaving(false);
    forceUpdate();
    return null;
  };

  const updateProgramsInfo = async () => {
    let data: any = {};
    if (customer.customer_type.toLowerCase() === 'bt') {
      let putUrbanData: {
        urban_admin_ship_to_numbers?: string[];
        urban_admin_assign_to_all?: boolean;
        urban_admin_allow_create_vehicle?: boolean;
        urban_admin_allow_preapproved_txs?: boolean;
        urban_admin_preapproved_txs_limit_amount?: string;
        urban_admin_preapproved_txs_no_limits?: boolean;
        is_urban?: boolean;
      } = {};
      if (!urbanData?.is_urban) {
        putUrbanData = {
          urban_admin_ship_to_numbers: [],
          is_urban: urbanData?.is_urban,
        };
      } else {
        putUrbanData = {
          urban_admin_assign_to_all: urbanAll,
          urban_admin_ship_to_numbers: !urbanAll ? addPrefixToArray('1~', urbanData?.stUrbanLocations || []) : [],
          urban_admin_allow_create_vehicle: urbanData.urban_admin_allow_create_vehicle,
          urban_admin_allow_preapproved_txs: urbanData.urban_admin_allow_preapproved_txs,
          urban_admin_preapproved_txs_limit_amount: urbanData.urban_admin_allow_preapproved_txs
            ? urbanData.urban_admin_preapproved_txs_limit_amount
            : undefined,
          urban_admin_preapproved_txs_no_limits: urbanData.urban_admin_allow_preapproved_txs
            ? urbanData.urban_admin_preapproved_txs_no_limits
            : false,
          is_urban: urbanData.is_urban,
        };
      }
      let putOnCallDRData: {
        oncall_dr_dashboard_assign_to_all?: boolean;
        oncall_dr_dashboard_ship_to_numbers?: string[];
        oncall_sds_dashboard?: boolean;
      } = {
        oncall_dr_dashboard_assign_to_all: onCallDRAll,
        oncall_dr_dashboard_ship_to_numbers: onCallDRAll
          ? []
          : addPrefixToArray('1~', onCallDRData?.stOnCallLocations || []),
        oncall_sds_dashboard: onCallDRData?.oncall_dr_dashboard,
      };
      let putCommercialData: {
        commercial_assign_to_all?: boolean;
        commercial_ship_to_numbers?: string[];
        is_commercial?: boolean;
      } = {
        commercial_assign_to_all: commercialAll,
        commercial_ship_to_numbers: commercialAll
          ? []
          : addPrefixToArray('1~', commercialData?.stCommercialLocations || []),
        is_commercial: commercialData?.is_commercial,
      };
      const putTelematicsFmsData = {
        telematics_is_used: !!telematicsData?.telematics_is_used,
        telematics_list_of_platforms: telematicsData?.telematics_is_used ? (telematicsData?.telematics_list_of_platforms || []).filter((item) => item.trim() !== '') : [],
        fms_is_used: !!fmsData?.fms_is_used,
        fms_platform: fmsData?.fms_is_used ? fmsData?.fms_platform: '',
      };
      const putMarketingData = {
        no_marketing_surveys: !!marketingData?.no_marketing_surveys,
      }
      const putFleetAppData = {
        secret_phrase: fleetAppData?.secret_phrase,
      }
      data = { ...putUrbanData, ...putOnCallDRData, ...putCommercialData, ...putTelematicsFmsData, ...putMarketingData, ...putFleetAppData };
    } else if (customer.customer_type.toLowerCase() === 'st') {
      data = {
        urban_admin_allow_create_vehicle: urbanData?.urban_admin_allow_create_vehicle,
        urban_admin_allow_preapproved_txs: urbanData?.urban_admin_allow_preapproved_txs,
        urban_admin_preapproved_txs_limit_amount: urbanData?.urban_admin_allow_preapproved_txs
          ? urbanData.urban_admin_preapproved_txs_limit_amount
          : undefined,
        urban_admin_preapproved_txs_no_limits: urbanData?.urban_admin_allow_preapproved_txs
          ? urbanData?.urban_admin_preapproved_txs_no_limits
          : false,
        is_urban: urbanData?.is_urban,
        oncall_sds_dashboard: onCallDRData?.oncall_dr_dashboard,
        is_commercial: commercialData?.is_commercial,
      };
    }

    try {
      const response = await updateLocationPrograms.mutateAsync({
        fleetId: customer.hash_key.replace(/1~/, ''),
        data: data,
      });
      if (response) {
        enqueueSnackbar(t('Successfully saved programs information.'), {
          variant: 'success',
        });
      }
    } catch (error) {
      const errorMessage = t( (error as any)?.message.toString() || 'Error saving programs.');
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  };

  const handleFabBackClick = () => {
    history.push(`${getBaseUrl()}/locations`);
  };

  useEffect(() => {
    if (!isEdit && params.action === 'edit') {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const RenderActionButtons = () => (
    <GlobalActions>
      <Fab
        color="primary"
        label={t('Save')}
        disabled={!isEdit || updateLocationPrograms.isLoading}
        onClick={onSaveClick}
      >
        <Save />
      </Fab>
      <Fab color="inherit" label={t('Saving')} disabled={!updateLocationPrograms.isLoading || !saving}>
        <Timelapse />
      </Fab>
      <Fab
        color={isEdit ? 'primary' : 'inherit'}
        disabled={!isEdit || updateLocationPrograms.isLoading}
        label={t('Cancel')}
        onClick={reloadData}
      >
        <Cancel />
      </Fab>
      <Fab
        color="primary"
        label={t('Edit')}
        onClick={() => {
          setIsEdit(true);
          forceUpdate();
          history.push(`../${params.tab}/edit`);
        }}
        disabled={isEdit || !updatePermission}
      >
        <Edit />
      </Fab>
      <Fab color="primary" label={t('Back')} disabled={isEdit} onClick={() => handleFabBackClick()}>
        <ArrowBack />
      </Fab>
    </GlobalActions>
  );

  return (
    <>
      <PageTitle
        title={t('Location Information')}
        toLocation={customer.hash_key.replace(/1~/, '')}
        rightItems={<LastModified entity="location" identifier={`${params.locationId.replace(/1~/, '')}`} />}
      />
      <Container>
        <Panel spacing={0}>
          <FormProvider {...methods}>
            <AppBar color="primary" position="static" style={{ height: 48 }}>
              <Tabs
                variant="scrollable"
                value={tabNumber}
                onChange={(event, value) => handleChange(value)}
                aria-label="nav tabs"
                scrollButtons="on"
              >
                <Tab style={{ minWidth: 260 }} label={t('General Info')} />
                <Tab style={{ minWidth: 260 }} label={t('Tire Ordering Procedures')} />
                {isBTorST && <Tab style={{ minWidth: 260 }} label={t('Programs')} />}
                {showServiceProviders && isBTorST && <Tab style={{ minWidth: 260 }} label={t('Service Providers')} />}
              </Tabs>
            </AppBar>
            <TabPanel value={tabNumber} index={0}>
              <GeneralInfo customer={customer} setCustomer={setCustomer} isEdit={isEdit} saveError={saveError} />
            </TabPanel>
            <TabPanel value={tabNumber} index={1}>
              <TireOrderingProc
                customer={customer}
                onChange={setCustomer}
                saveError={saveError}
                editFlag={isEdit}
                customerNumber={match.params.locationId}
                tireOrderingInfo={tireOrderingInformation}
                setTireOrderingInfo={setTireOrderingInformation}
              />
            </TabPanel>
            {isBTorST && (
              <TabPanel value={tabNumber} index={2}>
                <ProgramsDetails
                  isEdit={isEdit}
                  saveError={saveError}
                  loadingData={programsDataResponse.isLoading || programsDataResponse.isFetching || !customer}
                  forceUpdate={forceUpdate}
                  owner={customer.hash_key.split('~')[1] || ''}
                  customerLevel={customer.customer_type}
                  stCustomer={customer.ship_to_customer}
                  btCustomer={customer.bill_to_customer}
                  errorData={programsDataResponse.isError}
                  initialSecretPhrase={initialSecretPhrase}
                />
              </TabPanel>
            )}
            {isBTorST && (
              <TabPanel value={tabNumber} index={3}>
                <ServiceProviders
                  editFlag={isEdit}
                  isUrban={urbanData?.is_urban || false}
                  isCommercial={commercialData?.is_commercial || false}
                  state={customer.customer_state}
                  city={customer.customer_city}
                />
              </TabPanel>
            )}
            <RenderActionButtons />
          </FormProvider>
          <PreventTransitionPrompt when={isEdit && !changeUrlEdit} handleDiscard={() => setIsEdit(false)} />
        </Panel>
      </Container>
    </>
  );
};

const Wrapper: FunctionComponent<DetailsProps> = (props) => {
  const { t } = useTranslation();
  const [miscState, setMiscState] = useState<any>({});
  const [currentDealers, setCurrentDealers] = useState<DealerProvider[]>([]);

  const loading = (
    <>
      <PageTitle title={t('Location Information')} subtitle={t('loading...')} />
      <LoadingBounce style={{ height: CONTENT_AREA_HEIGHT }} />
    </>
  );

  if (!props.details.loading && !props.getDealerz.loading && !props.getTireOrderingInformation.loading) {
    const dealers: DealerProvider[] = props.getDealerz.getDealers;
    if (!miscState.loaded) {
      if (dealers) {
        setCurrentDealers(
          dealers.map((d) => {
            // eslint-disable-next-line no-param-reassign
            d.flag = 'S';
            return d;
          }),
        );
      }
      setMiscState({ loaded: true });
      return loading;
    }

    if (!props.details || !props.details.getCustomerAssets || !props.details.getCustomerAssets.customer.relationship) {
      return (
        <PlainMessage
          title={t('Missing Permissions')}
          messages={[t('User has no permissions to view this profile.')]}
        />
      );
    }
    const cust = props.details.getCustomerAssets.customer;
    const custChilds: CustomerChildAssets[] = props.details.getCustomerAssets.childAssets;

    if (cust) {
      if (
        !cust.delivery_address &&
        !cust.delivery_city &&
        !cust.delivery_state &&
        !cust.delivery_zip &&
        cust.method_of_delivery !== 'Pickup at Service Provider Location'
      ) {
        cust.delivery_address = cust.customer_addr1;
        cust.delivery_city = cust.customer_city;
        cust.delivery_state = cust.customer_state;
        cust.delivery_zip = cust.customer_zip;
      }
    }
    const tireInfo: any[] = JSON.parse(props.getTireOrderingInformation.getEntityPaginated.items);

    return (
      <NavTabs
        currentDealers={currentDealers}
        customer={cust}
        customerChilds={custChilds}
        letItBe={props.letItBe}
        updateDealers={props.updateDealerLocations}
        tireOrderingInformation={
          tireInfo && tireInfo.length > 0
            ? tireInfo[0]
            : {
                howOrders: '',
                onlineWebsite: '',
                emailLink: '',
                orderingHours: {},
                orderingInstructions: '',
                levelMap: {},
              }
        }
      />
    );
  }
  return loading;
};

const updateMePlease = graphql(update, {
  name: 'letItBe',
  options: (props: UpdateInput) => ({
    variables: {
      hash_key: props.hash_key,
      input: props.input,
    },
  }),
});

const UpdateDealers = graphql(updateDealerLocations, {
  name: 'updateDealerLocations',
  options: (props: UpdateDealerLocationsInput) => ({
    variables: {
      dealers: props.dealers,
      queryName: 'updateCustomerDealerRelationship',
    },
  }),
});

const withOne = graphql(getChildren, {
  name: 'details',
  options: (params: any) => ({
    variables: {
      hash_key: params.match.params.locationId,
    },
    fetchPolicy: 'no-cache',
    props: (data: Data) => ({
      loadingTwo: data.loading,
      customer: data.customer,
    }),
  }),
});

const dealers = graphql(getDealers, {
  name: 'getDealerz',
  options: (params: any) => ({
    variables: {
      hash_key: params.match.params.locationId,
      state: 'dummy',
      city: 'dummy',
      searchText: 'dummy',
      isSelected: true,
      queryName: 'getDealers',
    },
    fetchPolicy: 'no-cache',
    props: (data: DealerData) => ({
      getDealerzLoading: data.loading,
      getDealerzDealers: data.getDealers,
    }),
  }),
});

const TireOrderingProcedures = graphql(getTireOrderingInformation, {
  name: 'getTireOrderingInformation',
  options: (params: any) => ({
    variables: {
      hash_key: params.match.params.locationId,
      range_key: 'v0_tire_ordering_info',
    },
    fetchPolicy: 'no-cache',
    props: (data: any) => ({
      getTireOrderingInformationLoading: data.loading,
      getTireOrderingInformation: data.getEntityPaginated,
    }),
  }),
});

export const LocationDetails = compose(
  withOne,
  updateMePlease,
  dealers,
  UpdateDealers,
  TireOrderingProcedures,
)(Wrapper);
