import React from 'react';
import { Divider, Link } from '@material-ui/core';
import { useTranslation } from '@michelin/central-provider';
import { LocationOVM } from '@michelin/fcp-view-models';
import { getOptionLabel } from '@michelin/select-options-provider';
import { RenderNameAndAccountInfo } from 'modules/Contacts/Components/Components';
import { PRIMARY_LEVEL_VALUE } from 'modules/Contacts/utils';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { ContactsRoles, LocationContactRole } from '../../types';

export function AccountLocationRender({ location, clickable }: { location: LocationOVM; clickable?: boolean }) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleLocationDetailsClick = (pathname: string, label: string) => {
    history.push(`${getBaseUrl()}${pathname}`);
  };

  return (
    <>
      <span>
        {clickable ? (
          <Link
            onClick={() =>
              handleLocationDetailsClick(
                `/locationDetails/1~${location.customer_number}/general-info/view`,
                location.customer_name || '',
              )
            }
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
          >
            {location.customer_name}
          </Link>
        ) : (
          <b>{location.customer_name}</b>
        )}
        <br />
      </span>
      <span>
        {t('Michelin Id')}
        {': '}
        {location.customer_number ? location.customer_number.replace('null', ' ') : 'N/A'}
        <br />
      </span>
    </>
  );
}

export function nully(str?: string) {
  if (!str) return true;
  return str === 'null';
}

export function renderAddress(cf: LocationOVM): JSX.Element {
  return (
    <>
      {!nully(cf.customer_addr1) ? (
        <>
          {cf.customer_addr1}
          <br />
        </>
      ) : undefined}
      {!nully(cf.customer_addr2) ? (
        <>
          {cf.customer_addr2}
          <br />
        </>
      ) : undefined}
      {`${cf.customer_city}, ${cf.customer_state} ${cf.customer_zip}`}
    </>
  );
}

export function contactInfoRender(
  row: LocationContactRole,
  contactLevel: string,
  t: Function,
): JSX.Element {
  const { contacts } = row;
  return (
    <>
      {contacts.map((contact: ContactsRoles, index: number) => {
        const mappedContactTypes = contact.roles.map((role: string): JSX.Element | undefined => {
          if (role) {
            return (
              <div key={contactLevel + role} style={{ marginTop: '0.2rem' }}>
                <span style={{ fontWeight: 900 }}>
                  {contactLevel === PRIMARY_LEVEL_VALUE ? t('Primary') : t('Secondary')}
                </span>
                {` ${t('Tire')} `}
                {getOptionLabel('contact_types', role)}
              </div>
            );
          }
          return undefined;
        });

        return (
          <div key={contact.contact.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <RenderNameAndAccountInfo row={contact.contact} />
              <div style={{ marginTop: 16, marginLeft: 'auto' }}>{mappedContactTypes}</div>
            </div>
            {index < contacts.length - 1 ? (
              <div style={{ marginTop: '1rem' }}>
                <Divider variant="middle" />
              </div>
            ) : undefined}
          </div>
        );
      })}
    </>
  );
}
