import React, { useEffect, useState } from 'react';
import { Grid, Modal } from '@material-ui/core';
import { LoadingBounce, Panel, PreventTransitionPrompt, useDialogs } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { BillingProfileOVM, ContactListingOVM, LocationOVM } from '@michelin/fcp-view-models';
import { useQueryClient } from '@tanstack/react-query';
import { PlainMessage } from 'components/Messages';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import {
  useGetBillingContacts,
  useGetBillingLocations,
  useGetBillingProfile,
  useUpdateBillingProfile,
} from 'hooks/useBillingProfile';
import { useSnackbar } from 'notistack';
import { getBaseUrl } from 'prefs-and-service-offers';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { mapProfileToSave, mergeNewAssignedLocations, preApprovalContactsFiltering } from '../utils';
import { BillingFabs } from './Components/BillingFabs';
import { HeaderBillingProfileDetail } from './Components/HeaderBillingProfileDetail';
import { ProfileParams, ServiceParams } from './Components/SpeedDialActions';
import UniqueBillingProfileModal from './Components/UniqueBillingProfileModal';
import { ErsPurchasingProcedures } from './Panels/ErsPurchasingProcedures';
import { GeneralInformation } from './Panels/GeneralInformation';
import { OnSitePurchasingProcedures } from './Panels/OnSitePurchasingProcedures';
import { PreApprovalLimits } from './Panels/PreApprovalLimits';
import { RequestedPhotosDetail } from './Panels/RequestedPhotosDetail';
import { RequestedTiresDetail } from './Panels/RequestedTiresDetail';
import { RequestedWheelsDetail } from './Panels/RequestedWheelsDetail';
import { BillingProfileContextData, BillingProfileTypes, BillingServiceTypes, IPermissions } from './types';

type IProps = {
  action: string;
};

export interface ViewCreateParams {
  selectedAccount: string;
  profileId?: string;
  service?: ServiceParams;
  profile?: ProfileParams;
}

interface ErrorMetadata {
  availableLocations: string[];
  locationsCount: number;
  duplicatedCount: number;
}
interface duplicateProfiles extends ErrorMetadata {
  openModal: boolean;
}

const BillingProfileDetailPage = (props: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { location, allowsAction } = usePermissions();
  const params = useParams<ViewCreateParams>();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  if (props.action === 'create' && params?.profile && params?.service) {
    if (
      !Object.values(ServiceParams).includes(params?.service) ||
      !Object.values(ProfileParams).includes(params?.profile)
    ) {
      history.push(`${getBaseUrl()}/billings`);
    }
  }
  const isUrbanCustomer: boolean = !!(location && location.is_urban && !location.is_commercial);

  const billingProfileData = useGetBillingProfile({
    fleetId: params.selectedAccount || '',
    profileId: params?.profileId ?? '',
    key: 'BillingProfileDetails',
    action: props.action,
    service: params.service ?? '',
    profile: params.profile ?? '',
    location: location,
  });

  const getLoggedLocationAndChildren = useGetBillingLocations({
    fleetId: params.selectedAccount || '',
    ownerId: '',
    profileId: '',
    key: 'LoggedLocationAndChildren',
    byLevel: false,
    fleetLoggedData: true,
  });
  const getAuthorizeServiceLocations = useGetBillingLocations({
    fleetId: params.selectedAccount || '',
    ownerId: props.action === 'create' ? params.selectedAccount : '',
    profileId: props.action === 'create' ? '' : params.profileId,
    key: 'AuthorizeServiceLocations',
    byLevel: true,
    fleetLoggedData: false,
  });
  const getAuthorizeServiceContacts = useGetBillingContacts({
    fleetId: params.selectedAccount || '',
    ownerId: props.action === 'create' ? params.selectedAccount : '',
    profileId: props.action === 'create' ? '' : params.profileId,
    key: 'AuthorizeServiceContacts',
    byLevel: true,
    fleetLoggedData: false,
  });
  const updateBillingProfileMutation = useUpdateBillingProfile({
    fleetId: params.selectedAccount,
    profileId: params.profileId ? params.profileId : '',
  });
  const [savingFlag, setSavingFlag] = useState(false);
  const [duplicateProfiles, setDuplicateProfiles] = useState<duplicateProfiles>({
    availableLocations: [],
    locationsCount: 0,
    duplicatedCount: 0,
    openModal: false,
  });
  const { errorDialog } = useDialogs();
  const methods = useForm<BillingProfileContextData>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      profile: {} as BillingProfileOVM,
      ownerContacts: [],
      assignedLocationsRelationships: [],
      auxAssignedLocations: [],
      contactsWithLimitsEdited: [],
      preApprovalsContacts: [],
      loggedLocationAndChildren: [],
      authorizeServiceContacts: { PC: [], HO: [], BT: [], ST: [] },
      authorizeServiceLocations: { PC: [], HO: [], BT: [], ST: [] },
      isProfileLoading: true,
      isAuthorizeServiceContactsLoading: true,
      isAuthorizeServiceLocationsLoading: true,
      isLoggedLocationAndChildrenLoading: true,
      isBillingRequirementsChecksLoading: false,
    } as BillingProfileContextData,
  });

  const billingPermissions: IPermissions = {
    read: allowsAction('billing.read'),
    create: allowsAction('billing.create'),
    update: allowsAction('billing.update'),
    delete: allowsAction('billing.delete'),
    list: allowsAction('billing.list'),
  };

  // Control permissions
  if (billingPermissions && billingPermissions.read === false) {
    enqueueSnackbar(
      t('Your user does not have enough permissions to read the information about this billing profile.'),
      {
        variant: 'warning',
      },
    );
    history.push(`${getBaseUrl()}/billings`);
  } else if (billingPermissions && billingPermissions.update === false && props.action.toLowerCase() === 'edit') {
    enqueueSnackbar(
      t('Your user does not have enough permissions to edit the information about this billing profile.'),
      {
        variant: 'warning',
      },
    );
    history.push(`${getBaseUrl()}/billings`);
  } else if (billingPermissions && billingPermissions.create === false && props.action.toLowerCase() === 'create') {
    enqueueSnackbar(t('Your user does not have enough permissions to create a billing profile.'), {
      variant: 'warning',
    });
    history.push(`${getBaseUrl()}/billings`);
  }

  const isDirty = Object.keys(methods.formState.dirtyFields).length > 0;
  const { profile } = methods.getValues();
  const watchLoadingProfile = methods.watch('isProfileLoading');

  useEffect(() => {
    if (billingProfileData.data && !billingProfileData.isLoading && !billingProfileData.isFetching) {
      methods.reset({
        ...methods.getValues(),
        profile: { ...billingProfileData.data.profile },
        assignedLocationsRelationships: [...billingProfileData.data.assignedLocationsRelationships],
        ownerContacts: [...billingProfileData.data.ownerContacts],
        allowedActions: { ...billingProfileData.data.allowedActions },
        isProfileLoading: false,
        auxAssignedLocations: billingProfileData.data.assignedLocationsRelationships.map((x) => ({
          ...x,
          assigned: true,
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingProfileData.data, billingProfileData.isLoading, billingProfileData.isFetching]);

  useEffect(() => {
    if (
      getLoggedLocationAndChildren.data &&
      !getLoggedLocationAndChildren.isFetching &&
      !getLoggedLocationAndChildren.isLoading
    ) {
      methods.setValue('loggedLocationAndChildren', getLoggedLocationAndChildren.data.locations as LocationOVM[]);
      methods.setValue('isLoggedLocationAndChildrenLoading', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getLoggedLocationAndChildren.data,
    getLoggedLocationAndChildren.isFetching,
    getLoggedLocationAndChildren.isLoading,
  ]);
  useEffect(() => {
    if (
      getAuthorizeServiceLocations.data &&
      !getAuthorizeServiceLocations.isFetching &&
      !getAuthorizeServiceLocations.isLoading
    ) {
      methods.setValue(
        'authorizeServiceLocations',
        getAuthorizeServiceLocations.data.locations as Record<'PC' | 'HO' | 'BT' | 'ST', LocationOVM[]>,
      );
      methods.setValue('isAuthorizeServiceLocationsLoading', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getAuthorizeServiceLocations.data,
    getAuthorizeServiceLocations.isFetching,
    getAuthorizeServiceLocations.isLoading,
  ]);
  useEffect(() => {
    if (
      getAuthorizeServiceContacts.data &&
      !getAuthorizeServiceContacts.isFetching &&
      !getAuthorizeServiceContacts.isLoading
    ) {
      methods.setValue(
        'authorizeServiceContacts',
        getAuthorizeServiceContacts.data?.contacts as Record<'PC' | 'HO' | 'BT' | 'ST', ContactListingOVM[]>,
      );
      methods.setValue('isAuthorizeServiceContactsLoading', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthorizeServiceContacts.data, getAuthorizeServiceContacts.isFetching, getAuthorizeServiceContacts.isLoading]);
  const watchLoggedLocations = methods.watch('loggedLocationAndChildren');
  const watchOwnerContacts = methods.watch('ownerContacts');
  const watchProfile = methods.watch('profile');
  useEffect(() => {
    if (watchProfile && watchProfile.owner_key) {
      const preApprovalsContacts = preApprovalContactsFiltering({
        ownerContacts: watchOwnerContacts,
        profile: profile,
        assignedLocations: methods.getValues().assignedLocationsRelationships,
        location: location,
        loggedLocations: watchLoggedLocations,
      });
      methods.setValue('preApprovalsContacts', preApprovalsContacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLoggedLocations, watchOwnerContacts, watchProfile]);

  const discard = () => {
    if (billingProfileData.data) {
      const currentValues = methods.getValues();
      methods.reset({
        ...currentValues,
        profile: { ...billingProfileData.data.profile },
        assignedLocationsRelationships: [...billingProfileData.data.assignedLocationsRelationships],
        ownerContacts: [...billingProfileData.data.ownerContacts],
        allowedActions: { ...billingProfileData.data.allowedActions },
        isProfileLoading: false,
        contactsWithLimitsEdited: [],
      });
    }
  };

  const save = async (data: BillingProfileContextData) => {
    setSavingFlag(true);
    const updatedContacts = data.contactsWithLimitsEdited
      .filter((editedContact) => {
        const originalContact = data.ownerContacts.find((contact) => contact.hash_key === editedContact.hash_key);
        if (!originalContact) return false;
        return (
          editedContact.tire_dollar_amount !== originalContact.tire_dollar_amount ||
          editedContact.num_of_wheels !== originalContact.num_of_wheels ||
          editedContact.num_of_tires !== originalContact.num_of_tires
        );
      })
      .map((contact) => ({
        hash_key: contact.id ? `2~${contact.id}` : '',
        tire_dollar_amount: contact.tire_dollar_amount,
        num_of_wheels: contact.num_of_wheels,
        num_of_tires: contact.num_of_tires,
      }));

    const toSave = {
      profile: mapProfileToSave(data.profile),
      contactsWithLimitsEdited: updatedContacts,
      newAssignedLocations: mergeNewAssignedLocations(
        data.auxAssignedLocations,
        data.assignedLocationsRelationships.map((x) => ({ ...x, assigned: true })),
      ),
    };
    try {
      const response = await updateBillingProfileMutation.mutateAsync({
        fleetId: params.selectedAccount,
        profileId: params.profileId ? params.profileId : '',
        profileData: {
          profile: toSave.profile,
          contactsWithLimitsEdited: toSave.contactsWithLimitsEdited,
          locations: toSave.newAssignedLocations,
        },
        action: props.action.toLowerCase() === 'create' ? 'create' : 'edit',
      });
      if (response?.error) {
        if (response.error === 'location_conflict') {
          if (response.errors && response.errors.length > 0) {
            const metadata = response.errors[0].metadata as ErrorMetadata;
            setDuplicateProfiles({ ...metadata, openModal: true });
            setSavingFlag(false);
          }
        } else {
          errorDialog(t('Error has occured when saving..'), t('Error saving Billing Profile.'));
          setSavingFlag(false);
        }
      } else {
        enqueueSnackbar(t('Successfully saved profile'), {
          variant: 'success',
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        queryClient.invalidateQueries(['billing', 'BillingProfileDetails', params.selectedAccount, params.profileId]);
        queryClient.invalidateQueries(['billing', 'LoggedLocationAndChildren', params.selectedAccount, '']);
        queryClient.invalidateQueries([
          'billing',
          'AuthorizeServiceLocations',
          params.selectedAccount,
          params.profileId,
        ]);
        queryClient.invalidateQueries([
          'billing',
          'AuthorizeServiceContacts',
          params.selectedAccount,
          params.profileId,
        ]);
        methods.reset({
          ...methods.getValues(),
          contactsWithLimitsEdited: [],
          isAuthorizeServiceContactsLoading: true,
          isAuthorizeServiceLocationsLoading: true,
          isLoggedLocationAndChildrenLoading: true,
          isProfileLoading: true,
        });

        setSavingFlag(false);
        let profileId = params.profileId;
        if (props.action.toLowerCase() === 'create') {
          profileId = response?.data?.hash_key?.split('~')[1] || '';
        }
        history.push(`${getBaseUrl()}/billing/${profileId}/view`);
      }
      setSavingFlag(false);
    } catch (error) {
      try {
        let errorInfo: Error | null = null;
        let errorMessages: string[] | string = [];
        errorInfo = error as Error;
        const errorResponse = JSON.parse(errorInfo.message);
        if (typeof errorResponse === 'string') {
          errorMessages = errorResponse;
        } else if (Array.isArray(errorResponse)) {
          errorMessages = errorResponse.map((err: any) => (typeof err === 'string' ? err : err.message || ''));
        }
        if (errorMessages) {
          errorDialog(errorMessages, t('Error saving Billing Profile.'));
        } else {
          errorDialog(t('Error has occured when saving.'), t('Error saving Billing Profile.'));
        }
      } catch (parseError) {
        errorDialog(t('Error has occured when saving.'), t('Error saving Billing Profile.'));
      }
    }
    setSavingFlag(false);
  };
  const onError = (errors: any) => {
    const msg = [];
    if (errors.profile?.general_information) {
      Object.keys(errors.profile.general_information).forEach((err) => {
        msg.push(`• ${t('General Info')}: ${t(errors['profile']['general_information'][err]['message'])}`);
      });
    }
    if (errors.profile?.tire_details) {
      msg.push(`• ${t('Requested Details is required for Requested Off/Installed Tire Details')}`);
    }
    if (errors.profile?.wheel_details) {
      msg.push(`• ${t('Requested Details is required for Requested Off/Installed Wheel Details')}`);
    }
    if (errors.profile?.ers_purchasing_procedures) {
      Object.keys(errors.profile.ers_purchasing_procedures).forEach((err) => {
        msg.push(`• ERS: ${t(errors['profile']['ers_purchasing_procedures'][err]['message'])}`);
      });
    }
    if (errors.profile?.onsite_purchasing_procedures) {
      Object.keys(errors.profile.onsite_purchasing_procedures).forEach((err) => {
        msg.push(`• On-Site: ${t(errors['profile']['onsite_purchasing_procedures'][err]['message'])}`);
      });
    }
    if (msg.length) {
      errorDialog(msg, t('Missing required fields'), t('Ok'));
    } else {
      errorDialog('Please check the required fields', t('Missing required fields'), t('Ok'));
    }
  };

  if (
    billingProfileData.isLoading ||
    (!billingProfileData?.data && !billingProfileData?.isError) ||
    (watchLoadingProfile && !billingProfileData?.isError)
  )
    return (
      <>
        <HeaderBillingProfileDetail subtitle="Loading..." action={props.action} profileId={`${params?.profileId}`} />
        <LoadingBounce style={{ height: CONTENT_AREA_HEIGHT }} />
      </>
    );

  if (billingProfileData?.isError) {
    return (
      <Panel variant="none" style={{ border: 0 }}>
        <Grid container>
          <Grid item xs={12} spacing={2}>
            <PlainMessage
              title={t('Database Error')}
              messages={[
                t('Application could not load billing profile data.'),
                t('Please try again later or contact support if the error persists'),
              ]}
            />
          </Grid>
        </Grid>
      </Panel>
    );
  }
  if (billingProfileData.data) {
    return (
      <FormProvider {...methods}>
        <HeaderBillingProfileDetail
          subtitle={
            props.action === 'create' ? '' : methods.getValues().profile?.general_information?.profile_name || ''
          }
          action={props.action}
          profileId={`${params?.profileId}`}
        />
        <PreventTransitionPrompt when={isDirty} handleDiscard={discard} />
        <Panel spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GeneralInformation action={props.action} />
            </Grid>
            {methods.getValues().profile?.general_information?.profile_type !== BillingProfileTypes.onsite ? (
              <Grid item xs={12}>
                <ErsPurchasingProcedures action={props.action} />
              </Grid>
            ) : null}
            {methods.getValues().profile?.general_information?.profile_type !== BillingProfileTypes.ers ? (
              <Grid item xs={12}>
                <OnSitePurchasingProcedures action={props.action} />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <PreApprovalLimits action={props.action} />
            </Grid>
            {isUrbanCustomer === false && (
              <>
                {profile.general_information?.service_type !== BillingServiceTypes.MECHANICAL && (
                  <>
                    <Grid item xs={12}>
                      <RequestedTiresDetail action={props.action} />
                    </Grid>
                    <Grid item xs={12}>
                      <RequestedWheelsDetail action={props.action} />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <RequestedPhotosDetail action={props.action} />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 20 }} />
                </Grid>
              </>
            )}
          </Grid>

          <BillingFabs
            editFlag={props.action === 'edit' || props.action === 'create'}
            handleSaveClick={() => methods.handleSubmit(save, onError)()}
            modifiedFlag={isDirty}
            profileId={params?.profileId || ''}
            savingFlag={savingFlag}
            action={props.action}
            loadingData={watchLoadingProfile}
          />
          <UniqueBillingProfileModal
            open={duplicateProfiles.openModal}
            handleClose={() => {
              setDuplicateProfiles({ availableLocations: [], locationsCount: 0, duplicatedCount: 0, openModal: false });
              setSavingFlag(false);
            }}
            duplicatedLocations={duplicateProfiles.duplicatedCount}
            totalLocations={duplicateProfiles.locationsCount}
            availableLocations={duplicateProfiles.availableLocations}
            handleSave={() => {
              setDuplicateProfiles({ availableLocations: [], locationsCount: 0, duplicatedCount: 0, openModal: false });
              methods.handleSubmit(save, onError)();
            }}
          />
          <Modal open={savingFlag} disableBackdropClick disableEscapeKeyDown>
            <div />
          </Modal>
        </Panel>
      </FormProvider>
    );
  }
};

export { BillingProfileDetailPage };
