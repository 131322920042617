import React, { useState } from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { ContactOVM } from '@michelin/fcp-view-models';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import { ContactControlDelegator, ContactDetails } from 'components/Contact/ContactDetails';
import { Contact } from 'components/Contact/utils';
import { Column } from 'devextreme-react/data-grid';
import { TFunction } from 'i18next';
import { ContactsTable } from 'modules/Contacts/Tables/ContactsTable';
import { MethodOfSignedOrder } from '../types';

function assignRender(
  row: ContactOVM,
  assignedContact: ContactOVM | undefined,
  setContact: (contact: ContactOVM | undefined) => void,
  t: TFunction,
) {
  const rowHashKey = `2~${row.id}`;
  return (
    <Button
      color={assignedContact?.hash_key === rowHashKey ? 'danger' : 'default'}
      variant={'default'}
      onClick={() => {
        setContact(assignedContact?.hash_key === rowHashKey ? undefined : row);
      }}
      disabled={assignedContact?.hash_key === rowHashKey}
    >
      {assignedContact?.hash_key === rowHashKey ? t('Assigned') : t('Assign')}
    </Button>
  );
}

const useStyles = makeStyles(() => ({
  popup: { height: '100vh' },
}));

interface Props {
  editFlag: boolean;
  open: boolean;
  contactAssigned: ContactOVM | undefined;
  onCloseHandler: () => void;
  setContact: (contact: ContactOVM | undefined) => void;
  type: string;
}

export function ReceiverContact(props: Props) {
  const { open, contactAssigned, setContact, editFlag, type } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [createMode, setCreateMode] = useState(false);
  const dialogTitle = !createMode ? t('Select a Contact') : t('Create new Contact');
  const permissions = usePermissions();
  const createContactPermission = permissions.allowsAction('contacts.create');
  const onCloseHandler = () => {
    if (createMode) {
      setCreateMode(false);
    } else {
      props.onCloseHandler();
    }
  };

  const createContactHandler = () => {
    setCreateMode(true);
  };

  const onAfterSaveHandler = async (contact: Contact) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setCreateMode(false);
  };

  const onAfterBackHandler = () => {
    setCreateMode(false);
  };

  const additionalAssignColumns = React.useMemo(
    () => [
      <Column
        key="assign_column"
        allowFiltering={false}
        allowSorting={false}
        caption={t('Assign')}
        alignment="center"
        dataField="assign"
        cellRender={(row: any) => assignRender(row.data, contactAssigned, setContact, t)}
      />,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactAssigned],
  );

  const contactController: ContactControlDelegator = {};
  if (!open) return null;

  const dialogContent = (
    <ContactDetails
      action="create"
      hideHeader
      delegateControl={contactController}
      avoidFirstTimeAssign
      onAfterSaveHandler={onAfterSaveHandler}
      onAfterBackHandler={onAfterBackHandler}
      locations={[]}
      noRedirectionAfterSaving={true}
    />
  );

  let methodType: 'email_address' | 'fax' | '' = '';
  if (type === MethodOfSignedOrder.email) {
    methodType = 'email_address';
  } else if (type === MethodOfSignedOrder.fax) {
    methodType = 'fax';
  }
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      scroll="paper"
      open={open}
      classes={{ paper: classes.popup }}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ClosableDialogTitle onClose={onCloseHandler}>{dialogTitle}</ClosableDialogTitle>
      <ClosableDialogContent dividers style={{ padding: 0 }}>
        {createMode === false ? (
          <ContactsTable
            popupMode={true}
            hideActions={true}
            additionalColumns={additionalAssignColumns}
            showWarnings={false}
            childrenContacts={true}
            extraFieldFilter={methodType}
            locationTypeLevel={permissions.location?.customer_type || ''}
          />
        ) : (
          dialogContent
        )}
      </ClosableDialogContent>
      <DialogActions>
        {createMode ? (
          <Button
            color="success"
            style={{ margin: '8px' }}
            onClick={() => {
              if (contactController.save) contactController.save();
            }}
          >
            {t('Save')}
          </Button>
        ) : null}
        {editFlag && !createMode ? (
          <>
            <Button
              color="secondary"
              style={{ margin: '8px' }}
              onClick={createContactHandler}
              disabled={!createContactPermission}
            >
              {t('Create new Contact')}
            </Button>
          </>
        ) : null}
        <Button
          color="danger"
          style={{ margin: '8px' }}
          onClick={!createMode ? onCloseHandler : () => setCreateMode(false)}
        >
          {t(!createMode ? 'Close' : 'Discard')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
