import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Button, Panel, Select, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { useFormContext } from 'react-hook-form';
import { BillingProfileContextData, ContactsWithLimitsEdited } from '../types';

interface DialogInterface {
  openDialog: boolean;
  closeDialog: () => void;
  row: ContactsWithLimitsEdited;
  rowIndex: number;
  disableEditTireWheel: boolean;
  disableEditAmount: boolean;
}

export function ActionsCell({
  row,
  rowIndex,
  disableEditTireWheel,
  disableEditAmount,
}: {
  row: ContactsWithLimitsEdited;
  rowIndex: number;
  disableEditTireWheel: boolean;
  disableEditAmount: boolean;
}) {
  const [openEditLimits, setOpenEditLimits] = useState(false);
  const { getValues, setValue } = useFormContext<BillingProfileContextData>();

  return (
    <div>
      {openEditLimits && (
        <DialogEditLimits
          openDialog={openEditLimits}
          closeDialog={() => {
            setOpenEditLimits(false);
          }}
          row={row}
          rowIndex={rowIndex}
          disableEditTireWheel={disableEditTireWheel}
          disableEditAmount={disableEditAmount}
        />
      )}
      <IconButton
        aria-label="edit"
        style={{ margin: '8px' }}
        onClick={() => {
          setOpenEditLimits(true);
        }}
        size="small"
      >
        <Edit fontSize="small" color="primary" />
      </IconButton>
      <IconButton
        aria-label="delete"
        style={{ margin: '8px' }}
        onClick={async () => {
          const auxContactsWithLimitsEdited = getValues().contactsWithLimitsEdited.filter(
            (contact) => contact.id !== row.id,
          );
          setValue(`contactsWithLimitsEdited`, auxContactsWithLimitsEdited, { shouldDirty: true });
        }}
        size="small"
      >
        <Delete fontSize="small" color={'primary'} />
      </IconButton>
    </div>
  );
}

export const DialogEditLimits = (props: DialogInterface) => {
  const { row, openDialog, closeDialog, rowIndex, disableEditAmount, disableEditTireWheel } = props;
  const { getValues, setValue } = useFormContext<BillingProfileContextData>();
  const { t } = useTranslation();
  const tireLimitOptions: Array<SelectOption> = getOptions('contacts_approval_limits_no_of_tires');
  const wheelLimitOptions: Array<SelectOption> = getOptions('contacts_approval_limits_no_of_wheels');
  const [tires, setTires] = useState<number | undefined>(row.num_of_tires || 0);
  const [wheels, setWheels] = useState<number | undefined>(row.num_of_wheels || 0);
  const [tireAmount, setTireAmount] = useState<number | undefined>(row.tire_dollar_amount || 0);
  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        closeDialog();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{`${t('Edit Approval Limits')} - ${row.first_name} ${
        row.last_name
      }`}</DialogTitle>
      <DialogContent dividers>
        <Panel variant="none" style={{ border: 0 }} spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                id={`contactsWithLimitsEdited.${rowIndex}.tires`}
                label={t('Number of Tires')}
                type="string"
                variant="standard"
                value={tires || 0}
                options={tireLimitOptions}
                onChange={(e) => setTires(parseInt(e.target.value as string, 10))}
                fullWidth
                required
                disabled={disableEditTireWheel}
                style={{ display: 'flex', flexWrap: 'wrap' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                id={`contactsWithLimitsEdited.${rowIndex}.wheels`}
                label={t('Number of Wheels')}
                type="string"
                variant="standard"
                value={wheels || 0}
                options={wheelLimitOptions}
                onChange={(e) => setWheels(parseInt(e.target.value as string, 10))}
                fullWidth
                required
                disabled={disableEditTireWheel}
                style={{ display: 'flex', flexWrap: 'wrap' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id={`contactsWithLimitsEdited.${rowIndex}.tireAmount`}
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                label={t('Max Dollars for Tire Service')}
                type="number"
                variant="standard"
                value={tireAmount}
                onChange={(e) => setTireAmount(parseInt(e.target.value, 10))}
                fullWidth
                required
                disabled={disableEditAmount}
                style={{ display: 'flex', flexWrap: 'wrap' }}
              />
            </Grid>
          </Grid>
        </Panel>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const rowUpdated = {
              ...row,
              num_of_tires: tires,
              num_of_wheels: wheels,
              tire_dollar_amount: Number.isNaN(tireAmount) ? 0 : tireAmount,
            };
            const auxContactsWithLimitsEdited = getValues().contactsWithLimitsEdited.map((contact) =>
              contact.id === rowUpdated.id ? rowUpdated : contact,
            );
            setValue(`contactsWithLimitsEdited`, auxContactsWithLimitsEdited, { shouldDirty: true });
            closeDialog();
          }}
          color="success"
        
        >
          {t('Update')}
        </Button>
        <Button
          onClick={() => {
            closeDialog();
          }}
          color="danger"
       
        >
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
