import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { IconButton, Select, Switch, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import Autocomplete from 'components/Autocomplete';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import uuid from 'uuid';
import { BillingProfileContextData, BillingTireOffInstalled, BillingTireRequestedDetails } from '../types';

interface TireItemProps {
  index: number;
  editFlag: boolean;
  bothAssigned: boolean;
  onRemove: (index: number) => void;
  key: string;
}

export function TireItem({ index, editFlag, bothAssigned, onRemove, key }: TireItemProps) {
  const { t } = useTranslation();
  const { setValue, control, watch } = useFormContext<BillingProfileContextData>();

  const tireDetail = watch(`profile.tire_details.${index}`);

  return (
    <React.Fragment>
      <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Controller
          name={`profile.tire_details.${index}.off_installed`}
          control={control}
          render={(item) => (
            <Switch
              checked={item.field.value === BillingTireOffInstalled.INSTALLED_TIRES}
              disabled={!editFlag || bothAssigned}
              label=""
              checkedLabel={t('Installed')}
              uncheckedLabel={t('Off')}
              labelPosition="top"
              inputProps={{
                'aria-label': 'installed-switch',
              }}
              onChange={(e) => {
                const newValue = e.target.checked
                  ? BillingTireOffInstalled.INSTALLED_TIRES
                  : BillingTireOffInstalled.OFF_TIRES;
                item.field.onChange(newValue);
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ width: '100%' }}>
          <Controller
            name={`profile.tire_details.${index}.requested_details`}
            control={control}
            rules={{
              required: { value: true, message: t('Requested Tire Details are required.') },
            }}
            render={(item) => (
              <Autocomplete
                id="requested-details-autocomplete"
                data-testid="requested-details-test"
                multiple
                variant="standard"
                label={t('Requested Details')}
                disableClearable
                disableCloseOnSelect
                placeholder=""
                onChange={(value) => {
                  if (!value.includes(BillingTireRequestedDetails.OTHER)) {
                    setValue(`profile.tire_details.${index}.other`, '');
                  }
                  if (!value.includes(BillingTireRequestedDetails.TIRE_CASING_TYPE)) {
                    setValue(`profile.tire_details.${index}.billing_tire_casing_age`, '');
                  }
                  item.field.onChange(value);
                }}
                suggestions={getOptions('tire_requested_details')}
                values={item.field.value}
                error={!!item.formState?.errors?.profile?.tire_details?.[index]?.requested_details}
                readOnly={!editFlag}
              />
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Controller
          name={`profile.tire_details.${index}.other`}
          control={control}
          render={(item) => (
            <TextField
              value={item.field.value}
              label={t('Other')}
              inputProps={{
                'aria-label': 'other-textfield',
              }}
              readOnly={!editFlag || !tireDetail.requested_details?.includes(BillingTireRequestedDetails.OTHER)}
              onChange={(e) => {
                item.field.onChange(e.target.value);
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        {/* {tireDetail.requested_details &&
          tireDetail.requested_details.includes(BillingTireRequestedDetails.TIRE_CASING_TYPE) && ( */}
        <Controller
          name={`profile.tire_details.${index}.billing_tire_casing_age`}
          control={control}
          render={(item) => (
            <Select
              id={item.field.name}
              name="billing_tire_casing_age"
              data-testid="casingOptionsSelect"
              style={{ minWidth: '100px', width: '100%' }}
              label={t('Casing Details')}
              value={item.field.value}
              readOnly={
                !editFlag || !tireDetail.requested_details?.includes(BillingTireRequestedDetails.TIRE_CASING_TYPE)
              }
              options={getOptions('tire_casing_age')}
              onChange={(e) => {
                item.field.onChange(e.target.value);
              }}
              inputProps={{
                'aria-label': 'casing-select',
              }}
            />
          )}
        />
        {/* )} */}
      </Grid>
      <Grid item xs={12} md={1} style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          color="primary"
          size="small"
          variant="default"
          disabled={!editFlag}
          onClick={() => onRemove(index)}
          aria-label="remove"
        >
          <Delete fontSize="small" />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
}

interface TireListProps {
  editFlag: boolean;
  bothAssigned: boolean;
  clickedAdd: boolean;
  setClickedAdd: (clickedAdd: boolean) => void;
}

export function TireList({ editFlag, bothAssigned, clickedAdd, setClickedAdd }: TireListProps) {
  const { t } = useTranslation();
  useEffect(() => {
    if (clickedAdd) add();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedAdd]);
  const { setValue, watch, control } = useFormContext<BillingProfileContextData>();
 const { remove } = useFieldArray({
    control,
    name: 'profile.tire_details',
  });
  const list = watch('profile.tire_details');

  const add = () => {
    setClickedAdd(false);
    if (!list || list.length === 0) {
      const tire = {
        id: uuid.v4(),
        requested_details: [],
        off_installed: BillingTireOffInstalled.OFF_TIRES,
        other: '',
      };
      setValue('profile.tire_details', [tire], { shouldDirty: true });
      return;
    }
    if (!list[list.length - 1].requested_details || list[list.length - 1]?.requested_details?.length === 0) return;
    if (list) {
      const lastTireDetails = list[list.length - 1];
      if (lastTireDetails && lastTireDetails.requested_details && lastTireDetails.requested_details.length > 0) {
        if (
          lastTireDetails.off_installed === BillingTireOffInstalled.OFF_TIRES ||
          lastTireDetails.off_installed === undefined
        ) {
          const tire = {
            id: uuid.v4(),
            requested_details: [],
            off_installed: BillingTireOffInstalled.INSTALLED_TIRES,
            other: '',
          };
          setValue('profile.tire_details', [...list, tire], { shouldDirty: true });
        } else {
          const tire = {
            id: uuid.v4(),
            requested_details: [],
            off_installed: BillingTireOffInstalled.OFF_TIRES,
            other: '',
          };
          setValue('profile.tire_details', [...list, tire], { shouldDirty: true });
        }
        return;
      }
    }
  };

  return (
    <>
      {!list || list.length === 0 ? (
        <Typography style={{ color: '#999999', width: '100%', textAlign: 'center' }}>{t('No data...')}</Typography>
      ) : (
        <>
          {list.map((row, index) => (
            <TireItem
              key={`tireRow_${row.off_installed}`}
              index={index}
              editFlag={editFlag}
              bothAssigned={bothAssigned}
              onRemove={remove}
            />
          ))}
        </>
      )}
    </>
  );
}
