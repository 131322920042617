import {
  BillingProfileGetOVM,
  ContactListingOVM,
  ContactOVM,
  CustomerOVM,
  LocationOVM,
} from '@michelin/fcp-view-models';
import { Contact } from 'components/Contact/utils';

export interface BillingPreferencesLocation {
  hash_key: string;
  customer_number?: number;
  customer_type?: string;
  customer_name?: string;
}

export interface BillingPreferencesLocationRelationship {
  location?: BillingPreferencesLocation;
}

// React Hook Form Interface
export interface BillingProfileContextData extends BillingProfileGetOVM {
  contactsWithLimitsEdited: ContactsWithLimitsEdited[];
  auxAssignedLocations: NewAssignedLocations[];
  preApprovalsContacts: ContactOVM[];
  authorizeServiceContacts: Record<'PC' | 'HO' | 'BT' | 'ST', ContactListingOVM[]>;
  authorizeServiceLocations: Record<'PC' | 'HO' | 'BT' | 'ST', LocationOVM[]>;
  loggedLocationAndChildren: LocationOVM[];
  isProfileLoading: boolean;
  isAuthorizeServiceContactsLoading: boolean;
  isAuthorizeServiceLocationsLoading: boolean;
  isLoggedLocationAndChildrenLoading: boolean;
  isBillingRequirementsChecksLoading: boolean;
}

export interface NewAssignedLocations extends CustomerOVM {
  assigned: boolean;
}
export interface ContactsWithLimitsEdited extends ContactOVM {
  addedForEdit?: true;
}

export interface LocationContactRole {
  location: LocationOVM;
  contacts: ContactsRoles[];
}

export interface ContactsRoles {
  contact: ContactListingOVM;
  roles: string[];
}
export enum BillingServiceTypes {
  TIRE_WHEEL = 'tire_wheel',
  MECHANICAL = 'mechanical',
}

export enum BillingProfileTypes {
  ers = 'ers',
  onsite = 'onsite',
  ers_onsite = 'ers_onsite',
}

export enum MethodOfSignedOrder {
  Select = 'Select',
  email = 'email',
  fax = 'fax',
  mail = 'mail',
  other = 'other',
  provide_to_driver = 'provide_to_driver',
}

export enum AuthorizationService {
  Select = 'Select',
  break_down_phone_number = 'break_down_phone_number',
  driver = 'driver',
  fleet_contact = 'fleet_contact',
}

export enum BillingWheelOffInstalled {
  OFF_WHEELS = 'off_wheels',
  INSTALLED_WHEELS = 'installed_wheels',
}

export enum BillingWheelRequestedDetails {
  TYPE = 'type',
  OFFSET = 'offset',
  SIZE = 'size',
  OTHER = 'other',
  MATERIAL = 'material',
}

export enum BillingTireOffInstalled {
  OFF_TIRES = 'off_tires',
  INSTALLED_TIRES = 'installed_tires',
}

export enum BillingTireRequestedDetails {
  TIRE_CASING_BRAND = 'tire_casing_brand',
  DOT = 'dot',
  SIZE = 'size',
  TIRE_CASING_TREAD_DESIGN = 'tire_casing_tread_design',
  TIRE_CASING_TYPE = 'tire_casing_type',
  REMAINING_TREAD_DEPTH = 'remaining_tread_depth',
  OTHER = 'other',
}

export enum BillingRequestedPhotosTypes {
  NONE = 'none',
  OFF_TIRE = 'off_tire',
  CLOSE_UP_TIRE_DAMAGE = 'close_up_tire_damage',
  WORK_ORDER = 'work_order',
  TIRE_IDENTIFIER = 'tire_identifier',
  OTHER = 'other',
}

export enum ContactsTypesAuth {
  PO_ISSUER = 'po_issuer',
  ERS_MANAGER = 'ers_manager',
}

export type BillingReassignContact = Pick<
  Contact,
  | 'hash_key'
  | 'first_name'
  | 'last_name'
  | 'email_address'
  | 'work_phone'
  | 'cell_phone'
  | 'preferred_method'
  | 'contact_types'
  | 'contact_level'
>;

export interface IPermissions {
  create: boolean;
  update: boolean;
  read: boolean;
  delete: boolean;
  list: boolean;
}

export interface checksPO {
  po_required_for_service: boolean;
  participates_in_approve_orders: boolean;
}
