import React, { useState } from 'react';
import { Grid, IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { InformationTooltip, LoadingBounce, Panel, Switch, TextField } from '@michelin/acid-components';
import { isFeatureEnabled, usePermissions, useTranslation } from '@michelin/central-provider';
import { PlainMessage } from 'components/Messages';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { TooltipWarning } from 'components/TooltipWarning/TooltipWarning';
import { TABBED_CONTENT_HEIGHT } from 'components/Util';
import { LocationDetailsDataProvider } from 'modules/Locations/types';
import { Controller, useFormContext } from 'react-hook-form';
import { SaveError } from '../../../components/Location/LocationTypes';
import { PlatformsList } from '../Components/PlatformsList';

interface ProgramsProps {
  loadingData: boolean;
  isEdit: boolean;
  saveError: SaveError;
  forceUpdate: () => void;
  owner: string;
  customerLevel: string;
  stCustomer: string;
  btCustomer: string;
  errorData: boolean;
  initialSecretPhrase: string | undefined | null;
}

const SECRET_PHRASE_ADJ = [
  'durable',
  'smooth',
  'fast',
  'premium',
  'efficient',
  'comfortable',
  'strong',
  'reliable',
  'lightweight',
  'iconic',
  'luxurious',
  'prestigious',
  'high-end',
  'dynamic',
  'safe',
  'silent',
  'resistant',
  'elite',
  'agile',
  'innovative',
];

const SECRET_PHRASE_NOUNS = [
  'tire',
  'wheel',
  'road',
  'rubber',
  'traction',
  'grip',
  'travel',
  'journey',
  'map',
  'guide',
  'star',
  'restaurant',
  'rating',
  'performance',
  'endurance',
  'speed',
  'vehicle',
  'race',
  'track',
  'innovation',
];

export interface IUrbanAssignmentChild {
  hash_key: string;
  urban_admin_allow_create_vehicle?: boolean;
  urban_admin_allow_preapproved_txs?: boolean;
  urban_admin_preapproved_txs_limit_amount?: string;
}

const useStyles = makeStyles((theme) => ({
  gridItemStyle: {
    [theme.breakpoints.down('sm')]: { display: 'flex', justifyContent: 'flex-end', textAlign: 'end' },
    [theme.breakpoints.up('sm')]: { display: 'flex', justifyContent: 'flex-end', textAlign: 'end' },
  },
  switchStyles: {
    [theme.breakpoints.down('sm')]: { marginRight: 0, textAlign: 'end' },
    [theme.breakpoints.up('sm')]: { textAlign: 'end' },
  },
  preApprovalContainer: {
    [theme.breakpoints.down('sm')]: { display: 'flex', justifyContent: 'end', marginRight: 8 },
    [theme.breakpoints.up('sm')]: { textAlign: 'center' },
  },
  tooltip: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: 400,
  },
  arrow: {
    color: theme.palette.grey[900],
  },
}));

const MarginTooltip = ({ title }: { title: JSX.Element }) => {
  return (
    <span style={{ marginLeft: 10 }}>
      <InformationTooltip tooltipVariant="light" title={title} />
    </span>
  );
};

export const ProgramsDetails = (props: ProgramsProps) => {
  const {
    isEdit,
    saveError,
    loadingData,
    forceUpdate,
    owner,
    customerLevel,
    stCustomer,
    btCustomer,
    errorData,
    initialSecretPhrase,
  } = props;

  const permissions = usePermissions();
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, getValues, setValue, watch } = useFormContext<LocationDetailsDataProvider>();
  const isBTLevel = customerLevel === 'BT';
  const urbanReadOnly = !isEdit || !isBTLevel || !permissions.allowsAction('locations.update_is_urban');
  const onCallDRDashboardReadOnly =
    !isEdit || !permissions.allowsAction('locations.update_access_to_oncall_dr_dashboard');
  const isCommercialReadOnly = !isEdit || !permissions.allowsAction('locations.update_is_commercial');
  const generalReadOnly = !isEdit || !isBTLevel || !permissions.allowsAction('locations.update');

  const {
    commercialAll,
    commercialData,
    urbanAll,
    urbanData,
    onCallDRAll,
    onCallDRData,
    stLocations,
    telematicsData,
    fmsData,
    marketingData,
    fleetAppEnrolled,
  } = getValues().programsData;
  const assignedLocations = watch('programsData.urbanData.stUrbanLocations');
  const noSTSelected = (assignedLocations || []).length === 0 && isBTLevel && !urbanAll;
  const [showSecretPhrase, setShowSecretPhrase] = useState<boolean>(false);

  function setSecretPhrase(enrolled: boolean) {
    let sp = null;
    if (enrolled) {
      if (initialSecretPhrase) {
        // We reset the secret phrase to the initial state
        sp = initialSecretPhrase;
      } else {
        const randomAdjective = SECRET_PHRASE_ADJ[Math.floor(Math.random() * SECRET_PHRASE_ADJ.length)];
        const randomNoun = SECRET_PHRASE_NOUNS[Math.floor(Math.random() * SECRET_PHRASE_NOUNS.length)];
        const randomNumbers = Math.floor(100 + Math.random() * 900); // Generates 3-digit number

        sp = `${randomAdjective}.${randomNoun}.${randomNumbers}`;
      }
    }

    setValue('programsData.fleetAppData.secret_phrase', sp, { shouldDirty: true });
  }

  if (loadingData) {
    return <LoadingBounce style={{ height: TABBED_CONTENT_HEIGHT }} />;
  }
  if (errorData) {
    return <PlainMessage title={t('Error')} messages={[t('Error loading data. Please try again later.')]} />;
  }
  return (
    <Grid container spacing={2}>
      {urbanData && (
        <Grid container item xs={12}>
          <Panel title={t('Urban Account Administration')}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="programsData.urbanData.is_urban"
                  defaultValue={urbanData.is_urban}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      id="isUrbanSwitch"
                      readOnly={urbanReadOnly}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        if (!e.target.checked) {
                          setValue('programsData.urbanAll', true);
                          setValue('programsData.urbanData.stUrbanLocations', []);
                          setValue('programsData.urbanData.urban_admin_allow_create_vehicle', false);
                          setValue('programsData.urbanData.urban_admin_allow_preapproved_txs', false);
                          setValue('programsData.urbanData.urban_admin_preapproved_txs_no_limits', false);
                          setValue('programsData.urbanData.urban_admin_preapproved_txs_limit_amount', '');
                        }
                        forceUpdate();
                      }}
                      color="primary"
                      disabled={urbanReadOnly}
                      className={classes.switchStyles}
                      label={t('Is Urban')}
                      fullWidth={false}
                    />
                  )}
                />
              </Grid>
              {isBTLevel && (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Controller
                    name="programsData.urbanAll"
                    defaultValue={urbanAll}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        id="isUrbanAllSwitch"
                        readOnly={urbanReadOnly || !urbanData.is_urban}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          if (!e.target.checked && (assignedLocations || []).length === 0) {
                            setValue('programsData.urbanData.urban_admin_allow_create_vehicle', false);
                            setValue('programsData.urbanData.urban_admin_allow_preapproved_txs', false);
                            setValue('programsData.urbanData.urban_admin_preapproved_txs_no_limits', false);
                            setValue('programsData.urbanData.urban_admin_preapproved_txs_limit_amount', '');
                          }
                          forceUpdate();
                        }}
                        color="primary"
                        disabled={urbanReadOnly || !urbanData.is_urban}
                        className={classes.switchStyles}
                        label={t('Apply to all {{stQty}} ST locations', {
                          stQty: stLocations ? stLocations?.length : 0,
                        })}
                        fullWidth={false}
                      />
                    )}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Controller
                    name="programsData.urbanData.stUrbanLocations"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NewLocationAssignments
                        owner={owner}
                        mode={!urbanReadOnly && !urbanAll && urbanData.is_urban ? 'edit' : 'view'}
                        all={urbanAll}
                        locations={value ? value.map((hash_key) => ({ hash_key })) : []}
                        onUpdate={(newAssignments) => {
                          onChange(newAssignments.map((na) => na.hash_key));
                          if (newAssignments.length === 0) {
                            setValue('programsData.urbanData.urban_admin_allow_create_vehicle', false);
                            setValue('programsData.urbanData.urban_admin_allow_preapproved_txs', false);
                            setValue('programsData.urbanData.urban_admin_preapproved_txs_no_limits', false);
                            setValue('programsData.urbanData.urban_admin_preapproved_txs_limit_amount', '');
                          }
                        }}
                        disabled={(urbanReadOnly && urbanData.stUrbanLocations?.length === 0) || !urbanData.is_urban}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                  >
                    <Controller
                      name="programsData.urbanData.urban_admin_allow_create_vehicle"
                      defaultValue={urbanData.urban_admin_allow_create_vehicle}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Switch
                            checked={value}
                            id="isUrbanAllowCreateVehicleSwitch"
                            readOnly={urbanReadOnly || !urbanData.is_urban || noSTSelected}
                            onChange={(e) => {
                              onChange(e.target.checked);
                              forceUpdate();
                            }}
                            color="primary"
                            disabled={urbanReadOnly || !urbanData.is_urban || noSTSelected}
                            className={classes.switchStyles}
                            label={t('Allow providers to add vehicles')}
                            fullWidth={false}
                          />
                          {!urbanReadOnly && urbanData.is_urban && noSTSelected && (
                            <TooltipWarning
                              tooltipPlacement="right"
                              title={t('Select at least 1 ST to enable this option')}
                              marginLeft={15}
                            />
                          )}
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                      <Grid
                        xs={12}
                        item
                        style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                      >
                        <Controller
                          name="programsData.urbanData.urban_admin_allow_preapproved_txs"
                          defaultValue={urbanData.urban_admin_allow_preapproved_txs}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Switch
                                checked={value}
                                id="isUrbanAllowPreApprovedSwitch"
                                readOnly={urbanReadOnly || !urbanData.is_urban || noSTSelected}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  forceUpdate();
                                }}
                                color="primary"
                                disabled={urbanReadOnly || !urbanData.is_urban || noSTSelected}
                                className={classes.switchStyles}
                                label={t('Allow Pre-approved Transactions')}
                                fullWidth={false}
                              />
                              {!urbanReadOnly && urbanData.is_urban && noSTSelected && (
                                <TooltipWarning
                                  tooltipPlacement="right"
                                  title={t('Select at least 1 ST to enable this option')}
                                  marginLeft={15}
                                />
                              )}
                            </>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ textAlign: 'start', width: 'fit-content' }}>
                          {!!urbanData.urban_admin_allow_preapproved_txs && !!urbanData.is_urban && (
                            <>
                              <Controller
                                name="programsData.urbanData.urban_admin_preapproved_txs_no_limits"
                                defaultValue={urbanData.urban_admin_preapproved_txs_no_limits}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Switch
                                    checked={value}
                                    id="isUrbanNoLimitsSwitch"
                                    readOnly={urbanReadOnly}
                                    onChange={(e) => {
                                      onChange(e.target.checked);
                                      forceUpdate();
                                    }}
                                    color="primary"
                                    disabled={urbanReadOnly}
                                    className={classes.switchStyles}
                                    label={t('No-Limits')}
                                    fullWidth={false}
                                  />
                                )}
                              />
                              <br />
                            </>
                          )}

                          <Controller
                            name="programsData.urbanData.urban_admin_preapproved_txs_limit_amount"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                margin="dense"
                                style={{
                                  textAlign: 'start',
                                  maxWidth: 126,
                                  display:
                                    !!urbanData.urban_admin_preapproved_txs_no_limits ||
                                    !urbanData.urban_admin_allow_preapproved_txs ||
                                    !urbanData.is_urban
                                      ? 'none'
                                      : undefined,
                                }}
                                fullWidth={false}
                                label={t('Limit Amount')}
                                type="number"
                                value={field.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  !urbanReadOnly && field.onChange(e.currentTarget.value)
                                }
                                error={saveError.errors.includes('preapprovedLimitAmount')}
                                disabled={!isEdit || !permissions.allowsAction('locations.update_limits_amount')}
                                required={!!urbanData.urban_admin_allow_preapproved_txs}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment style={{ transform: 'scale(0.8)' }} position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Panel>
        </Grid>
      )}
      {(stCustomer || btCustomer) && commercialData && (
        <Grid container item xs={12}>
          <Panel title={t('Commercial Account Management')}>
            <Grid container spacing={2}>
              <Grid spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <Controller
                    name="programsData.commercialData.is_commercial"
                    defaultValue={commercialData.is_commercial}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        id="isCommercialSwitch"
                        readOnly={isCommercialReadOnly}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        disabled={isCommercialReadOnly}
                        className={classes.switchStyles}
                        label={t('Is Commercial', {
                          stQty: stLocations ? stLocations?.length : 0,
                        })}
                        fullWidth={false}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {!stCustomer && (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Controller
                    name="programsData.commercialAll"
                    defaultValue={commercialAll}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        id="commercialAllSwitch"
                        readOnly={isCommercialReadOnly}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        disabled={isCommercialReadOnly}
                        className={classes.switchStyles}
                        label={t('Apply to all {{stQty}} ST locations', {
                          stQty: stLocations ? stLocations?.length : 0,
                        })}
                        fullWidth={false}
                      />
                    )}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Controller
                    name="programsData.commercialData.stCommercialLocations"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NewLocationAssignments
                        owner={owner}
                        mode={!isCommercialReadOnly && !commercialAll ? 'edit' : 'view'}
                        all={commercialAll}
                        locations={value ? value.map((hash_key) => ({ hash_key })) : []}
                        onUpdate={(newAssignments) => {
                          onChange(newAssignments.map((na) => na.hash_key));
                        }}
                        disabled={isCommercialReadOnly && commercialData?.stCommercialLocations?.length === 0}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Panel>
        </Grid>
      )}
      {(stCustomer || btCustomer) && onCallDRData && (
        <Grid container item xs={12}>
          <Panel title={t('ON Call DR Dashboard')}>
            <Grid container spacing={2}>
              <Grid spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <Controller
                    name="programsData.onCallDRData.oncall_dr_dashboard"
                    defaultValue={onCallDRData.oncall_dr_dashboard}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        id="onCAllDRSwitch"
                        readOnly={onCallDRDashboardReadOnly}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        disabled={onCallDRDashboardReadOnly}
                        className={classes.switchStyles}
                        label={t('Enabled for use and access of the ON Call DR Dashboard')}
                        fullWidth={false}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {!stCustomer && (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Controller
                    name="programsData.onCallDRAll"
                    defaultValue={onCallDRAll}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        readOnly={onCallDRDashboardReadOnly}
                        disabled={onCallDRDashboardReadOnly}
                        fullWidth={false}
                        className={classes.switchStyles}
                        label={t('Enable all {{stQty}} ST locations for use and access of the ON Call DR Dashboard', {
                          stQty: stLocations ? stLocations?.length : 0,
                        })}
                      />
                    )}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Controller
                    name="programsData.onCallDRData.stOnCallLocations"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NewLocationAssignments
                        owner={owner}
                        mode={!onCallDRDashboardReadOnly && !onCallDRAll ? 'edit' : 'view'}
                        all={onCallDRAll}
                        locations={value ? value.map((hash_key) => ({ hash_key })) : []}
                        onUpdate={(newAssignments) => {
                          onChange(newAssignments.map((na) => na.hash_key));
                        }}
                        disabled={onCallDRDashboardReadOnly && onCallDRData.stOnCallLocations?.length === 0}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Panel>
        </Grid>
      )}
      {(stCustomer || btCustomer) && telematicsData && (
        <Grid container item xs={12}>
          <Panel
            title={t('Telematics')}
            subtitle={isBTLevel ? <></> : <MarginTooltip title={t('Telematics can only be edited at BT level')} />}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="programsData.telematicsData.telematics_is_used"
                  defaultValue={telematicsData.telematics_is_used}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      id="useTelematicsSwitch"
                      readOnly={generalReadOnly}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        forceUpdate();
                      }}
                      color="primary"
                      disabled={generalReadOnly}
                      className={classes.switchStyles}
                      label={t('Use Telematics')}
                      fullWidth={false}
                    />
                  )}
                />
              </Grid>
              {getValues().programsData.telematicsData?.telematics_is_used && !loadingData && (
                <Grid item xs={11}>
                  <Controller
                    name="programsData.telematicsData.telematics_list_of_platforms"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <PlatformsList
                        defaultValues={
                          telematicsData?.telematics_list_of_platforms &&
                          telematicsData.telematics_list_of_platforms.length > 0
                            ? telematicsData.telematics_list_of_platforms
                            : ['']
                        }
                        onValuesChange={(data) => {
                          onChange(data);
                        }}
                        readOnly={generalReadOnly}
                        error={saveError.errors.includes('telematicsPlatformsList') && !generalReadOnly}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Panel>
        </Grid>
      )}
      {(stCustomer || btCustomer) && telematicsData && (
        <Grid container item xs={12}>
          <Panel
            title={t('Fleet Management System')}
            subtitle={
              isBTLevel ? <></> : <MarginTooltip title={t('Fleet Management System can only be edited at BT level')} />
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="programsData.fmsData.fms_is_used"
                  defaultValue={fmsData?.fms_is_used}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      id="useFMSSwitch"
                      readOnly={generalReadOnly}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        forceUpdate();
                      }}
                      color="primary"
                      disabled={generalReadOnly}
                      className={classes.switchStyles}
                      label={t('Use Fleet Management System')}
                      fullWidth={false}
                    />
                  )}
                />
              </Grid>
              {getValues().programsData.fmsData?.fms_is_used && (
                <Grid item xs={10}>
                  <Controller
                    name="programsData.fmsData.fms_platform"
                    control={control}
                    rules={{
                      required: { value: true, message: t('FMS Name') },
                    }}
                    render={(item) => (
                      <TextField
                        id={item.field.name}
                        name="name"
                        label={t('FMS platform')}
                        type="string"
                        variant="standard"
                        value={item.field.value || ''}
                        onChange={(e) => item.field.onChange(e.target.value)}
                        readOnly={generalReadOnly}
                        required
                        error={saveError.errors.includes('fmsPlatform') && !generalReadOnly}
                        helperText={
                          saveError.errors.includes('fmsPlatform') && !generalReadOnly
                            ? t('The platform is required').toString()
                            : ''
                        }
                        maxLength={250}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Panel>
        </Grid>
      )}
      {marketingData && (
        <Grid container item xs={12}>
          <Panel
            title={t('Marketing Notifications')}
            subtitle={
              isBTLevel ? <></> : <MarginTooltip title={t('Marketing Notifications can only be edited at BT level')} />
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} spacing={2}>
                <Controller
                  name="programsData.marketingData.no_marketing_surveys"
                  defaultValue={marketingData.no_marketing_surveys}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      id="marketingOptSwitch"
                      onChange={(e) => {
                        onChange(e.target.checked);
                        forceUpdate();
                      }}
                      color="primary"
                      readOnly={generalReadOnly}
                      disabled={generalReadOnly}
                      className={classes.switchStyles}
                      label={t('Stop receiving ONCall Marketing and User Surveys')}
                      fullWidth={false}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Panel>
        </Grid>
      )}
      {isFeatureEnabled('secret-phrase') && permissions.allowsAction('locations.update_secret_phrase') && (
        <Grid container item xs={12}>
          <Panel
            title={t('Fleet App')}
            subtitle={
              isBTLevel ? <></> : <MarginTooltip title={t('Fleet App preferences can only be edited at BT level')} />
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} spacing={2}>
                <Controller
                  name="programsData.fleetAppEnrolled"
                  defaultValue={fleetAppEnrolled}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      id="fleetAppEnrolledSwitch"
                      onChange={(e) => {
                        const { checked } = e.target;
                        onChange(checked);
                        setSecretPhrase(checked);
                        forceUpdate();
                      }}
                      color="primary"
                      readOnly={generalReadOnly}
                      disabled={generalReadOnly}
                      fullWidth={false}
                      className={classes.switchStyles}
                      label={t('Enroll in Fleet App')}
                    />
                  )}
                />
                {fleetAppEnrolled && (
                  <Controller
                    name="programsData.fleetAppData.secret_phrase"
                    control={control}
                    rules={{ required: fleetAppEnrolled }}
                    render={(item) => (
                      <TextField
                        id={item.field.name}
                        name="name"
                        label={t('Secret phrase')}
                        type={showSecretPhrase ? 'text' : 'password'}
                        variant="standard"
                        value={item.field.value || ''}
                        onChange={(e) => item.field.onChange(e.target.value)}
                        readOnly={generalReadOnly}
                        disabled={generalReadOnly}
                        required
                        error={saveError.errors.includes('secretPhrase') && !generalReadOnly}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowSecretPhrase(!showSecretPhrase)}
                                edge="end"
                              >
                                {showSecretPhrase ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        helperText={
                          saveError.errors.includes('secretPhrase') && !generalReadOnly
                            ? t('The secret phrase is required').toString()
                            : ''
                        }
                        maxLength={50}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Panel>
        </Grid>
      )}
    </Grid>
  );
};
