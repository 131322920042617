import React, { useEffect, useState } from 'react';
import { AppBar, Container, Panel, Tab, TabPanel, Tabs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PageTitle } from 'components/PageTitle/PageTitle';
import DeliveryReceiptNotes from 'modules/Billing/DeliveryReceiptNotes/List';
import { BillingProfilesList } from 'modules/Billing/Profiles/List';
import { SelectedServiceOffer, ServiceOfferIds, getBaseUrl, getSelectedServiceOffer } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { BillingRequirements } from './Requirements';

interface NavTabsProps {
  selectedTab?: number;
  action: 'view' | 'edit' | 'create';
}

function BillingsModule(props: NavTabsProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [headerTitle] = useState(t('Billing Preferences') as string);

  const [selectedTab, setSelectedTab] = useState(props.selectedTab || 0);
  useEffect(() => setSelectedTab(props.selectedTab || 0), [props.selectedTab]);

  const selectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;
  const onCallFlag: boolean =
    !!selectedServiceOffer && !!selectedServiceOffer.id && selectedServiceOffer.id === ServiceOfferIds.onCall;

  function onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    let tabURL = 'profiles';

    switch (newValue) {
      case 0:
        tabURL = 'profiles';
        break;
      case 1:
        tabURL = 'requirements';
        break;
      case 2:
        tabURL = 'receiptnotes';
        break;
      default:
        tabURL = 'profiles';
        break;
    }
    history.push(`${getBaseUrl()}/billings/${tabURL}`);
  }

  return (
    <>
      <PageTitle rightItems={<LastModified entity="billing" />} title={headerTitle} />
      <Container>
        <Panel spacing={0}>
          <AppBar color="primary" position="static" style={{ height: 48 }}>
            <Tabs
              variant="scrollable"
              value={selectedTab}
              onChange={onTabChange}
              aria-label="nav tabs"
              scrollButtons="on"
            >
              <Tab style={{ minWidth: 260 }} label={t('Billing Profiles')} value={0} />
              <Tab style={{ minWidth: 260 }} label={t('Billing Requirements')} value={1} />
              <Tab style={{ minWidth: 260 }} label={t('Delivery Receipt Notes')} value={2} />
            </Tabs>
          </AppBar>
          <TabPanel value={selectedTab} index={0} style={{ padding: 0, border: 'none' }}>
            <BillingProfilesList onCallFlag={onCallFlag} enableLocation />
          </TabPanel>
          <TabPanel value={selectedTab} index={1} style={{ padding: 0, border: 'none' }}>
            <BillingRequirements />
          </TabPanel>
          <TabPanel value={selectedTab} index={2} style={{ padding: 0, border: 'none' }}>
            <DeliveryReceiptNotes />
          </TabPanel>
        </Panel>
      </Container>
    </>
  );
}

export { BillingsModule };
