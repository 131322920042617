import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Button } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';
import { useFormContext } from 'react-hook-form';
import { BillingProfileContextData } from '../types';

interface Props {
  open: boolean;
  duplicatedLocations: number | undefined;
  totalLocations: number | undefined;
  availableLocations: string[];
  handleClose: () => void;
  handleSave: () => void;
}

export default function UniqueBillingProfileModal(props: Props): JSX.Element {
  const { duplicatedLocations, totalLocations, availableLocations, open, handleClose, handleSave } = props;
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<BillingProfileContextData>();
  const profile = watch('profile');
  const assignedToAllLocations = watch('profile.general_information.set_all_locations');
  let serviceType = '';
  let profileType = '';

  if (profile && profile.general_information) {
    serviceType = getOptionLabel('billing_service_types', profile.general_information.service_type);

    profileType = getOptionLabel('profile_types', profile.general_information.profile_type);
  }

  const onSave = () => {
    const assignedLocations = watch('assignedLocationsRelationships');
    const newAssignedLocations = assignedLocations.filter((location) => {
      return availableLocations.includes(location.hash_key?.split('~')[1] || '');
    }) as BillingProfileContextData['assignedLocationsRelationships'];
    setValue('profile.assigned_locations', availableLocations);
    setValue('assignedLocationsRelationships', newAssignedLocations);
    handleSave();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent dividers>
        <div>
          <span>
            {t(
              'A duplicate Billing profile already exists at {{duplicatesCount}} out of {{profilesTotal}} ' +
                'which you selected for assignment. Only one Billing profile having both of the same field values are permitted ' +
                'at any one location. The duplicated values in the tire profile at those locations are:',
              {
                duplicatesCount: assignedToAllLocations ? t('All') : duplicatedLocations,
                profilesTotal: totalLocations,
              },
            )}
          </span>
          <br />
          <span>{`${t('Profile Type')} = ${profileType}`}</span>
          <br />
          <span>{`${t('Service Type')} = ${serviceType}`}</span>
          <br />
          {(!assignedToAllLocations && duplicatedLocations !== totalLocations) && (
            <span>
              {t(
                'You may continue with assigning to the {{amountOfLocations}} locations ' +
                  'where there was no exact match or return to the page.',
                { amountOfLocations: availableLocations.length },
              )}
            </span>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {(!assignedToAllLocations || duplicatedLocations !== totalLocations) && (
          <Button size="small" disabled={availableLocations.length === 0} style={{ margin: '8px' }} onClick={onSave}>
            {t('Save & Assign to {{amountOfLocations}} locations', { amountOfLocations: availableLocations.length })}
          </Button>
        )}
        <Button size="small" color="danger" style={{ margin: '8px' }} onClick={handleClose}>
          {t('Cancel & Return to Page')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
