import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { FilterGroup, GlobalActions, IconButton, useDialogs, useListSelectFilter } from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  useMaestroDataSource,
  usePermissions,
  useSnackbar,
  useTranslation,
} from '@michelin/central-provider';
import { BillingProfileOVM } from '@michelin/fcp-view-models/dist/models/billing';
import { getOptions } from '@michelin/select-options-provider';
import { SpeedDial } from 'components/SpeedDial';
import { profileList } from 'components/Types/APITypes';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { DataGrid, Template } from 'devextreme-react';
import { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { useDeleteBillingProfile } from 'hooks/useBillingProfile';
import { SelectedServiceOffer, ServiceOfferIds, getBaseUrl, getSelectedServiceOffer } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { getListProfileType } from 'utils';
import { CreatedByRender, LocationsRender, OptionRender, ProfileNameRender } from './Columns';
import { useGetViewActions } from './Components/SpeedDialActions';

export interface BillingProfileProps {
  onCallFlag: boolean;
  // setDataAndLoading: Function;
  enableLocation: boolean;
}

export function BillingProfilesTable(props: BillingProfileProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dialogs = useDialogs();
  const { allowsAction, location } = usePermissions();
  const theme = useTheme();
  const history = useHistory();
  const tabletView = useMediaQuery(theme.breakpoints.down(800));
  const cellView = useMediaQuery(theme.breakpoints.down(500));
  const [creatorFilterValue, setCreatorFilterValue] = useState('all');
  const [profileFilterValue, setProfileFilterValue] = useState('all');
  const [serviceFilterValue, setServiceFilterValue] = useState('all');
  const profileType = getListProfileType();
  const locSelectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;
  const notAllPreferences = locSelectedServiceOffer?.id !== ServiceOfferIds.allPreferences;
  const serviceTypeServiceOffer = notAllPreferences ? profileType : '';
  const deleteBillingProfile = useDeleteBillingProfile();

  const creatorFilter = useListSelectFilter({
    label: t('Creator'),
    options: getOptions('account_types', 'all', t('Any Level')),
    defaultValue: creatorFilterValue,
    hashParam: 'creator',
  });

  const profileTypeFilter = useListSelectFilter({
    label: t('Profile Type'),
    options: getOptions('profile_types', 'all', t('Any Profile Type')),
    defaultValue: profileFilterValue,
    hashParam: notAllPreferences ? '' : 'profile_type',
  });

  const serviceTypeFilter = useListSelectFilter({
    label: t('Service Type'),
    options: getOptions('service_types', 'all', t('Any Service Type')),
    defaultValue: serviceFilterValue,
    hashParam: 'service_type',
  });

  const filterGroup = useMemo(() => {
    let filter = [];
    if (notAllPreferences) {
      filter = [creatorFilter.filter, serviceTypeFilter.filter];
    } else {
      filter = [creatorFilter.filter, profileTypeFilter.filter, serviceTypeFilter.filter];
    }
    return new FilterGroup([...filter]);
  }, [creatorFilter.filter, profileTypeFilter.filter, serviceTypeFilter.filter, notAllPreferences]);

  useEffect(() => {
    if (!allowsAction('billing.list')) {
      enqueueSnackbar(t('User has no permissions to list billing profiles.'), { variant: 'warning' });
      history.push(getBaseUrl());
    } else if (!allowsAction('billing.read')) {
      enqueueSnackbar(t('User has no permissions to read billing profiles.'), { variant: 'warning' });
      history.push(getBaseUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (creatorFilter.value) {
      setCreatorFilterValue(creatorFilter.value);
    }
  }, [creatorFilter.value]);

  useEffect(() => {
    if (profileTypeFilter.value) {
      setProfileFilterValue(profileTypeFilter.value);
    }
  }, [profileTypeFilter.value]);

  useEffect(() => {
    if (serviceTypeFilter.value) {
      setServiceFilterValue(serviceTypeFilter.value);
    }
  }, [serviceTypeFilter.value]);

  useEffect(() => {
    clearMaestroDataSourceCache('billing-profile-list');
    setCreatorFilterValue('all');
    setProfileFilterValue('all');
    setServiceFilterValue('all');
  }, [locSelectedServiceOffer.id]);

  const ds = useMaestroDataSource({
    API: profileList(location?.customer_number || ''),
    reqOptions: {
      filters: {
        creator: creatorFilter.value === 'all' ? '' : creatorFilter.value?.toLowerCase(),
        profile_type: serviceTypeServiceOffer
          ? serviceTypeServiceOffer
          : profileTypeFilter.value === 'all'
          ? ''
          : profileTypeFilter.value?.toLowerCase(),
        service_type: serviceTypeFilter.value === 'all' ? '' : serviceTypeFilter.value?.toLowerCase(),
      },
    },
    componentPlacements: {
      filtersPlacement: tabletView ? 'outside' : 'embeded',
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup,
    cacheKey: 'billing-profile-list',
  });

  const reloadAfterAction = () => {
    async function reload() {
      clearMaestroDataSourceCache('billing-profile-list');
      await ds.dataSource.reload();
    }
    reload();
  };

  const readPermission = allowsAction('billing.read');
  const deletePermission = allowsAction('billing.delete');

  const renderActionButtons = (
    row: { data: BillingProfileOVM },
    confirmDeleteProfile: (data: BillingProfileOVM | undefined) => void | undefined,
  ) => {
    const canDelete = deletePermission && !!row.data.allowedActions?.delete;
    if (!canDelete) return null;
    return (
      <IconButton
        aria-label="delete"
        style={{ margin: '8px' }}
        onClick={() => {
          confirmDeleteProfile(row.data);
        }}
        size="small"
      >
        <DeleteIcon fontSize="small" color="primary" />
      </IconButton>
    );
  };

  const confirmDeleteProfile = (data: BillingProfileOVM | undefined) => {
    if (!data) return;

    dialogs.confirmDialog(
      t('Are you sure you want to delete this Billing profile?'),
      t('Delete Billing Profile.'),
      t('Yes'),
      t('No'),
      async () => {
        await deleteProfile(data);
      },
      undefined,
      { disableBackdropClick: true, disableEscapeKeyDown: true },
    );
  };

  const deleteProfile = async (billingProfile: BillingProfileOVM | undefined) => {
    if (!billingProfile || !billingProfile.id || !billingProfile?.owner?.customer_number) return;

    try {
      await deleteBillingProfile.mutateAsync({
        fleetId: billingProfile.owner?.customer_number,
        profileId: billingProfile.id,
      });
      enqueueSnackbar(t('Billing Profile Deleted.'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('Error deleting Billing Profile.'), {
        variant: 'error',
      });
    }
    reloadAfterAction();
  };
  return (
    <>
      <DataGrid
        style={{ height: CONTENT_AREA_HEIGHT }}
        noDataText={t('No billing profiles found...')}
        className="freespaced-table"
        dataSource={ds.dataSource}
        allowColumnReordering
        wordWrapEnabled
        remoteOperations
        onToolbarPreparing={ds.onToolbarPreparing}
      >
        <SearchPanel visible highlightSearchText={false} placeholder={t('Search...')} />
        <Scrolling mode="virtual" />
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <Column
          caption={t('Creator')}
          dataType="string"
          dataField="creator"
          alignment="center"
          allowSorting
          allowSearch={false}
          cellRender={CreatedByRender}
        />
        <Column
          caption={t('Profile Name')}
          dataType="string"
          dataField="profile_name"
          allowSorting
          allowSearch
          cellRender={({ row }) => <ProfileNameRender row={row} readPermission={readPermission} />}
        />
        <Column
          caption={t('Profile Type')}
          dataType="string"
          dataField="profile_type"
          allowSorting
          allowSearch
          cellRender={({ row }) => OptionRender('profile_types', row?.data?.general_information?.profile_type || '')}
        />
        <Column
          caption={t('Service Type')}
          dataType="string"
          dataField="service_type"
          allowSorting
          allowSearch
          cellRender={({ row }) => OptionRender('service_types', row?.data?.general_information?.service_type || '')}
        />
        {props.enableLocation && (
          <Column
            visible={location?.customer_type !== 'ST'}
            caption={t('Locations')}
            allowSorting={false}
            width={150}
            cellRender={LocationsRender}
            dataField="locationAssignments"
            allowSearch={false}
          />
        )}

        <Column
          visible={(row: BillingProfileOVM) => row?.allowedActions?.delete}
          allowFiltering={false}
          allowSearch={false}
          cellRender={({ row }) => renderActionButtons(row, confirmDeleteProfile)}
        />
      </DataGrid>
    </>
  );
}

export function BillingProfilesList(props: BillingProfileProps) {
  const { allowsAction } = usePermissions();

  const actions = useGetViewActions();
  return (
    <>
      <BillingProfilesTable
        onCallFlag={props.onCallFlag}
        // setDataAndLoading={props.setDataAndLoading}
        enableLocation={props.enableLocation}
      />
      <GlobalActions>
        {allowsAction('billing.create') && actions.length > 0 && <SpeedDial direction="up" actions={actions} />}
      </GlobalActions>
    </>
  );
}
