import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { PhoneInput, Select, useDialogs } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { BillingProfileOVM } from '@michelin/fcp-view-models';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import Autocomplete from 'components/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { AuthorizationService, BillingProfileContextData, BillingServiceTypes } from '../types';
import { ContactLevelToAuthorize } from './ContactLevelToAuthorize';

interface Props {
  editFlag: boolean;
  ers: boolean;
  poRequired: boolean;
  primaryContactRequired: boolean;
}

function isIssuerVisible(ers: boolean, primaryContactRequired: boolean, profile: BillingProfileOVM): boolean {
  return (
    // ERS Checking
    (ers === true &&
      typeof profile.ers_purchasing_procedures !== 'undefined' &&
      profile.ers_purchasing_procedures !== null &&
      // ERS Primary Contact
      ((primaryContactRequired === true &&
        profile.ers_purchasing_procedures.primary_method_to_request_service === AuthorizationService.fleet_contact) ||
        // ERS Secondary Contact
        (primaryContactRequired === false &&
          profile.ers_purchasing_procedures.secondary_method_to_request_service ===
            AuthorizationService.fleet_contact))) ||
    // ON-Site Checking
    (ers === false &&
      typeof profile.onsite_purchasing_procedures !== 'undefined' &&
      profile.onsite_purchasing_procedures !== null &&
      // ON-Site Primary Contact
      ((primaryContactRequired === true &&
        profile.onsite_purchasing_procedures.primary_method_to_request_service ===
          AuthorizationService.fleet_contact) ||
        // ON-Site Secondary Contact
        (primaryContactRequired === false &&
          profile.onsite_purchasing_procedures.secondary_method_to_request_service ===
            AuthorizationService.fleet_contact)))
  );
}

function isPhoneVisible(ers: boolean, primaryContactRequired: boolean, profile: BillingProfileOVM): boolean {
  return (
    // ERS Checking
    (ers === true &&
      typeof profile.ers_purchasing_procedures !== 'undefined' &&
      profile.ers_purchasing_procedures !== null &&
      // ERS Primary Phone Number
      ((primaryContactRequired === true &&
        profile.ers_purchasing_procedures.primary_method_to_request_service ===
          AuthorizationService.break_down_phone_number) ||
        // ERS Secondary Phone Number
        (primaryContactRequired === false &&
          profile.ers_purchasing_procedures.secondary_method_to_request_service ===
            AuthorizationService.break_down_phone_number))) ||
    // ON-Site Checking
    (ers === false &&
      typeof profile.onsite_purchasing_procedures !== 'undefined' &&
      profile.onsite_purchasing_procedures !== null &&
      // ON-Site Primary Phone Number
      ((primaryContactRequired === true &&
        profile.onsite_purchasing_procedures.primary_method_to_request_service ===
          AuthorizationService.break_down_phone_number) ||
        // ON-Site Secondary Phone Number
        (primaryContactRequired === false &&
          profile.onsite_purchasing_procedures.secondary_method_to_request_service ===
            AuthorizationService.break_down_phone_number)))
  );
}

export function ContactToRequireAuth(props: Props): JSX.Element {
  const { editFlag, ers, poRequired, primaryContactRequired } = props;
  const { t } = useTranslation();
  const { alertDialog } = useDialogs();
  const { location } = usePermissions();
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const methods = useFormContext<BillingProfileContextData>();
  const { getValues, setValue, watch, control, setError, clearErrors, trigger } = methods;
  const { profile } = getValues();
  const purchaseProcedure = ers
    ? watch('profile.ers_purchasing_procedures')
    : watch('profile.onsite_purchasing_procedures');

  const ppName = ers ? 'ers' : 'onsite';
  const contactLevel = primaryContactRequired ? 'primary' : 'secondary';
  const shortContactLevel = primaryContactRequired ? 'pri' : 'sec';

  const [optionsMethodToRequestService, setOptionsMethodToRequestService] = useState<SelectOption[]>([]);

  const watchContactTypes = methods.watch(
    `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_type`,
  );
  const watchContact = watch(`profile.${ppName}_purchasing_procedures.${contactLevel}_method_to_request_service`);

  useEffect(() => {
    if (watchContact === AuthorizationService.fleet_contact && shortContactLevel === 'pri') {
      trigger(`profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_type`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchContactTypes]);

  useEffect(() => {
    const opts: SelectOption[] = getOptions('method_to_req_service').filter((x) => {
      if (
        profile?.general_information?.service_type === BillingServiceTypes.MECHANICAL &&
        (x.value === 'break_down_phone_number' || x.value === 'driver')
      ) {
        return false;
      }
      return true;
    });
    setOptionsMethodToRequestService(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.general_information?.service_type]);

  const methodToRequestServiceDisabled =
    purchaseProcedure?.required_authorization_to_begin_service === 'po' && primaryContactRequired;
  const issuerVisible = isIssuerVisible(ers, primaryContactRequired, profile);
  const phoneVisible = isPhoneVisible(ers, primaryContactRequired, profile);

  const contactTypesValue = primaryContactRequired
    ? purchaseProcedure?.pri_ers_authorized_contact_type
    : purchaseProcedure?.sec_ers_authorized_contact_type;

  const authorized_tire_po_issuer = { label: t('Tire PO Issuer'), value: 'po_issuer' };
  const authorized_tire_ers_manager = { label: t('Tire ERS Manager'), value: 'ers_manager' };
  const contactTypesSuggestions = props.ers
    ? [authorized_tire_po_issuer, authorized_tire_ers_manager]
    : getOptions('contact_types');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Controller
            name={`profile.${ppName}_purchasing_procedures.${contactLevel}_method_to_request_service`}
            control={control}
            rules={{
              validate: (value) =>
                (value && value !== 'Select') ||
                t('The {{contactLevel}} contact must be defined', {
                  contactLevel: primaryContactRequired ? t('primary') : t('secondary'),
                }).toString(),
            }}
            render={(item) => (
              <Select
                label={t('{{contactLevel}} Contact if Pre-Approval limit is reached*', {
                  contactLevel: primaryContactRequired ? t('Primary') : t('Secondary'),
                })}
                value={item.field.value || ''}
                readOnly={!editFlag || (primaryContactRequired && poRequired) || methodToRequestServiceDisabled}
                onChange={(e) => {
                  item.field.onChange(e.target.value);
                  if (e.target.value === AuthorizationService.fleet_contact) {
                    if (profile.owner?.customer_type === 'ST') {
                      setValue(
                        `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_level`,
                        'ST',
                      );
                    } else {
                      setValue(
                        `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_level`,
                        '',
                      );
                    }
                    setValue(`profile.${ppName}_purchasing_procedures.${shortContactLevel}_phone_number`, '');
                  } else if (e.target.value === AuthorizationService.break_down_phone_number) {
                    setValue(
                      `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_level`,
                      '',
                    );
                    setValue(
                      `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_type`,
                      [],
                    );
                  } else if (e.target.value === AuthorizationService.driver) {
                    setValue(`profile.${ppName}_purchasing_procedures.${shortContactLevel}_phone_number`, '');
                    setValue(
                      `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_level`,
                      '',
                    );
                    setValue(
                      `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_type`,
                      [],
                    );
                  }
                }}
                options={optionsMethodToRequestService}
                style={{ width: '100%' }}
                error={
                  !!methods.formState.errors.profile?.[`${ppName}_purchasing_procedures`]?.[
                    `${contactLevel}_method_to_request_service`
                  ]
                }
                helperText={
                  methods.formState.errors.profile?.[`${ppName}_purchasing_procedures`]?.[
                    `${contactLevel}_method_to_request_service`
                  ]?.message
                }
                className="acidSelectFieldNowrap"
              />
            )}
          />
        </Grid>
        <Controller
          name={`profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_type`}
          control={control}
          rules={{
            validate: (value) => {
              const methodToRequestService = methods.getValues(
                `profile.${ppName}_purchasing_procedures.${contactLevel}_method_to_request_service`,
              );
              const isValidArrayLength = Array.isArray(value) && (value.length === 1 || value.length === 2);
              const isContactFleet = methodToRequestService === AuthorizationService.fleet_contact;
              const errorMessage = t('{{contactLevel}} contact types are required.', {
                contactLevel: primaryContactRequired ? t('Primary') : t('Secondary'),
              }).toString();
              return (isValidArrayLength && isContactFleet) || !issuerVisible || errorMessage;
            },
          }}
          render={(item) => (
            <>
              {issuerVisible && (
                <>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="autocompletepp"
                      multiple
                      variant="standard"
                      label={`${t('Contact Types Who May Authorize Service')}*`}
                      disableClearable
                      disableCloseOnSelect
                      placeholder=""
                      onChange={(value) => {
                        if ((value.length ?? 0) <= 2) {
                          if (ers && primaryContactRequired && methodToRequestServiceDisabled) {
                            if (value.some((v) => v === 'po_issuer')) item.field.onChange(value);
                          } else {
                            item.field.onChange(value);
                          }
                        } else {
                          alertDialog(
                            [
                              t("You can't select more than 2 contact types."),
                              t('Please remove one contact type before selecting another.'),
                            ],
                            t('Max number of contact types selected'),
                          );
                        }
                      }}
                      suggestions={contactTypesSuggestions}
                      values={contactTypesValue}
                      readOnly={!editFlag}
                      error={
                        !!methods.formState.errors.profile?.[`${ppName}_purchasing_procedures`]?.[
                          `${shortContactLevel}_ers_authorized_contact_type`
                        ]
                      }
                      helperText={
                        methods.formState.errors.profile?.[`${ppName}_purchasing_procedures`]?.[
                          `${shortContactLevel}_ers_authorized_contact_type`
                        ]?.message
                      }
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        />
        {issuerVisible && contactTypesValue && contactTypesValue.length > 0 && purchaseProcedure && (
          <Grid item xs={12} md={4} style={{ display: 'flex' }}>
            <ContactLevelToAuthorize
              editFlag={editFlag}
              ppName={ppName}
              contactLevel={contactLevel}
              shortContactLevel={shortContactLevel}
            />
          </Grid>
        )}

        <Controller
          name={`profile.${ppName}_purchasing_procedures.${shortContactLevel}_phone_number`}
          control={control}
          rules={{
            validate: (value) => {
              return (!!value && !phoneNumberError) || !phoneVisible || t('Enter a valid phone number').toString();
            },
          }}
          render={(item) => (
            <>
              {phoneVisible ? (
                <Grid item xs={12} md={4}>
                  <PhoneInput
                    id={`${ppName}_${shortContactLevel}_breakdown_phone`}
                    label={t('Fleet Breakdown Phone Number')}
                    required
                    value={item.field.value || ''}
                    defaultCountry={location?.customer_country.toLowerCase() === 'ca' ? 'CA' : 'US'}
                    readOnly={!editFlag}
                    onChange={(event: any) => {
                      if (
                        event.value !== item.field.value &&
                        !((item.field.value === null || item.field.value === undefined) && event.value === '')
                      ) {
                        item.field.onChange(event.value);
                      }
                      if (
                        !event.isPossible &&
                        !!!item.formState.errors.profile?.[`${ppName}_purchasing_procedures`]?.[
                          `${shortContactLevel}_phone_number`
                        ]
                      ) {
                        setError(`profile.${ppName}_purchasing_procedures.${shortContactLevel}_phone_number`, {
                          type: 'manual',
                          message: t('Enter a valid phone number').toString(),
                        });
                        setPhoneNumberError(true);
                      } else if (
                        event.isPossible &&
                        !!item.formState.errors.profile?.[`${ppName}_purchasing_procedures`]?.[
                          `${shortContactLevel}_phone_number`
                        ]
                      ) {
                        clearErrors(`profile.${ppName}_purchasing_procedures.${shortContactLevel}_phone_number`);
                        setPhoneNumberError(false);
                      }
                    }}
                  />
                </Grid>
              ) : null}
            </>
          )}
        />
      </Grid>
    </>
  );
}
