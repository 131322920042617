/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button, Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { BillingTireRequestedInstalledDetails } from '@michelin/fcp-view-models';
import { useFormContext } from 'react-hook-form';
import { TireList } from '../Components/Tires';
import { BillingProfileContextData, BillingTireOffInstalled } from '../types';

export interface RequestedTiresDetailProps {
  action: string;
}

export function RequestedTiresDetail(props: RequestedTiresDetailProps) {
  const { t } = useTranslation();
  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';
  const [clickedAdd, setClickedAdd] = useState(false);
  const { getValues } = useFormContext<BillingProfileContextData>();
  const title = t('Requested Off/Installed Tire Details');
  let isOffAssigned = false;
  let isInstalledAssigned = false;
  let noRequestedDetails = false;
  const { profile } = getValues();
  if (profile.tire_details) {
    profile.tire_details.forEach((tireDetail: BillingTireRequestedInstalledDetails) => {
      if (tireDetail.off_installed === BillingTireOffInstalled.OFF_TIRES) {
        isOffAssigned = true;
      } else {
        isInstalledAssigned = true;
      }
      if (!tireDetail.requested_details || tireDetail.requested_details.length === 0) {
        noRequestedDetails = true;
      }
    });
  }

  return (
    <Panel
      title={title}
      subtitle={
        <span style={{ fontSize: '0.8em' }}>
          <Alert severity="warning">
            {t(
              'Michelin does not guarantee the applicable dealer from its independent dealer network will provide these Requested Details.',
            )}
          </Alert>
        </span>
      }
      style={{ marginBottom: 15 }}
      control={
        !editFlag || (isOffAssigned && isInstalledAssigned) ? (
          <></>
        ) : (
          <Button
            color="default"
            variant="default"
            onClick={() => setClickedAdd(true)}
            disabled={(isOffAssigned || isInstalledAssigned) && noRequestedDetails}
          >
            {t('Add')}
          </Button>
        )
      }
      id="requestedOffInstalled"
    >
      <Grid container>
        <Grid container spacing={2}>
          <TireList
            editFlag={editFlag}
            bothAssigned={isOffAssigned && isInstalledAssigned}
            clickedAdd={clickedAdd}
            setClickedAdd={setClickedAdd}
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
