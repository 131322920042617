import React from 'react';
import { ContactTypeOVM, LocationOVM } from '@michelin/fcp-view-models';
import { getOptionLabel } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { gql } from 'apollo-boost';
import { updateContactContactTypes } from 'components/Contact/ContactDetails/utils';
import { Contact, ContactTypeLevels } from 'components/Contact/utils';
import {
  ERS_MANAGER_ROLE_AREA_VALUE,
  GENERAL_LEVEL_VALUE,
  PO_ISSUER_ROLE_AREA_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  TIRE_SERVICE_VALUE,
} from 'modules/Contacts/utils';
import { v4 as uuidv4 } from 'uuid';
import { BillingReassignContact } from '../../types';
import { BillingReassignContactType, State } from './MissingCriticalRolesDialog';

export const missingCriticalRolesTitle = 'Locations Missing Critical Roles';

export const missingCriticalRolesText = (
  withOutAssignedContacts: number,
  totalLocations: number,
  contactLevel: string,
  contactTypes: Array<string>,
  update: boolean,
  t: Function,
) => {
  const mappedContactTypes = contactTypes.map((x) => `${t('Tire')} ${getOptionLabel('contact_types', x)}`).join(', ');
  const mapContactLevel = new Map<string, string>();
  mapContactLevel.set('BT', t('Bill To'));
  mapContactLevel.set('ST', t('Ship To'));
  mapContactLevel.set('HO', t('Home Office'));
  mapContactLevel.set('PC', t('Parent Company'));

  return (
    <p>
      {t(
        '{{alreadyAssignedLocations}} out of {{totalLocations}} "{{contactLevel}}" Locations are missing contacts that have the contact type roles(s) of: "{{contectTypes}}".',
        {
          alreadyAssignedLocations: withOutAssignedContacts,
          totalLocations,
          contactLevel: mapContactLevel.get(contactLevel),
          contectTypes: mappedContactTypes,
        },
      )}
      <br />
      {update && (
        <>
          {t(
            'Do you wish to update the contacts for each of these locations to ensure that these roles are available for the Billing profile?',
          )}
          <br />
          <br />
          {t('Click')}
          <b>{` ${t('Update').toUpperCase()} `}</b>
          {t('to update the contacts for the location(s).')}
          <br />
          {t('Click')}
          <b>{` ${t('Close').toUpperCase()} `}</b>
          {t('to return to the page.')}
        </>
      )}
    </p>
  );
};

export const rolesToEditAssign = ['po_issuer', 'ers_manager'];

/* STATE HANDLING */
export function resetState(): State {
  return {
    currentIndex: undefined,
    firstSelectedLocationIndex: undefined,
    newAssignedContactTypes: new Map(),
    createContactMode: false,
    removedAssignedContactTypes: new Set(),
  };
}

export function getContactTypesFromContacts(location: LocationOVM, contacts: Array<BillingReassignContact>) {
  const contactTypesPerLocation = new Map<string, BillingReassignContact>();

  contacts.forEach((contact) => {
    const { contact_types } = contact;
    if (contact_types) {
      contact_types.forEach((contactType) => {
        const { role_areas, service } = contactType;
        if (service === TIRE_SERVICE_VALUE && role_areas) {
          role_areas.forEach((contactTypeRoleArea) => {
            const { levels, role_area } = contactTypeRoleArea;
            if ((role_area === ERS_MANAGER_ROLE_AREA_VALUE || role_area === PO_ISSUER_ROLE_AREA_VALUE) && levels) {
              levels.forEach((contactTypeLevel) => {
                const { level, location: locationHashKey } = contactTypeLevel;

                if (
                  location.hash_key === locationHashKey &&
                  (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE)
                ) {
                  contactTypesPerLocation.set(`${role_area}~${level}`, contact);
                }
              });
            }
          });
        }
      });
    }
  });

  return contactTypesPerLocation;
}

// TODO: it's needed a new endpoint to get the contacts from the location. Contact Refactor.
export async function loadContacts(locationSelected: LocationOVM): Promise<Array<BillingReassignContact>> {
  const contacts: Array<BillingReassignContact> = await getLocationContacts(locationSelected.customer_number || '');
  return contacts;
}

/* QUERY */
const contactRelationshipsQuery = gql`
  query ContactLocationRelationship($locations: [String]!) {
    getContactLocationsRelationships(locations: $locations) {
      hash_key
      range_key
      is_deleted
      contact {
        hash_key
        first_name
        last_name
        contact_types {
          id
          service
          role_areas {
            role_area
            levels {
              location
              level
            }
          }
        }
        cell_phone
        work_phone
        ext
        email_address
        preferred_method
        job_title
        contact_level
      }
    }
  }
`;

export async function getLocationContacts(locationNumber: string): Promise<Array<BillingReassignContact>> {
  const apolloClient = auth.apolloClient;

  if (apolloClient) {
    try {
      const queryData = await apolloClient.query({
        query: contactRelationshipsQuery,
        variables: {
          locations: [locationNumber],
        },
        fetchPolicy: 'no-cache',
      });

      const contacts =
        queryData.data.getContactLocationsRelationships &&
        queryData.data.getContactLocationsRelationships
          .filter((x: any) => !x.is_deleted && x.contact)
          .map((x: any) => x.contact);

      return contacts;
    } catch (e) {
      console.error('Error requesting contacts', e);
    }
  }

  return [];
}

export function getCustomerNumbersFromRelationship(relationship: string) {
  const locationNumbers = relationship.split('~');
  let ship_to_customer;
  let home_office_number;
  let parent_company_number;
  let bill_to_customer;

  const indexedSplit = (splittedVar: Array<string>, index: number) =>
    splittedVar.length >= index ? splittedVar[index] : '';

  parent_company_number = indexedSplit(locationNumbers, 0);
  parent_company_number =
    parent_company_number === 'null' || typeof parent_company_number !== 'string' ? undefined : parent_company_number;
  home_office_number = indexedSplit(locationNumbers, 1);
  home_office_number =
    home_office_number === 'null' || typeof home_office_number !== 'string' ? undefined : home_office_number;
  bill_to_customer = indexedSplit(locationNumbers, 2);
  bill_to_customer = bill_to_customer === 'null' || typeof bill_to_customer !== 'string' ? undefined : bill_to_customer;
  ship_to_customer = indexedSplit(locationNumbers, 3);
  ship_to_customer = ship_to_customer === 'null' || typeof ship_to_customer !== 'string' ? undefined : ship_to_customer;

  return {
    ship_to_customer,
    home_office_number,
    parent_company_number,
    bill_to_customer,
  };
}

export interface ContactTypeToReassign {
  location: Pick<LocationOVM, 'hash_key'>;
  service: string;
  role_area: string;
  level: string;
}
export interface SelectedContactToReassign extends ContactTypeToReassign {
  contact: Contact;
  location: Pick<LocationOVM, 'hash_key'>;
  service: string;
  role_area: string;
  level: string;
}

export function updateAssignedContactTypes(assignedContactTypes: Map<string, BillingReassignContactType>): any {
  const assignedSelectedToReassign = new Map<string, SelectedContactToReassign[]>();
  assignedContactTypes.forEach((x) => {
    const contactHashKey = x.contact.hash_key;
    const alreadyAddedContact = assignedSelectedToReassign.get(contactHashKey);
    if (alreadyAddedContact) {
      alreadyAddedContact.push({
        contact: x.contact as any,
        level: x.level,
        service: TIRE_SERVICE_VALUE,
        location: x.location,
        role_area: x.roleArea,
      });
      assignedSelectedToReassign.set(contactHashKey, alreadyAddedContact);
    } else {
      assignedSelectedToReassign.set(contactHashKey, [
        {
          contact: x.contact as any,
          level: x.level,
          service: TIRE_SERVICE_VALUE,
          location: x.location,
          role_area: x.roleArea,
        },
      ]);
    }
  });
  return assignedSelectedToReassign;
}

export async function saveReassignations(selectedToReassign?: Map<string, SelectedContactToReassign[]>) {
  if (typeof selectedToReassign !== 'undefined') {
    const contacts = applySelectedToReassignToContact(selectedToReassign);
    const contact_hash_keys = Array.from(contacts.keys());
    const updateContactMutationsResults = new Array<Promise<any>>();

    contact_hash_keys.forEach((contactHashKey) => {
      const contactToUpdate = contacts.get(contactHashKey);
      if (typeof contactToUpdate !== 'undefined') {
        const { contact_types, email_address, work_phone, cell_phone } = contactToUpdate;
        if (typeof contact_types !== 'undefined') {
          const mutationResult = updateContactContactTypes(
            contactHashKey,
            contact_types,
            email_address,
            work_phone,
            cell_phone,
          );
          if (typeof mutationResult !== 'undefined') {
            updateContactMutationsResults.push(mutationResult);
          }
        }
      }
    });
    await Promise.all(updateContactMutationsResults);
  }
}

export function applySelectedToReassignToContact(
  selectedToReassign: Map<string, Array<SelectedContactToReassign>>,
): Map<string, Contact> {
  const contacts: Map<string, Contact> = new Map<string, Contact>();
  const hash_keys = Array.from(selectedToReassign.keys());

  hash_keys.forEach((hashKey) => {
    // Get Contact to reassign
    const contactAssignations = selectedToReassign.get(hashKey);

    if (contactAssignations) {
      contactAssignations.forEach((contactAssignation) => {
        const { level, location, contact: contactToAssign, role_area, service } = contactAssignation;
        let contact = contacts.get(contactToAssign.hash_key);

        if (!contact && contactToAssign) {
          contact = contactToAssign;
        }

        if (contact) {
          const { contact_types } = contact;
          if (contact_types) {
            const contactTypeWithServiceFind = contact_types.find((x) => x.service === service);

            if (contactTypeWithServiceFind) {
              // Service already exist.
              const { role_areas } = contactTypeWithServiceFind;
              if (role_areas) {
                const contactTypeRoleAreaFind = role_areas.find((x) => x.role_area === role_area);
                if (contactTypeRoleAreaFind) {
                  // Role Area exist. Add level to location
                  const { levels } = contactTypeRoleAreaFind;
                  const contactTypeRoleLocationFind = levels?.find((x) => x.location === location.hash_key);
                  if (contactTypeRoleLocationFind) {
                    contactTypeRoleLocationFind.level = level;
                  } else {
                    // Location is not assigned to this contact. Wrong state.
                    console.warn(`Contact ${contact.hash_key} does not have the location ${location.hash_key}.`);
                  }
                } else {
                  // Role Area does not exist. Add role area to role areas.
                  const locations = getLocationsFromContactType(contact_types[0]);
                  const levels = createLevelsForLocations(locations, location.hash_key || '', level);
                  role_areas.push({
                    role_area,
                    levels,
                  });
                }
              } else {
                // No role areas. Wrong state on contact.
                console.warn(`Contact ${contact.hash_key} has no role role areas.`);
              }
            } else {
              // Service does not exist. Create one.
              const locations = getLocationsFromContactType(contact_types[0]);
              const levels = createLevelsForLocations(locations, location.hash_key || '', level);
              contact_types.push({
                id: uuidv4(),
                role_areas: [
                  {
                    role_area,
                    levels,
                  },
                ],
                service,
              });
            }
          } else {
            // No Contact Types. Wrong State on contact.
            console.warn(`Contact ${contact.hash_key} has no Contact Types.`);
          }

          contacts.set(contactToAssign.hash_key, contact);
        }
      });
    }
  });

  return contacts;
}

export function getLocationsFromContactType(contact_type: ContactTypeOVM): Array<string> {
  const locations = new Array<string>();

  if (contact_type && contact_type.role_areas && contact_type.role_areas[0]) {
    contact_type.role_areas[0]?.levels?.forEach((level) => {
      locations.push(level.location || '');
    });
  }

  return locations;
}

export function createLevelsForLocations(
  locations: Array<string>,
  location: string,
  level: string,
): Array<ContactTypeLevels> {
  return locations.map((x) => {
    if (x === location) {
      return {
        level,
        location: x,
      };
    }
    return {
      level: GENERAL_LEVEL_VALUE,
      location: x,
    };
  });
}
