import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';
import { IconButton, Select } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { LocationOVM } from '@michelin/fcp-view-models';
import { getOptions } from '@michelin/select-options-provider';
import { TIRE_SERVICE_VALUE } from 'modules/Contacts/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { isHierarchyHigher } from 'utils/reusableMethods';
import { BillingProfileContextData, LocationContactRole } from '../types';
import { ReassignContactTypes } from './ReassignContactTypes/ReassignContactTypes';
import { rolesToEditAssign } from './ReassignContactTypes/utils';

interface Props {
  editFlag: boolean;
  ppName: 'ers' | 'onsite';
  contactLevel: 'primary' | 'secondary';
  shortContactLevel: 'pri' | 'sec';
}

export function ContactLevelToAuthorize(props: Props): JSX.Element {
  const { editFlag, ppName, contactLevel, shortContactLevel } = props;

  const { getValues, control, watch } = useFormContext<BillingProfileContextData>();
  const { profile, authorizeServiceLocations } = getValues();
  const watchAssignedLocationsRelationships = watch('assignedLocationsRelationships');
  const watchSetToAll = watch(`profile.general_information.set_all_locations`);
  const watchAuthorizeServiceContacts = watch('authorizeServiceContacts');
  const watchLoadingAuthorizeServiceLocations = watch('isAuthorizeServiceLocationsLoading');
  const watchLoadingAuthorizeServiceContacts = watch('isAuthorizeServiceContactsLoading');
  const { location } = usePermissions();
  const { t } = useTranslation();
  const watchLocationLevel = getValues(
    `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_level`,
  );
  const [locationsWithContacts, setLocationsWithContacts] = useState<string[]>([]);
  const [locationsWithContactsToAssign, setLocationsWithContactsToAssign] = useState<string[]>([]);
  const [locationsWithContactsToAssignData, setlocationsWithContactsToAssignData] = useState<LocationOVM[]>([]);
  const [locationsContactsRolesData, setLocationsContactsRolesData] = useState<LocationContactRole[]>([]);
  const [displayWarningDialog, setDisplayWarningDialog] = useState(false);
  const [displayLocationsContactsDialog, setDisplayLocationsContactsDialog] = useState(false);
  const [disableEditContactTypes, setDisableEditContactTypes] = useState(false);
  const maxLevel = (profile.owner && profile.owner.customer_type) || location?.customer_type || '';
  const options_sorted_account_types = getOptions('sorted_account_types').filter((x) =>
    isHierarchyHigher(maxLevel.toLocaleUpperCase(), x.value),
  );

  const checkContactLocationsAssignation = ({
    locLevel,
    selectedContactTypes,
    level,
  }: {
    locLevel: string;
    selectedContactTypes: string[];
    level: 'primary' | 'secondary';
  }): {
    locationsWithContactTypeRequired: string[];
    locationsWithContactsToAssign: string[];
    locationsWithContactsToAssignData: LocationOVM[];
    locationsContactsRoles: string[][];
    locationsContactsRolesData: LocationContactRole[];
  } => {
    if (!['PC', 'HO', 'BT', 'ST'].includes(locLevel) || !watchAuthorizeServiceContacts || !authorizeServiceLocations)
      return {
        locationsWithContactTypeRequired: [],
        locationsWithContactsToAssign: [],
        locationsWithContactsToAssignData: [],
        locationsContactsRoles: [],
        locationsContactsRolesData: [],
      };
    let auxLocationsWithContactTypeRequired: string[] = [];
    let auxLocationsContactsRoles: string[][] = [];
    let auxLocationsContactsRolesData: LocationContactRole[] = [];
    let auxAuthorizeServiceLocations: LocationOVM[] = [];
    if (profile.general_information?.set_all_locations) {
      auxAuthorizeServiceLocations = authorizeServiceLocations[locLevel as 'PC' | 'HO' | 'BT' | 'ST'];
    } else {
      auxAuthorizeServiceLocations = authorizeServiceLocations[locLevel as 'PC' | 'HO' | 'BT' | 'ST'].filter(
        (authLoc) => {
          const id = (authLoc.hash_key || '').split('~')[1];
          return watchAssignedLocationsRelationships.some(
            (assign) =>
              (authLoc.relationship && authLoc.relationship.includes((assign.hash_key || '').split('~')[1])) ||
              (assign.relationship && assign.relationship.includes(id)),
          );
        },
      );
    }
    let hierarchyHigher = true;
    if (!isHierarchyHigher(location?.customer_type || '', profile.owner?.customer_type || '')) {
      hierarchyHigher = false;
      auxAuthorizeServiceLocations = auxAuthorizeServiceLocations.filter((authLoc) => {
        return authLoc.relationship?.includes((location?.hash_key || '').split('~')[1]);
      });
    }

    watchAuthorizeServiceContacts[locLevel as 'PC' | 'HO' | 'BT' | 'ST'].forEach((contact) => {
      if (!contact.contact_types) return;
      contact.contact_types.forEach((contactType) => {
        if (contactType.service !== TIRE_SERVICE_VALUE || !contactType.role_areas) return;
        contactType.role_areas.forEach((contactTypeRoleArea) => {
          if (!contactTypeRoleArea.role_area) return;
          if (!selectedContactTypes.includes(contactTypeRoleArea.role_area || '')) return;
          contactTypeRoleArea.levels?.forEach((contactTypeRoleAreaLevel) => {
            if (contactTypeRoleAreaLevel.level !== level) return;
            const sameLevel = locLevel === profile.owner?.customer_type;
            if (
              (profile.general_information?.set_all_locations && !sameLevel && hierarchyHigher) ||
              auxAuthorizeServiceLocations.some((loc) => loc.hash_key === contactTypeRoleAreaLevel.location)
            ) {
              auxLocationsWithContactTypeRequired.push(contactTypeRoleAreaLevel.location || '');
              auxLocationsContactsRoles.push([
                contactTypeRoleAreaLevel.location ?? '',
                contact.id || '',
                contactTypeRoleArea.role_area || '',
              ]);

              let locationContactRole = auxLocationsContactsRolesData.find(
                (loc) => loc?.location.hash_key === contactTypeRoleAreaLevel.location,
              );

              if (!locationContactRole) {
                const locationData = auxAuthorizeServiceLocations.find(
                  (loc) => loc.hash_key === contactTypeRoleAreaLevel.location,
                );

                if (locationData) {
                  const auxLocationContactRole = {
                    location: locationData,
                    contacts: [{ contact: contact, roles: [contactTypeRoleArea.role_area || ''] }],
                  };
                  auxLocationsContactsRolesData.push(auxLocationContactRole);
                }
              }

              if (locationContactRole) {
                const existingContact = locationContactRole.contacts.find((c) => c.contact.id === contact.id);
                if (existingContact) {
                  existingContact.roles.push(contactTypeRoleArea.role_area || '');
                } else {
                  locationContactRole.contacts.push({
                    contact,
                    roles: [contactTypeRoleArea.role_area || ''],
                  });
                }
              }
            }
          });
        });
      });
    });
    auxLocationsWithContactTypeRequired = Array.from(new Set(auxLocationsWithContactTypeRequired));
    const locationsWithContactsToAssignData = auxAuthorizeServiceLocations.filter(
      (location) => !auxLocationsWithContactTypeRequired.includes(location.hash_key || ''),
    );
    const locationsWithContactsToAssign = locationsWithContactsToAssignData.map((location) => location.hash_key || '');

    return {
      locationsWithContactTypeRequired: auxLocationsWithContactTypeRequired,
      locationsWithContactsToAssign,
      locationsWithContactsToAssignData,
      locationsContactsRoles: auxLocationsContactsRoles,
      locationsContactsRolesData: auxLocationsContactsRolesData,
    };
  };

  const watchContactTypeList = watch(
    `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_type`,
  );
  const watchContactLevel = watch(
    `profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_level`,
  );
  useEffect(() => {
    if (
      (watchContactTypeList || []).length > 0 &&
      watchAuthorizeServiceContacts &&
      editFlag &&
      !watchLoadingAuthorizeServiceContacts &&
      !watchLoadingAuthorizeServiceLocations
    ) {
      const output = checkContactLocationsAssignation({
        locLevel: watchLocationLevel as string,
        selectedContactTypes:
          getValues().profile?.[`${ppName}_purchasing_procedures`]?.[
            `${shortContactLevel}_ers_authorized_contact_type`
          ] || [],
        level: contactLevel,
      });
      setLocationsWithContacts(output.locationsWithContactTypeRequired);
      setLocationsWithContactsToAssign(output.locationsWithContactsToAssign);
      setlocationsWithContactsToAssignData(output.locationsWithContactsToAssignData);
      setLocationsContactsRolesData(output.locationsContactsRolesData);

      if (!(watchContactTypeList ?? []).some((item) => rolesToEditAssign.includes(item))) {
        setDisableEditContactTypes(true);
      } else {
        setDisableEditContactTypes(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchContactTypeList,
    editFlag,
    watchAssignedLocationsRelationships,
    watchSetToAll,
    watchLoadingAuthorizeServiceContacts,
    watchLoadingAuthorizeServiceLocations,
  ]);

  return (
    <>
      <Grid container xs={12}>
        <Grid xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Controller
            name={`profile.${ppName}_purchasing_procedures.${shortContactLevel}_ers_authorized_contact_level`}
            control={control}
            rules={{
              validate: (value) =>
                (value && value !== '') ||
                t('The {{contactLevel}} contact level to authorize service must be defined', {
                  contactLevel: contactLevel,
                }).toString(),
            }}
            render={(item) => (
              <Select
                label={`${t('Contact Level to Authorize Service')}*`}
                value={item.field.value || ''}
                onChange={(event) => {
                  item.field.onChange(event.target.value);
                  if (!watchLoadingAuthorizeServiceContacts && !watchLoadingAuthorizeServiceLocations) {
                    const output = checkContactLocationsAssignation({
                      locLevel: event.target.value as string,
                      selectedContactTypes:
                        getValues().profile?.[`${ppName}_purchasing_procedures`]?.[
                          `${shortContactLevel}_ers_authorized_contact_type`
                        ] || [],
                      level: contactLevel,
                    });
                    setLocationsWithContacts(output.locationsWithContactTypeRequired);
                    setLocationsWithContactsToAssign(output.locationsWithContactsToAssign);
                    setlocationsWithContactsToAssignData(output.locationsWithContactsToAssignData);
                    setLocationsContactsRolesData(output.locationsContactsRolesData);
                    if (output.locationsWithContactsToAssign.length > 0) {
                      setDisplayWarningDialog(true);
                    }
                  }
                }}
                fullWidth
                options={options_sorted_account_types}
                readOnly={!editFlag}
                disabled={!options_sorted_account_types.length}
                error={!!item.fieldState.error}
              />
            )}
          />
          {editFlag &&
            watchLocationLevel &&
            locationsWithContactsToAssignData.length > 0 &&
            !watchLoadingAuthorizeServiceContacts &&
            !watchLoadingAuthorizeServiceLocations && (
              <IconButton
                color="primary"
                size="small"
                variant="default"
                disabled={disableEditContactTypes}
                onClick={() => {
                  setDisplayWarningDialog(true);
                }}
              >
                <Edit style={{ cursor: 'pointer' }} />
              </IconButton>
            )}
          {editFlag &&
            watchLocationLevel &&
            locationsContactsRolesData.length > 0 &&
            !watchLoadingAuthorizeServiceContacts &&
            !watchLoadingAuthorizeServiceLocations && (
              <IconButton
                color="primary"
                size="small"
                variant="default"
                onClick={() => {
                  setDisplayLocationsContactsDialog(true);
                }}
              >
                <Visibility style={{ cursor: 'pointer' }} />
              </IconButton>
            )}
        </Grid>
        <Grid item xs={12}>
          {locationsWithContactsToAssign.length > 0 &&
            editFlag &&
            !watchLoadingAuthorizeServiceContacts &&
            !watchLoadingAuthorizeServiceLocations && (
              <Typography color="error" variant="body1" style={{ marginTop: 8 }}>
                {t(
                  !disableEditContactTypes
                    ? '{{amount}} out of {{totalAmount}} {{locationLevel}} Locations are missing contacts with the necessary contact types to authorize service. Click edit to update contacts at these locations.'
                    : '{{amount}} out of {{totalAmount}} {{locationLevel}} Locations are missing contacts with the necessary contact types to authorize service.',
                  {
                    amount: locationsWithContactsToAssign.length,
                    totalAmount: locationsWithContactsToAssign.length + locationsWithContacts.length,
                    locationLevel: watchLocationLevel || '',
                  },
                )}
              </Typography>
            )}
          {editFlag &&
            watchContactLevel &&
            (watchLoadingAuthorizeServiceContacts || watchLoadingAuthorizeServiceLocations) && (
              <Typography color="error" variant="body1" style={{ marginTop: 8 }}>
                {t('Checking Locations and Contacts...')}
              </Typography>
            )}
        </Grid>
      </Grid>
      <ReassignContactTypes
        displayWarningDialog={displayWarningDialog}
        onClose={() => {
          setDisplayWarningDialog(false);
        }}
        contactLevel={contactLevel}
        contactTypes={watchContactTypeList || []}
        locationsWithContacts={locationsWithContacts}
        locationsWithContactsToAssignData={locationsWithContactsToAssignData}
        ppName={ppName}
        shortContactLevel={shortContactLevel}
        disableEditContactTypes={disableEditContactTypes}
        displayLocationsContactsDialog={displayLocationsContactsDialog}
        watchContactTypeList={watchContactTypeList || []}
        onCloseLocationsContactsDialog={() => {
          setDisplayLocationsContactsDialog(false);
        }}
        locationsContactsRolesData={locationsContactsRolesData}
      />
    </>
  );
}
