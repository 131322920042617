import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import { LocationContactRole } from '../../types';
import { AccountLocationRender, contactInfoRender, renderAddress } from './Columns';

interface Props {
  open: boolean;
  contactTypeLevel: string;
  contactTypes: Array<string>;
  onClose: () => void;
  locationsContactsRolesData: LocationContactRole[];
}

export function LocationsContactsDialog(props: Props): JSX.Element {
  const { open, onClose, contactTypeLevel, locationsContactsRolesData } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      scroll="paper"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('Contacts to Authorize Service')}</DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        <DataGrid
          dataSource={locationsContactsRolesData}
          showBorders
          repaintChangesOnly
          columnAutoWidth
          style={{ minHeight: '50vh' }}
        >
          <Scrolling mode="virtual" />
          <Column
            calculateSortValue={(row: LocationContactRole) =>
              `${row.location.customer_state} ${row.location.customer_city}`
            }
            allowSorting
            caption={t('Account/Location')}
            dataField="account_location"
            cellRender={({ row }: { row: { data: LocationContactRole } }) => (
              <AccountLocationRender location={row.data.location} clickable />
            )}
          />
          <Column
            calculateSortValue={(row: LocationContactRole) =>
              `${row.location.customer_addr1} ${row.location.customer_addr2}`
            }
            allowSorting
            caption={t('Address')}
            dataField="address"
            cellRender={({ row }: { row: { data: LocationContactRole } }) => renderAddress(row.data.location)}
          />
          <Column
            allowSorting={false}
            caption={t('Contact Info & Contact Types')}
            dataField="contact_info"
            cellRender={({ row }: { row: { data: LocationContactRole } }) =>
              contactInfoRender(row.data, contactTypeLevel, t)
            }
          />
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="default">
          {t('Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}