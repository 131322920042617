import React, { ChangeEvent, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { Button, Select, TextField, WithDialogsProps, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import Autocomplete from 'components/Autocomplete';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import { isValidEmail } from 'components/Util/validate';
import { Contact, DropDownElement, NotificationSubscription } from '../utils';
import { MECH_SERVICE_VALUE } from './utils';

function getDefaultIndex(list: DropDownElement[], value?: string | null): number {
  if (value) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].value === value) {
        return i;
      }
    }
  }
  return 0;
}

function updateEvent(
  notification: NotificationSubscription,
  setReadOnlyType: any,
  setReadOnlyMethod: any,
  setTypeOptions: any,
  setMethodOptions: any,
  setCCEmails: any,
  newValue: string,
  t: any,
) {
  notification.event = newValue;
  setReadOnlyMethod(true);
  notification.method = [];
  setMethodOptions([]);
  notification.type = 'Select';
  setCCEmails('');
  if (newValue && newValue !== '' && newValue !== 'Select') {
    setReadOnlyType(false);
    if (newValue === 'profile') {
      setTypeOptions(getOptions('notification_subscription_types_profile', 'Select', t('Select')));
    } else if (newValue === 'tire_ers') {
      setTypeOptions(getOptions('notification_subscription_types_tire_ers', 'Select', t('Select')));
    } else if (newValue === 'bulk_order') {
      setTypeOptions(getOptions('notification_subscription_types_bulk_order', 'Select', t('Select')));
    } else if (newValue === 'urban_fleet_ordering') {
      setTypeOptions(getOptions('notification_subscription_types_urban_fleet_ordering', 'Select', t('Select')));
    } else if (newValue === 'mechanical_care') {
      setTypeOptions(getOptions('notification_subscription_types_mechanical_care', 'Select', t('Select')));
    }
  } else {
    setReadOnlyType(true);
    setTypeOptions(getOptions('notification_subscription_empty_options'));
  }
}

function updateType(
  notification: NotificationSubscription,
  setReadOnlyMethod: any,
  setMethodOptions: any,
  setCCEmails: any,
  newValue: string,
  t: any,
) {
  notification.type = newValue;
  notification.method = [];
  if (newValue && newValue !== '' && newValue !== 'Select') {
    setReadOnlyMethod(false);
    setCCEmails('');
    if (
      notification.event === 'bulk_order' ||
      notification.event === 'urban_fleet_ordering' ||
      notification.event === 'mechanical_care'
    ) {
      setMethodOptions(getOptions('notification_subscription_methods_opt1'));
    } else {
      switch (newValue) {
        case 'dispatched':
          setMethodOptions(getOptions('notification_subscription_methods_opt2'));
          break;
        case 'eta_changes':
          setMethodOptions(getOptions('notification_subscription_methods_opt4'));
          break;
        case 'vehicle_rolling':
          setMethodOptions(getOptions('notification_subscription_methods_opt5'));
          break;
        case 'fleet_profile_changes':
        case 'new_profile_features':
          setMethodOptions(getOptions('notification_subscription_methods_opt1'));
          break;
        case 'enroute':
        case 'arrived':
        case 'closed':
        case 'cancelled':
        case 'dr_failed_auto_approval':
        case 'dr_available_for_approval':
        case 'case_summary':
          setMethodOptions(getOptions('notification_subscription_methods_opt2'));
          break;
        default:
          setMethodOptions([]);
          break;
      }
    }
  } else {
    setReadOnlyMethod(true);
    setMethodOptions([]);
    setCCEmails('');
  }
}

function updateMethod(
  notification: NotificationSubscription,
  setMethod: any,
  setCCEmails: any,
  methods: string[] | undefined,
) {
  notification.method = methods;
  if (methods && !methods.includes('email')) {
    // Clean CC Emails if there is no method related.
    setCCEmails('');
  }
  setMethod(methods);
}

function cancelChanges(
  notification: NotificationSubscription,
  originalNotification: NotificationSubscription,
  onClose: any,
) {
  notification.event = originalNotification.event;
  notification.type = originalNotification.type;
  notification.method = originalNotification.method;
  notification.cc_emails = originalNotification.cc_emails;
  onClose();
}

function saveChanges(
  notification: NotificationSubscription,
  ccEmails: string,
  onClose: any,
  isEdit: boolean,
  onCreateSubscription: any,
  dialogs: WithDialogsProps,
  contact: Contact,
  t: Function,
) {
  const { notification_subscription } = contact;
  if (!notification) return;

  if (!notification.event || notification.event === '' || notification.event === 'Select') {
    dialogs.alertDialog(t('You must select an Event.'), t('Event required'), t('Ok'), undefined);
    return;
  }
  if (!notification.type || notification.type === '' || notification.type === 'Select') {
    dialogs.alertDialog(t('You must select a Type.'), t('Type required'), t('Ok'), undefined);
    return;
  }
  if (!notification.method || notification.method.length < 1) {
    dialogs.alertDialog(t('You must select a Method'), t('Method required'), t('Ok'), undefined);
    return;
  }

  const combosSet = new Set<string>();
  if (notification_subscription) {
    notification_subscription.forEach((x) => {
      const { id, event, type, method } = x;

      if (method) {
        method.forEach((met) => {
          if (event && type && met) {
            combosSet.add(event + type + met);
            combosSet.add(id + event + type + met);
          }
        });
      }
    });
  }

  let isRepeated = false;

  if (notification.method) {
    notification.method.forEach((met) => {
      const { id, event, type } = notification;
      if (event && type && combosSet.has(event + type + met) && !combosSet.has(id + event + type + met)) {
        isRepeated = true;
      }
    });
  }

  if (isRepeated) {
    dialogs.alertDialog(
      t('The notification subscription created already exists.'),
      t('Repeated Notification Subscription'),
      t('Ok'),
      undefined,
    );
  } else {
    const emails: string[] = ccEmails.split(',');
    const result: string[] = [];
    for (let i = 0; i < emails.length; i++) {
      emails[i] = emails[i].replace(/^\s+|\s+$/g, '');
      if (!isValidEmail(emails[i])) {
        dialogs.alertDialog(
          t('Email {{email}} has invalid format.', { email: emails[i] }),
          t('Invalid format'),
          t('Ok'),
          undefined,
        );
        return;
      }
      if (emails[i] !== '') {
        result.push(emails[i]);
      }
    }

    notification.cc_emails = result;
    // if (!isEdit) {
    onCreateSubscription();
    // }
    onClose();
  }
}

interface Props {
  isEdit: boolean;
  open: boolean;
  contact: Contact;
  notificationSubscriptionSelected: NotificationSubscription;
  onClose: () => void;
  onCreateSubscription: () => void;
}

export default function NotificationSubscriptionsDialog(props: Props) {
  const {
    isEdit,
    onClose,
    open,
    notificationSubscriptionSelected: notification,
    onCreateSubscription,
    contact,
  } = props;

  const { email_address, cell_phone } = contact;

  const originalNotification: NotificationSubscription = JSON.parse(JSON.stringify(notification));
  const [ccEmails, setCCEmails] = useState((notification.cc_emails && notification.cc_emails.join(', ')) || '');

  const [readOnlyType, setReadOnlyType] = useState(
    !(isEdit && notification!.event && notification!.event !== '' && notification!.event !== 'Select'),
  );
  const [readOnlyMethod, setReadOnlyMethod] = useState(
    !(isEdit && notification!.type && notification!.type !== '' && notification!.type !== 'Select'),
  );

  const { t } = useTranslation();
  const isUrban = auth.isUrbanCustomer();
  const isCommercial = auth.isCommercialCustomer();
  const hasMechanicalContactTypes = contact?.contact_types?.some((ct) => ct.service === MECH_SERVICE_VALUE);
  const eventOptions = getOptions('notification_subscription_events', 'Select', t('Select')).filter((x) => {
    if (!isUrban && x.value === 'urban_fleet_ordering') {
      return false;
    }
    if (isUrban && !isCommercial && x.value === 'tire_ers') {
      return false;
    }
    if (isUrban && !isCommercial && x.value === 'bulk_order') {
      return false;
    }
    if (!hasMechanicalContactTypes && x.value === 'mechanical_care') {
      return false;
    }
    return true;
  });
  const dialogs = useDialogs();

  const [typeOptions, setTypeOptions] = useState(() => {
    if (!isEdit) {
      return getOptions('notification_subscription_empty_options', 'Select', t('Select'));
    }
    switch (notification.event) {
      case 'profile':
        return getOptions('notification_subscription_types_profile', 'Select', t('Select'));
      case 'tire_ers':
        return getOptions('notification_subscription_types_tire_ers', 'Select', t('Select'));
      case 'bulk_order':
        return getOptions('notification_subscription_types_bulk_order', 'Select', t('Select'));
      case 'urban_fleet_ordering':
        return getOptions('notification_subscription_types_urban_fleet_ordering', 'Select', t('Select'));
      case 'mechanical_care':
        return getOptions('notification_subscription_types_mechanical_care', 'Select', t('Select'));
      default:
        return getOptions('notification_subscription_empty_options', 'Select', t('Select'));
    }
  });

  // Unused var
  const [methods, setMethod] = useState(notification.method);

  const [methodOptions, setMethodOptions] = useState(() => {
    if (!isEdit) {
      return [];
    }
    if (
      notification.event === 'bulk_order' ||
      notification.event === 'urban_fleet_ordering' ||
      notification.event === 'mechanical_care'
    ) {
      return getOptions('notification_subscription_methods_opt1');
    }
    switch (notification.type) {
      case 'dispatched':
        return getOptions('notification_subscription_methods_opt2');
      case 'eta_changes':
        return getOptions('notification_subscription_methods_opt4');
      case 'vehicle_rolling':
        return getOptions('notification_subscription_methods_opt5');
      case 'fleet_profile_changes':
      case 'new_profile_features':
        return getOptions('notification_subscription_methods_opt1');
      case 'enroute':
      case 'arrived':
      case 'closed':
      case 'cancelled':
      case 'dr_failed_auto_approval':
      case 'dr_available_for_approval':
      case 'case_summary':
        return getOptions('notification_subscription_methods_opt2');
      default:
        return [];
    }
  });

  const filteredMethodOptions = methodOptions.filter((x) => {
    const { value } = x;
    return ((value === 'call_cell' || value === 'text') && cell_phone) || (value === 'email' && email_address);
  });

  const helperText: string = t('Enter emails separated by commas');

  return (
    <Dialog
      onClose={() => cancelChanges(notification, originalNotification, onClose)}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <ClosableDialogTitle onClose={() => cancelChanges(notification, originalNotification, onClose)}>
        {isEdit ? t('Edit Notification Subscription') : t('New Notification Subscription')}
      </ClosableDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: 7 }}>
            <Select
              style={{ width: '100%' }}
              id="notificaiton_subscription_event_select"
              value={eventOptions[getDefaultIndex(eventOptions, notification.event)].value}
              label={t('Event')}
              options={eventOptions}
              onChange={(eventChange) =>
                updateEvent(
                  notification,
                  setReadOnlyType,
                  setReadOnlyMethod,
                  setTypeOptions,
                  setMethodOptions,
                  setCCEmails,
                  eventChange.target.value as string,
                  t,
                )
              }
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 7 }}>
            <Select
              style={{ width: '100%' }}
              id="notificaiton_subscription_type_select"
              value={typeOptions[getDefaultIndex(typeOptions, notification.type)].value}
              label={t('Notification Type')}
              options={typeOptions}
              onChange={(eventChange) =>
                updateType(
                  notification,
                  setReadOnlyMethod,
                  setMethodOptions,
                  setCCEmails,
                  eventChange.target.value as string,
                  t,
                )
              }
              disabled={readOnlyType}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 7 }}>
            <Autocomplete
              id="notificaiton_subscription_method_select"
              multiple
              disableCloseOnSelect
              suggestions={filteredMethodOptions}
              label={t('Notification Method')}
              placeholder={t('Select methods')}
              values={notification.method}
              readOnly={readOnlyMethod}
              onChange={(values) => updateMethod(notification, setMethod, setCCEmails, values)}
              helperText={
                !(cell_phone && email_address)
                  ? 'Cell phone and email address may be required to have all options available.'
                  : ''
              }
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 7 }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1}>
                <Email />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  id="cc_emails"
                  label={t('CC Emails')}
                  multiline
                  defaultValue={ccEmails}
                  disabled={methods === undefined || (methods && !methods.includes('email'))}
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                  helperText={helperText}
                  value={ccEmails}
                  onInput={(e: ChangeEvent<HTMLInputElement>) => {
                    setCCEmails(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
            <Button
              id="notificaitonSubscriptionDialogSaveButton"
              size="small"
              style={{ margin: '8px', width: 'auto' }}
              onClick={() =>
                saveChanges(notification, ccEmails, onClose, isEdit, onCreateSubscription, dialogs, contact, t)
              }
            >
              {t('Save')}
            </Button>
            <Button
              id="notificaitonSubscriptionDialogCancelButton"
              size="small"
              color="danger"
              style={{ margin: '8px', width: 'auto' }}
              onClick={() => cancelChanges(notification, originalNotification, onClose)}
            >
              {t('Cancel')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
