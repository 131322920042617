/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { FormControlLabel, Grid } from '@material-ui/core';
import { Contacts } from '@material-ui/icons';
import { LocationAvatar, Select, TextField, ValueAvatar } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { ContactOVM } from '@michelin/fcp-view-models';
import { getOptions } from '@michelin/select-options-provider';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { RenderNameAndAccountInfo } from 'modules/Contacts/Components/Components';
import { Controller, useFormContext } from 'react-hook-form';
import { BillingProfileContextData, MethodOfSignedOrder } from '../types';
import { ReceiverContact } from './ReceiverContact';

export interface DrWorkOrderProps {
  editFlag: boolean;
  ers: boolean;
}

export function DrWorkOrder(props: DrWorkOrderProps): JSX.Element {
  const { editFlag, ers } = props;
  const { t } = useTranslation();
  const { location } = usePermissions();
  const { control, getValues, setValue, formState, watch, trigger } = useFormContext<BillingProfileContextData>();
  const { profile } = getValues();
  const purchaseProcedure = ers
    ? watch('profile.ers_purchasing_procedures')
    : watch('profile.onsite_purchasing_procedures');

  const options_method_to_receive_signed_order = getOptions(
    'method_to_receive_signed_order',
    'Select',
    t('Unassigned'),
  );

  const ppName = ers ? 'ers' : 'onsite';

  const [open, setOpen] = useState(false);

  const updateSignedOrder = (newValue: string, oldValue: string) => {
    if (
      !(
        (newValue === MethodOfSignedOrder.email || newValue === MethodOfSignedOrder.fax) &&
        (oldValue === MethodOfSignedOrder.email || oldValue === MethodOfSignedOrder.fax)
      ) ||
      (newValue === MethodOfSignedOrder.fax && !purchaseProcedure?.receiver_contact?.fax) ||
      (newValue === MethodOfSignedOrder.email && !purchaseProcedure?.receiver_contact?.email_address)
    ) {
      const aux = {
        ...purchaseProcedure,
        signed_order_receiver_location: undefined,
        signed_order_receiver_contact: undefined,
        signed_order_receiver_other: undefined,
        receiver_location: undefined,
        receiver_contact: undefined,
      };
      setValue(
        `profile.${ppName}_purchasing_procedures`,
        aux as unknown as BillingProfileContextData['profile'][
          | 'ers_purchasing_procedures'
          | 'onsite_purchasing_procedures'],
        { shouldDirty: true },
      );
    }
  };

  const isOtherOption = purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.other;

  const methodToReceive = purchaseProcedure?.method_to_receive_signed_order;

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 20 }}>
        <Grid item xs={12} md={4}>
          <Controller
            name={`profile.${ppName}_purchasing_procedures.method_to_receive_signed_order`}
            control={control}
            render={(item) => (
              <Select
                id={item.field.name}
                label={t('Method to Receive Signed DR/Work Order')}
                data-testid="methodToReceive"
                value={item.field.value || ''}
                readOnly={!editFlag}
                onChange={(e) => {
                  const newValue = e.target.value as string;
                  const oldValue = item.field.value as string;
                  updateSignedOrder(newValue, oldValue);
                  item.field.onChange(newValue);
                }}
                options={options_method_to_receive_signed_order}
                style={{ width: '100%' }}
              />
            )}
          />
        </Grid>
        {!isOtherOption &&
          (purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.email ||
            purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.fax ||
            purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.mail) && (
            <>
              <Grid item xs={12} md={4} style={{ paddingTop: 24 }}>
                <Controller
                  name={`profile.${ppName}_purchasing_procedures.signed_order_receiver_location`}
                  control={control}
                  rules={{
                    required: {
                      value:
                        purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.mail ? true : false,
                      message: t('Location required'),
                    },
                    validate: {
                      value: () =>
                        purchaseProcedure?.method_to_receive_signed_order !== MethodOfSignedOrder.mail ||
                        !!getValues().profile[`${ppName}_purchasing_procedures`]?.receiver_location ||
                        t('Location required').toString(),
                    },
                  }}
                  render={(item) => (
                    <>
                      {purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.mail && (
                        <FormControlLabel
                          value=""
                          control={
                            <>
                              <NewLocationAssignments
                                owner={location?.customer_number || ''}
                                mode={editFlag ? 'edit' : 'view'}
                                locations={
                                  profile[`${ppName}_purchasing_procedures`]?.receiver_location?.hash_key
                                    ? [
                                        {
                                          hash_key:
                                            profile[`${ppName}_purchasing_procedures`]?.receiver_location?.hash_key ||
                                            '',
                                        },
                                      ]
                                    : []
                                }
                                onUpdate={(newAssignment) => {
                                  if (!newAssignment || !newAssignment.length) return;
                                  const na = newAssignment[0];
                                  setValue(
                                    `profile.${ppName}_purchasing_procedures.signed_order_receiver_location`,
                                    na.hash_key,
                                  );
                                  const receiver_location = {
                                    customer_name: na.customer_name,
                                    customer_city: na.customer_city,
                                    customer_state: na.customer_state,
                                    customer_addr1: na.customer_addr1 || '',
                                    customer_addr2: na.customer_addr2 || '',
                                    customer_zip: na.customer_zip,
                                    customer_type: na.customer_type,
                                    parent_company_number: na.parent_company_number || '',
                                    home_office_number: na.home_office_number || '',
                                    bill_to_customer: na.bill_to_customer || '',
                                    ship_to_customer: na.ship_to_customer || '',
                                    customer_number: na.customer_number || '',
                                    hash_key: na.hash_key,
                                  };
                                  setValue(
                                    `profile.${ppName}_purchasing_procedures.receiver_location`,
                                    receiver_location,
                                  );
                                  trigger(`profile.${ppName}_purchasing_procedures.signed_order_receiver_location`);
                                }}
                                plainView
                                disabled={!editFlag}
                                hideCount
                                singleSelection
                                style={{ margin: '0 0 0 auto' }}
                                all={false}
                                error={
                                  !!formState.errors.profile?.[`${ppName}_purchasing_procedures`]
                                    ?.signed_order_receiver_location
                                }
                                displayCurrentLocation
                              />
                            </>
                          }
                          label={t('Receiver of Signed DR/Work Order*')}
                          labelPlacement="start"
                          style={{ margin: 0 }}
                        />
                      )}
                    </>
                  )}
                />

                <Controller
                  name={`profile.${ppName}_purchasing_procedures.signed_order_receiver_contact`}
                  control={control}
                  rules={{
                    required: {
                      value:
                        purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.email ||
                        purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.fax
                          ? true
                          : false,
                      message: t('Contact required'),
                    },
                    validate: {
                      value: () => {
                        const method = purchaseProcedure?.method_to_receive_signed_order;
                        const contact = getValues().profile[`${ppName}_purchasing_procedures`]?.receiver_contact;
                        return (
                          (method !== MethodOfSignedOrder.email && method !== MethodOfSignedOrder.fax) ||
                          !!contact ||
                          t('Contact required').toString()
                        );
                      },
                    },
                  }}
                  render={(item) => (
                    <>
                      {(purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.email ||
                        purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.fax) && (
                        <FormControlLabel
                          value=""
                          control={
                            <>
                              <ReceiverContact
                                contactAssigned={purchaseProcedure?.receiver_contact}
                                setContact={(contact: ContactOVM | undefined) => {
                                  if (!contact) return;
                                  item.field.onChange(contact?.hash_key);
                                  const receiver_contact = {
                                    email_address: contact.email_address || '',
                                    fax: contact.fax || '',
                                    first_name: contact.first_name || '',
                                    last_name: contact.last_name || '',
                                    hash_key: `2~${contact.id}`,
                                    id: contact.id,
                                  };
                                  setValue(
                                    `profile.${ppName}_purchasing_procedures.receiver_contact`,
                                    receiver_contact,
                                  );
                                  setValue(
                                    `profile.${ppName}_purchasing_procedures.signed_order_receiver_contact`,
                                    receiver_contact.hash_key,
                                  );
                                  setOpen(false);
                                  trigger(`profile.${ppName}_purchasing_procedures.signed_order_receiver_contact`);
                                }}
                                onCloseHandler={() => {
                                  setOpen(false);
                                }}
                                open={open}
                                editFlag={editFlag}
                                type={purchaseProcedure?.method_to_receive_signed_order as 'email_address' | 'fax'}
                              />
                              <ValueAvatar
                                color={
                                  !!formState.errors.profile?.[`${ppName}_purchasing_procedures`]
                                    ?.signed_order_receiver_contact
                                    ? 'danger'
                                    : 'primary'
                                }
                                value=""
                                onClick={() => {
                                  setOpen(true);
                                }}
                                icon={<Contacts />}
                                tooltip={t('Receiver')}
                                disabled={!editFlag}
                              />
                            </>
                          }
                          label={t('Receiver of Signed DR/Work Order*')}
                          labelPlacement="start"
                          style={{ margin: 0 }}
                        />
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ paddingTop: 16 }}>
                {methodToReceive === MethodOfSignedOrder.fax || methodToReceive === MethodOfSignedOrder.email ? (
                  <RenderNameAndAccountInfo
                    row={profile?.[`${ppName}_purchasing_procedures`]?.receiver_contact as any}
                    contactMethod={methodToReceive}
                  />
                ) : (
                  purchaseProcedure?.receiver_location && (
                    <LocationAvatar
                      accountType={purchaseProcedure?.receiver_location?.customer_type as any}
                      name={purchaseProcedure?.receiver_location?.customer_name || ''}
                      accountNumbers={{
                        parentCompany: purchaseProcedure?.receiver_location?.parent_company_number,
                        homeOffice: purchaseProcedure?.receiver_location?.home_office_number,
                        billTo: purchaseProcedure?.receiver_location?.bill_to_customer,
                        shipTo: purchaseProcedure?.receiver_location?.ship_to_customer,
                      }}
                      address={{
                        city: purchaseProcedure?.receiver_location?.customer_city,
                        state: purchaseProcedure?.receiver_location?.customer_state,
                        address1: purchaseProcedure?.receiver_location?.customer_addr1,
                        address2: purchaseProcedure?.receiver_location?.customer_addr2,
                        zip: purchaseProcedure?.receiver_location?.customer_zip,
                      }}
                    />
                  )
                )}
              </Grid>
            </>
          )}

        <Controller
          name={`profile.${ppName}_purchasing_procedures.signed_order_receiver_other`}
          control={control}
          rules={{
            required: {
              value: purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.other ? true : false,
              message: t('Other method description is required'),
            },
          }}
          render={(item) => (
            <>
              {isOtherOption && (
                <Grid item xs={12} md={4}>
                  <TextField
                    id={item.field.name}
                    onChange={(e) => {
                      item.field.onChange(e.target.value);
                    }}
                    label={t('Other')}
                    value={item.field.value || ''}
                    readOnly={!editFlag}
                    error={!!formState.errors.profile?.[`${ppName}_purchasing_procedures`]?.signed_order_receiver_other}
                    helperText={
                      formState.errors.profile?.[`${ppName}_purchasing_procedures`]?.signed_order_receiver_other
                        ?.message
                    }
                    required
                  />
                </Grid>
              )}
            </>
          )}
        />
      </Grid>
    </>
  );
}
