/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button, Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { BillingWheelRequestedInstalledDetails } from '@michelin/fcp-view-models';
import { useFormContext } from 'react-hook-form';
import { WheelList } from '../Components/Wheels';
import { BillingProfileContextData, BillingWheelOffInstalled } from '../types';

export interface RequestedWheelsDetailProps {
  action: string;
}

export function RequestedWheelsDetail(props: RequestedWheelsDetailProps) {
  const { t } = useTranslation();
  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';
  const [clickedAdd, setClickedAdd] = useState(false);
  const { getValues } = useFormContext<BillingProfileContextData>();
  const title = t('Requested Off/Installed Wheel Details');
  let isOffAssigned = false;
  let isInstalledAssigned = false;
  let noRequestedDetails = false;
  const { profile } = getValues();
  if (profile.wheel_details) {
    profile.wheel_details.forEach((wheelDetail: BillingWheelRequestedInstalledDetails) => {
      if (wheelDetail.off_installed === BillingWheelOffInstalled.OFF_WHEELS) {
        isOffAssigned = true;
      } else {
        isInstalledAssigned = true;
      }
      if (!wheelDetail.requested_details || wheelDetail.requested_details.length === 0) {
        noRequestedDetails = true;
      }
    });
  }

  return (
    <Panel
      title={title}
      subtitle={
        <span style={{ fontSize: '0.8em' }}>
          <Alert severity="warning">
            {t(
              'Michelin does not guarantee the applicable dealer from its independent dealer network will provide these Requested Details.',
            )}
          </Alert>
        </span>
      }
      style={{ marginBottom: 15 }}
      control={
        !editFlag || (isOffAssigned && isInstalledAssigned) ? (
          <></>
        ) : (
          <Button
            color="default"
            variant="default"
            onClick={() => setClickedAdd(true)}
            disabled={(isOffAssigned || isInstalledAssigned) && noRequestedDetails}
          >
            {t('Add')}
          </Button>
        )
      }
      id="requestedOffInstalled"
    >
      <Grid container>
        <Grid container spacing={2}>
          <WheelList
            editFlag={editFlag}
            bothAssigned={isOffAssigned && isInstalledAssigned}
            clickedAdd={clickedAdd}
            setClickedAdd={setClickedAdd}
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
