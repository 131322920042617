import React, { useEffect, useState } from 'react';
import { Button, LoadingBounce, Message } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { LocationOVM } from '@michelin/fcp-view-models';
import { ContactControlDelegator, ContactDetails } from 'components/Contact/ContactDetails';
import {
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  TIRE_SERVICE_VALUE,
} from 'components/Contact/ContactDetails/utils';
import { Contact, ContactType, ContactTypeRole } from 'components/Contact/utils';
import uuid from 'uuid';
import { BillingReassignContactType } from './MissingCriticalRolesDialog';
import { getContactTypesFromContacts, getCustomerNumbersFromRelationship, loadContacts } from './utils';

interface ContactTypesBuildedOptions {
  newContactTypes: Array<ContactType>;
  newMandatoryContactTypes: Array<ContactType>;
  alertUserPrimaryNecessary: boolean;
}

function buildContactTypes(
  currentLocation: LocationOVM,
  contactTypes: Array<string>,
  currentlyAssignedContactTypes: Map<string, BillingReassignContactType>,
  contactTypeLevels: Array<string>,
  contacts: Array<
    Pick<
      Contact,
      | 'hash_key'
      | 'first_name'
      | 'last_name'
      | 'email_address'
      | 'work_phone'
      | 'cell_phone'
      | 'preferred_method'
      | 'contact_types'
      | 'contact_level'
    >
  >,
): ContactTypesBuildedOptions {
  const contactTypesFromContacts = getContactTypesFromContacts(currentLocation, contacts);
  const newContactTypes: Array<ContactType> = [];
  const newMandatoryContactTypes: Array<ContactType> = [];
  let alertUserPrimaryNecessary = false;

  if (contactTypeLevels.length > 1) {
    // Primary and Secondary
    // TBD.
  } else if (contactTypeLevels.length === 1) {
    const level = contactTypeLevels[0];
    // Primary or Secondary

    const role_areas: Array<ContactTypeRole> = [];
    const mandatory_role_areas: Array<ContactTypeRole> = [];
    contactTypes.forEach((roleArea) => {
      if (
        !currentlyAssignedContactTypes.has(`${currentLocation.customer_number}~${level}~${roleArea}`) &&
        !contactTypesFromContacts.has(`${roleArea}~${level}`)
      ) {
        if (level === SECONDARY_LEVEL_VALUE) {
          role_areas.push({
            role_area: roleArea,
            levels: [
              {
                level,
                location: currentLocation.hash_key || '',
              },
            ],
          });

          if (!contactTypesFromContacts.has(`${roleArea}~${PRIMARY_LEVEL_VALUE}`)) {
            // If there is no primary one needs to be assigned.
            mandatory_role_areas.push({
              role_area: roleArea,
              levels: [
                {
                  level: PRIMARY_LEVEL_VALUE,
                  location: currentLocation.hash_key || '',
                },
              ],
            });
            // Alert the user.
            alertUserPrimaryNecessary = true;
          }
        } else {
          role_areas.push({
            role_area: roleArea,
            levels: [
              {
                level,
                location: currentLocation.hash_key || '',
              },
            ],
          });
        }
      }
    });

    newContactTypes.push({
      id: uuid.v4(),
      role_areas,
      service: TIRE_SERVICE_VALUE,
    });

    if (mandatory_role_areas.length > 0) {
      newMandatoryContactTypes.push({
        id: uuid.v4(),
        role_areas: mandatory_role_areas,
        service: TIRE_SERVICE_VALUE,
      });
    }
  }

  return {
    newContactTypes,
    alertUserPrimaryNecessary,
    newMandatoryContactTypes,
  };
}

interface Props {
  contactTypes: Array<string>;
  contactTypeLevels: Array<string>;
  currentAssignedContactTypes: Map<string, BillingReassignContactType>;
  location: LocationOVM;
  onAfterSaveHandler: (contact: Contact) => void;
  onAfterBackHandler: () => void;
  delegateControl: ContactControlDelegator;
}

interface State {
  loading: boolean;
  error: any;
  contactTypesBuilded: ContactTypesBuildedOptions | undefined;
}

export function AddContact(props: Props): JSX.Element {
  const { currentAssignedContactTypes, location, onAfterBackHandler, onAfterSaveHandler, delegateControl } = props;
  const [{ loading, error, contactTypesBuilded }, setState] = useState<State>({
    loading: true,
    error: undefined,
    contactTypesBuilded: undefined,
  });
  const { t } = useTranslation();

  const parentNumbers = getCustomerNumbersFromRelationship(location.relationship || '');
  const parentNumbersCurrentLocation = {
    ship_to_customer: parentNumbers.ship_to_customer ? parentNumbers.ship_to_customer : '',
    home_office_number: parentNumbers.home_office_number ? parentNumbers.home_office_number : '',
    parent_company_number: parentNumbers.parent_company_number ? parentNumbers.parent_company_number : '',
    bill_to_customer: parentNumbers.bill_to_customer ? parentNumbers.bill_to_customer : '',
    hash_key: location.hash_key || '',
    range_key: 'v0_customer',
    customer_name: location.customer_name || '',
    customer_addr1: location.customer_addr1 || '',
    customer_addr2: location.customer_addr2 || '',
    customer_city: location.customer_city || '',
    customer_state: location.customer_state || '',
    customer_zip: location.customer_zip || '',
    customer_number: location.customer_number || '',
    relationship: location.relationship || '',
    customer_type: location.customer_type || '',
    extrnl_cust_id: location.extrnl_cust_id || '',
  };

  /* RETRIEVE CONTACTS IF A LOCATION HAS BEEN SELECTED */
  useEffect(() => {
    let isCancelled = false;
    setState({
      loading: true,
      error: undefined,
      contactTypesBuilded: undefined,
    });

    if (location) {
      const asyncEffect = async () => {
        // AVOID MULTIPLE QUERIES ON USER CLICKING NEXT MULTIPLE TIMES FAST
        setTimeout(async () => {
          if (isCancelled === false) {
            const contacts = await loadContacts(location);

            if (isCancelled === false) {
              const builtContactTypes = buildContactTypes(
                location,
                props.contactTypes,
                currentAssignedContactTypes,
                props.contactTypeLevels,
                contacts,
              );

              setState({
                contactTypesBuilded: builtContactTypes,
                loading: false,
                error: undefined,
              });
            }
          }
        }, 1500);
      };

      asyncEffect();
    }

    // AVOID RACE-CONDITION ON MULTIPLES QUERIES
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (loading) return <LoadingBounce style={{ height: '70vh' }} />;
  if (contactTypesBuilded) {
    if (contactTypesBuilded.alertUserPrimaryNecessary) {
      const onClickContinueHandler = () => {
        setState({
          contactTypesBuilded: {
            ...contactTypesBuilded,
            alertUserPrimaryNecessary: false,
          },
          error,
          loading,
        });
      };

      const necessaryContactTypes = contactTypesBuilded.newMandatoryContactTypes
        .map((mandatoryContactTypes) => {
          if (!mandatoryContactTypes.role_areas) return null;
          return mandatoryContactTypes.role_areas
            .map((mandatoryRoleArea) => `Primary Tire ${mandatoryRoleArea.role_area}`)
            .join(', ');
        })
        .join(', ');

      const nextContactTypes = contactTypesBuilded.newContactTypes
        .map((mandatoryContactTypes) => {
          if (!mandatoryContactTypes.role_areas) return null;
          return mandatoryContactTypes.role_areas
            .map((mandatoryRoleArea) =>
              mandatoryRoleArea.levels
                .map((contactTypeLevel) => `${contactTypeLevel.level} Tire ${mandatoryRoleArea.role_area}`)
                .join(', '),
            )
            .join(', ');
        })
        .join(', ');

      return (
        <div style={{ textAlign: 'center' }}>
          <p>
            {t('The next primary contacts are needed to be assigned before assigning some secondary contact types:')}
            <b>{necessaryContactTypes}</b>
          </p>
          <p>{t('By pressing Ok you will create a contact with these contact types.')}</p>
          <p>
            <i>
              {t(
                'After creating this contact you will have the chance to create another one with the next contact types:',
              )}
              <b>{nextContactTypes}</b>
            </i>
          </p>
          <Button variant="contained" onClick={onClickContinueHandler}>
            OK
          </Button>
        </div>
      );
    }
    return (
      <ContactDetails
        action="create"
        avoidFirstTimeAssign
        contactTypes={
          contactTypesBuilded.newMandatoryContactTypes.length > 0
            ? contactTypesBuilded.newMandatoryContactTypes
            : contactTypesBuilded.newContactTypes
        }
        disablePriorityLevels
        disableAssignLocations
        disableContactLevel
        delegateControl={delegateControl}
        locations={[parentNumbersCurrentLocation]}
        onAfterSaveHandler={onAfterSaveHandler}
        onAfterBackHandler={onAfterBackHandler}
        hideHeader
        blockReassign
        noRedirectionAfterSaving={true}
      />
    );
  }
  return (
    <Message message={t('There was an error processing your request. Please try again later.')} title={t('Error')} />
  );
}
