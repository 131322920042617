import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { BillingProfileContextData, BillingProfileTypes } from '../types';
import { isHierarchySameOrLower } from 'utils/reusableMethods';
import { ContactOVM } from '@michelin/fcp-view-models';

interface DialogInterface {
  openDialog: boolean;
  closeDialog: () => void;
}

export const DialogAddContactLimitsApprovals = (props: DialogInterface) => {
  const { getValues, setValue } = useFormContext<BillingProfileContextData>();
  const { preApprovalsContacts, contactsWithLimitsEdited, profile } = getValues();
  const { t } = useTranslation();
  const {location} = usePermissions()
  const noContactAvailableMessage = t('No records to display.');

  return (
    <Dialog
      open={props.openDialog}
      onClose={() => {
        props.closeDialog();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{t('All Contacts')}</DialogTitle>
      <DialogContent>
        <DataGrid
          noDataText={noContactAvailableMessage}
          showBorders
          style={{ maxHeight: '20rem' }}
          dataSource={preApprovalsContacts.filter((c) => {
            if (c === null || typeof c === 'undefined') return false;
            if (!profile) return false;
            const inContactsWithLimitsEdited = contactsWithLimitsEdited.find((s) => s.id === c.id);
            if (inContactsWithLimitsEdited) {
              return false;
            }
            if (
              profile.general_information?.profile_type === BillingProfileTypes.ers ||
              profile.general_information?.profile_type === BillingProfileTypes.ers_onsite
            ) {
              if (
                (c.num_of_tires &&
                  c.num_of_tires > (profile?.approvals?.ers_pre_approvals_limits?.maximum_num_of_tires ?? 0)) ||
                (c.num_of_wheels &&
                  c.num_of_wheels > (profile?.approvals?.ers_pre_approvals_limits?.maxium_num_of_wheels ?? 0))
              ) {
                return false;
              }
            }

            if (
              profile?.general_information?.profile_type === BillingProfileTypes.onsite ||
              profile?.general_information?.profile_type === BillingProfileTypes.ers_onsite
            ) {
              if (
                (c.num_of_tires &&
                  c.num_of_tires > (profile?.approvals?.onsite_pre_approvals_limits?.maximum_num_of_tires || 0)) ||
                (c.num_of_wheels &&
                  c.num_of_wheels > (profile?.approvals?.onsite_pre_approvals_limits?.maxium_num_of_wheels || 0)) ||
                (c.tire_dollar_amount &&
                  c.tire_dollar_amount > (profile?.approvals?.onsite_pre_approvals_limits?.maximum_dollar_amount || 0))
              ) {
                return false;
              }
            }
            return true;
          }) as ContactOVM[]}
        >
          <Scrolling mode="virtual" />
          <Column
            caption={t('Full Name')}
            allowFiltering={false}
            cellRender={(row: any) => (row.data.first_name ? `${row.data.first_name} ${row.data.last_name}` : '-')}
          />
          <Column
            caption={t('Tires')}
            allowFiltering={false}
            cellRender={(row: any) => (row.data.num_of_tires ? row.data.num_of_tires : '-')}
          />
          <Column
            caption={t('Wheels')}
            allowFiltering={false}
            cellRender={(row: any) => (row.data.num_of_wheels ? row.data.num_of_wheels : '-')}
          />
          <Column
            caption={t('Max Dollars for Tire Service')}
            allowFiltering={false}
            cellRender={(row: any) => (row.data.tire_dollar_amount ? `$ ${row.data.tire_dollar_amount}` : '-')}
          />
          <Column
            caption={t('Work Phone')}
            allowFiltering={false}
            cellRender={(row: any) => (row.data.work_phone ? row.data.work_phone : '-')}
          />
          <Column
            caption={t('Cell Phone')}
            allowFiltering={false}
            cellRender={(row: any) => (row.data.cell_phone ? row.data.cell_phone : '-')}
          />
          <Column
            caption={t('Email Address')}
            allowFiltering={false}
            cellRender={(row: any) => (row.data.email_address ? row.data.email_address : '-')}
          />
          <Column
            allowFiltering={false}
            cellRender={({row}:{row:{data:ContactOVM}}) => (
              <Button
                color={'default'}
                variant={'default'}
                onClick={() => {
                  const auxContacts = cloneDeep(contactsWithLimitsEdited);
                  const contact = preApprovalsContacts.find((contact) => contact.id === row.data.id);
                  if (contact) {
                    auxContacts.push({ ...contact, addedForEdit: true });
                  }
                  setValue('contactsWithLimitsEdited', auxContacts, { shouldDirty: true });
                  props.closeDialog();
                }}
                disabled={!isHierarchySameOrLower(row.data.contact_level || '', location?.customer_type || '')}
              >
                {t('Add')}
              </Button>
            )}
          />
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.closeDialog();
          }}
          color="default"
          variant="default"
        >
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
