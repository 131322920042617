import React, { useState } from 'react';
import { Grid, InputAdornment, Link } from '@material-ui/core';
import { Button, LoadingBounce, Panel, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { isInContacsWithLimitsEdited } from 'modules/Billing/utils';
import { getBaseUrl } from 'prefs-and-service-offers';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { DialogAddContactLimitsApprovals } from '../Components/DialogAddContactLimitsApprovals';
import { ActionsCell } from '../Components/DialogEditLimits';
import { BillingProfileContextData, BillingProfileTypes, BillingServiceTypes } from '../types';

export interface IPreApprovalLimitsComponent {
  action: string;
}

export function PreApprovalLimits(props: IPreApprovalLimitsComponent) {
  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';
  const { getValues, control, watch } = useFormContext<BillingProfileContextData>();
  const { t } = useTranslation();
  const history = useHistory();

  const { profile } = getValues();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ers_max_tires = watch('profile.approvals.ers_pre_approvals_limits.maximum_num_of_tires');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ers_max_wheels = watch('profile.approvals.ers_pre_approvals_limits.maxium_num_of_wheels');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onsite_max_tires = watch('profile.approvals.onsite_pre_approvals_limits.maximum_num_of_tires');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onsite_max_wheels = watch('profile.approvals.onsite_pre_approvals_limits.maxium_num_of_wheels');
  const onsite_max_dollars = watch('profile.approvals.onsite_pre_approvals_limits.maximum_dollar_amount');
  const contactsWithLimitsEdited = watch('contactsWithLimitsEdited');
  const watchPreApprovalsContacts = watch('preApprovalsContacts');
  const watchIsLoggedLocationAndChildrenLoading = watch('isLoggedLocationAndChildrenLoading');
  const watchIsBillingRequirementsChecksLoading = watch('isBillingRequirementsChecksLoading');

  const noContactAvailableMessage = t('No records to display.');

  const [openDialog, setOpenDialog] = useState(false);

  const handleRowPrepared = (e: any) => {
    if (e.rowType === 'data') {
      if (e.data.addedForEdit) {
        e.rowElement.style.backgroundColor = '#efeeee';
      }
    }
  };
  return (
    <Panel title={t('Pre Approval Limits')} style={{ marginBottom: 15 }}>
      <Grid style={{ width: '100%' }}>
        <Grid container spacing={2} style={{ paddingTop: 5 }}>
          {profile?.general_information?.profile_type !== BillingProfileTypes.onsite && (
            <Grid item xs={12}>
              <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                <Grid item xs={12} md={2}>
                  <div style={{ paddingTop: 15, paddingLeft: 5 }}>{t('ERS Service')}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="profile.approvals.ers_pre_approvals_limits.maximum_num_of_tires"
                    control={control}
                    rules={{
                      required: { value: true, message: t('Maximum # of Tires') },
                    }}
                    render={(item) => (
                      <TextField
                        id={item.field.name}
                        name="ersMaximumNumOfTires"
                        type="number"
                        required
                        label={t('Maximum # of Tires')}
                        InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                        variant="standard"
                        value={item.field.value || 0}
                        error={
                          !!item.formState.errors.profile?.approvals?.ers_pre_approvals_limits?.maximum_num_of_tires
                        }
                        onChange={(e) => {
                          const value = parseInt(e.target.value, 10);
                          item.field.onChange(isNaN(value) ? 0 : value);
                        }}
                        helperText={
                          !!item.formState.errors.profile?.approvals?.ers_pre_approvals_limits?.maximum_num_of_tires
                            ? t('Maximum # of Tires is required').toString()
                            : ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="profile.approvals.ers_pre_approvals_limits.maxium_num_of_wheels"
                    control={control}
                    rules={{
                      required: { value: true, message: t('Maximum # of Wheeels') },
                    }}
                    render={(item) => (
                      <TextField
                        id={item.field.name}
                        name="ersMaximumNumOfWheels"
                        type="number"
                        required
                        label={t('Maximum # of Wheels')}
                        InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                        variant="standard"
                        value={item.field.value || 0}
                        error={
                          !!item.formState.errors.profile?.approvals?.ers_pre_approvals_limits?.maxium_num_of_wheels
                        }
                        onChange={(e) => {
                          const value = parseInt(e.target.value, 10);
                          item.field.onChange(isNaN(value) ? 0 : value);
                        }}
                        helperText={
                          !!item.formState.errors.profile?.approvals?.ers_pre_approvals_limits?.maxium_num_of_wheels
                            ? t('Maximum # of Wheels is required').toString()
                            : ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} />
              </Grid>
            </Grid>
          )}
          {profile?.general_information?.profile_type !== BillingProfileTypes.ers && (
            <Grid item xs={12} md={12}>
              <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                <Grid item xs={12} md={2}>
                  <div style={{ paddingTop: 15, paddingLeft: 5 }}>{t('On-site Service')}</div>
                </Grid>
                {profile?.general_information?.service_type !== BillingServiceTypes.MECHANICAL && (
                  <>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="profile.approvals.onsite_pre_approvals_limits.maximum_num_of_tires"
                        control={control}
                        rules={{
                          required: { value: true, message: t('Maximum # of Tires') },
                        }}
                        render={(item) => (
                          <TextField
                            id={item.field.name}
                            name="onsiteMaximumNumOfTires"
                            type="number"
                            required
                            label={t('Maximum # of Tires')}
                            InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                            variant="standard"
                            value={item.field.value || 0}
                            error={
                              !!item.formState.errors.profile?.approvals?.onsite_pre_approvals_limits
                                ?.maximum_num_of_tires
                            }
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              item.field.onChange(isNaN(value) ? 0 : value);
                            }}
                            helperText={
                              !!item.formState.errors.profile?.approvals?.onsite_pre_approvals_limits
                                ?.maximum_num_of_tires
                                ? t('Maximum # of Tires is required').toString()
                                : ''
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="profile.approvals.onsite_pre_approvals_limits.maxium_num_of_wheels"
                        control={control}
                        rules={{
                          required: { value: true, message: t('Maximum # of Wheeels') },
                        }}
                        render={(item) => (
                          <TextField
                            id={item.field.name}
                            name="onsiteMaximumNumOfWheels"
                            type="number"
                            required
                            label={t('Maximum # of Wheels')}
                            InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                            variant="standard"
                            value={item.field.value || 0}
                            error={
                              !!item.formState.errors.profile?.approvals?.onsite_pre_approvals_limits
                                ?.maxium_num_of_wheels
                            }
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              item.field.onChange(isNaN(value) ? 0 : value);
                            }}
                            helperText={
                              !!item.formState.errors.profile?.approvals?.onsite_pre_approvals_limits
                                ?.maxium_num_of_wheels
                                ? t('Maximum # of Wheels is required').toString()
                                : ''
                            }
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={3}>
                  <Controller
                    name="profile.approvals.onsite_pre_approvals_limits.maximum_dollar_amount"
                    control={control}
                    rules={{
                      required: { value: true, message: t('Maximum Dollar Amount') },
                    }}
                    render={(item) => (
                      <TextField
                        id={item.field.name}
                        name="onsiteMaximumDollarAmount"
                        type="number"
                        required
                        label={t('Maximum Dollar Amount')}
                        InputProps={{
                          inputProps: { min: 0 },
                          readOnly: !editFlag,
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        variant="standard"
                        value={onsite_max_dollars || 0}
                        error={
                          !!item.formState.errors.profile?.approvals?.onsite_pre_approvals_limits?.maximum_dollar_amount
                        }
                        onChange={(e) => {
                          const value = parseInt(e.target.value, 10);
                          item.field.onChange(isNaN(value) ? 0 : value);
                        }}
                        helperText={
                          !!item.formState.errors.profile?.approvals?.onsite_pre_approvals_limits?.maximum_dollar_amount
                            ? t('Maximum Dollar Amount is required').toString()
                            : ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={1} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Panel
          title={
            profile && profile?.general_information?.service_type === BillingServiceTypes.MECHANICAL
              ? t('Authorized Approvers of Mechanical Services')
              : t('Authorized Tire and Wheel Approvers')
          }
          subtitle={t('above Pre-Approval Limits')}
          control={
            !editFlag || watchIsLoggedLocationAndChildrenLoading ? (
              <></>
            ) : (
              <Button
                color="primary"
                size="small"
                style={{ marginTop: 4 }}
                onClick={() => {
                  setOpenDialog(true);
                }}
                disabled={watchIsBillingRequirementsChecksLoading}
              >
                {t('Add')}
              </Button>
            )
          }
        >
          {watchIsBillingRequirementsChecksLoading ? (
            <LoadingBounce style={{ height: 300 }} />
          ) : (
            <DataGrid
              showBorders
              className="freespaced-table"
              noDataText={noContactAvailableMessage}
              dataSource={
                watchIsLoggedLocationAndChildrenLoading
                  ? []
                  : contactsWithLimitsEdited.concat(
                      watchPreApprovalsContacts.filter((row) => {
                        if (typeof row === 'undefined' || row === null) return false;
                        if (isInContacsWithLimitsEdited(row.id, contactsWithLimitsEdited)) {
                          return false;
                        }
                        if (
                          profile?.general_information?.profile_type === BillingProfileTypes.ers ||
                          profile?.general_information?.profile_type === BillingProfileTypes.ers_onsite
                        ) {
                          if (
                            (row.num_of_tires &&
                              row.num_of_tires >
                                (profile?.approvals?.ers_pre_approvals_limits?.maximum_num_of_tires || 0)) ||
                            (row.num_of_wheels &&
                              row.num_of_wheels >
                                (profile?.approvals?.ers_pre_approvals_limits?.maxium_num_of_wheels || 0))
                          ) {
                            return true;
                          }
                        }
                        if (
                          profile?.general_information?.profile_type === BillingProfileTypes.onsite ||
                          profile?.general_information?.profile_type === BillingProfileTypes.ers_onsite
                        ) {
                          if (
                            (row.num_of_tires &&
                              row.num_of_tires >
                                (profile?.approvals?.onsite_pre_approvals_limits?.maximum_num_of_tires || 0)) ||
                            (row.num_of_wheels &&
                              row.num_of_wheels >
                                (profile?.approvals?.onsite_pre_approvals_limits?.maxium_num_of_wheels || 0)) ||
                            (row.tire_dollar_amount &&
                              row.tire_dollar_amount >
                                (profile?.approvals?.onsite_pre_approvals_limits?.maximum_dollar_amount || 0))
                          ) {
                            return true;
                          }
                        }
                        return false;
                      }),
                    )
              }
              style={{ maxHeight: 440 }}
              showColumnHeaders
              scrolling={{ mode: 'virtual' }}
              onRowPrepared={handleRowPrepared}
            >
              <Column
                caption={t('Full Name')}
                allowFiltering={false}
                cellRender={({ data: row }: any) => {
                  return editFlag ? (
                    <span
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      <b>
                        {row.first_name} {row.last_name}
                      </b>
                    </span>
                  ) : (
                    <Link
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 700,
                        cursor: 'pointer',
                      }}
                      onClick={() => history.push(`${getBaseUrl()}/contacts/${row.id}/view`)}
                    >
                      <b>
                        {row.first_name} {row.last_name}
                      </b>
                    </Link>
                  );
                }}
              />
              <Column
                caption={t('Tires')}
                allowFiltering={false}
                cellRender={({ data: row }: any) => row.num_of_tires}
              />
              <Column
                caption={t('Wheels')}
                allowFiltering={false}
                cellRender={({ data: row }: any) => row.num_of_wheels}
              />
              <Column
                visible={profile?.general_information?.profile_type !== BillingProfileTypes.ers}
                caption={t('Max Dollars for Tire Service')}
                allowFiltering={false}
                cellRender={({ data: row }: any) =>
                  row.tire_dollar_amount != null ? `$ ${row.tire_dollar_amount}` : ''
                }
              />
              <Column
                caption={t('Work Phone')}
                allowFiltering={false}
                cellRender={(row: any) => (row.data.work_phone ? row.data.work_phone : '-')}
              />
              <Column
                caption={t('Cell Phone')}
                allowFiltering={false}
                cellRender={(row: any) => (row.data.cell_phone ? row.data.cell_phone : '-')}
              />
              <Column
                caption={t('Email Address')}
                allowFiltering={false}
                cellRender={(row: any) => (row.data.email_address ? row.data.email_address : '-')}
              />
              <Column
                caption=""
                allowFiltering={false}
                cellRender={({ data: row, rowIndex }: any) =>
                  row.addedForEdit && editFlag ? (
                    <ActionsCell
                      row={row}
                      rowIndex={rowIndex}
                      disableEditTireWheel={
                        profile?.general_information?.service_type === BillingServiceTypes.MECHANICAL
                      }
                      disableEditAmount={profile?.general_information?.profile_type === BillingProfileTypes.ers}
                    />
                  ) : null
                }
              />
            </DataGrid>
          )}
        </Panel>
      </Grid>
      <DialogAddContactLimitsApprovals openDialog={openDialog} closeDialog={() => setOpenDialog(false)} />
    </Panel>
  );
}
